import React from 'react';
import {
    makeStyles,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginRight: 30,
    },
    RadioGroup: {
        alignItems: 'center'
    },
    formLabel: {
        color: theme.palette.secondary.main,
        marginRight: 7,
        '& .MuiTypography-body1': {
            marginBottom: 0,
        },
    },
    customIcon: {
        width: 25,
        height: 25,
        marginRight: 30
    }
}));

const Icons = {
    'deliver': LocalShippingIcon,
    'pickup': DirectionsWalkIcon,
};

export default function RadioField({
    id,
    name,
    value = '',
    onChange,
    options,
    defaultValue,
}) {
    const classes = useStyles();
    const [getValue, setValue] = React.useState(value || defaultValue);

    const handleChange = ({ target }) => {
        setValue(target.value);
        onChange({ target: { id, value: target.value } });
    };

    return (
        <RadioGroup
            id={id}
            row aria-label={name}
            name={name}
            value={getValue}
            onChange={handleChange}
            className={classes.RadioGroup}
            defaultValue={defaultValue}
        >
            {options?.map(({ id, label, icon }, i) => {
                const Icon = Icons[icon] ? Icons[icon] : Icons[''];
                return (
                    <React.Fragment key={`${id}-radio-item-${i}`}>
                        <FormControlLabel
                            className={classes.formLabel}
                            value={id}
                            label={label}
                            control={
                                <Radio />
                            }
                        />
                        {icon && <Icon color="primary" className={classes.customIcon} />}
                    </React.Fragment>
                )
            })}
        </RadioGroup>
    );
}