import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { CircularProgress, Typography } from "@material-ui/core";
import { getBGColorForService } from "../../utils";
import { login } from "../../utils/authentication";
import { useNavigate } from "react-router-dom";
import SnackbarComponent from "../../components/snackbar";
import PasswordSecurity from "./security/password-security";
import { createUserWithEmailAndPassword, sendVerificationEmail } from "../../utils/server-functions";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { getAuth } from 'firebase/auth';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 0,
    minWidth: 340,
    maxWidth: 425,
    width: "100%",
  },

  textField: {
    fontSize: 13,
    fontFamily: "Roboto",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#ffffff",
      color: "white",
      borderRadius: "30px",
    },
  },
  submitButton: {
    marginTop: 15,
    border: `1px solid ${getBGColorForService("orange")}`,
    background: getBGColorForService("orange"),
    color: "white",
    width: "150px",
    borderRadius: "30px",
    "&:hover": {
      background: "#773512",
    },
  },
  text: {
    fontSize: 14,
    marginBottom: 30,
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  terms: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "28px",
    marginBottom: 30,
    marginTop: 30,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  link: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 14,
    textDecoration: "none",
    color: "black",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    cursor: "pointer",
    "& svg": {
      marginRight: 8,
    },
  },
  welcomeMessage: {
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
  },
}));

const RegisterForm = ({
  isModal,
  email,
  userExist,
  handleScreenChange,
  onClose,
  setCurrentScreen,
  setLoading,
  formData,
  setFormData,
}) => {
  const classes = useStyles({ userExist });
  const navigate = useNavigate();

  const [validationError, setValidationError] = useState(false);

  const [hasErrors, setHasErrors] = useState(false);
  const [error, setError] = useState("");
  const [fieldsWithError, setFieldsWithError] = useState({});
  const [open, setOpen] = useState(false);
  console.log("error",error);

  // const functions = getFunctions();
  // const createUser = httpsCallable(functions, 'createUser');
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const auth = getAuth();

    // Handle form submit logic here

    // Handle login logic

    let check = { ...fieldsWithError };
    const requiredFields = ["password"];
    requiredFields.forEach((requiredField) => {
      if (!formData.password || check[requiredField]) {
        check[requiredField] = true;
      } else {
        delete check[requiredField];
      }
    });
    if (Object.keys(check).length > 0 || validationError) {
      setFieldsWithError(check);
      setOpen(true);
      setLoading(false);
    } else {
      if (!userExist) {
        // Handle registration logic
        //check how we handle this currently onlyJobsseekers can register
        const newData = { customClaim: "isJobseeker", ...formData };
        try {
          setCurrentScreen("registerValidation");
          const response = await createUserWithEmailAndPassword(newData).then(()=>{
          });
          console.log("after",formData)

          await sendVerificationEmail(formData)

          const createdUser = response.authUser; // Pass null or a default value if needed


          if (createdUser !== null) {
            setLoading(false);

            // setFormData({ username: "", email: "", password: "" });
          }
        } catch (error) {
          console.error("Error registering user:", error);

          setHasErrors(true);
          setError(error);
          setOpen(true);
          setLoading(false);
        }
      } else {
        login(formData.email, formData.password)
          .then(({ token }) => {
            if (isModal) {
              onClose();
            }
          })
          .catch((err) => {
            setHasErrors(true);
            console.error("err", err);
            setError(err);
            setOpen(true);
          });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.formContainer}>
      {!userExist ? (
        <div className={classes.welcomeMessage}>
          <Typography variant="body2" className={classes.text}>
            Create your account with email <strong>{email}</strong>
          </Typography>
          <div className={classes.link} onClick={() => navigate("/")}>
            <KeyboardBackspaceIcon /> Use other email
          </div>
        </div>
      ) : (
        <div className={classes.welcomeMessage}>
          <Typography variant="h6" className={classes.text}>
            Welcome back <strong>{email}</strong>
          </Typography>
          <div className={classes.link} onClick={() => navigate("/")}>
            <KeyboardBackspaceIcon /> Use other email
          </div>
        </div>
      )}
      <PasswordSecurity
        email={email}
        userExist={userExist}
        setHasErrors={setHasErrors}
        formData={formData}
        setFormData={setFormData}
        setValidationError={setValidationError}
        fieldsWithError={fieldsWithError}
        setFieldsWithError={setFieldsWithError}
        showValidation={!userExist ? true : false}
      />

      {/* {userExist && (
        <Typography variant="body2" className={classes.terms}>
          By signing in to your account, you agree to Mi Kier Traha's Terms,
          Cookie and Privacy policies.
        </Typography>
      )}
      {!userExist && (
        <Typography variant="body2" className={classes.terms}>
          By creating an account or logging in, you agree to Mi Kier Traha's
          Terms. You also acknowlegde our Cookie and Privacy policies.
        </Typography>
      )} */}

      <div className={classes.buttonWrapper}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submitButton}
          // onClick={handleSubmit}
          // form={"login-form"}
        >
          {!userExist ? "Register" : "Login"}
        </Button>
      </div>

      <SnackbarComponent
        message={error||"Enter a strong password"}
        open={open}
        onOpen={setOpen}
      />
    </form>
  );
};

export default RegisterForm;
