import { makeStyles } from "@material-ui/core";

export const useIconStyles = makeStyles((theme) => ({
  container: {
    minWidth: 20,
    width: 20,
    maxWidth: 20,
    height: 20,
    minHeight: 20,
    maxHeight: 20,
    userSelect: "none",

    "& svg": {
      width: "100%",
      height: "100%",
      color: "#262626",
    },
  },
}));

export const iconProps = {
  "aria-hidden": "true",
  focusable: "false",
  "data-prefix": "fas",
  role: "img",
  viewBox: "0 0 512 512",
  xmlns: "http://www.w3.org/2000/svg",
};

export { default as Filter } from "./filter";
