import { useState } from 'react';
import {
    makeStyles,
    Grid,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    floatingNav: {
        position:'sticky',
        top:'120px',
        [theme.breakpoints.down('md')]: {
            top:80
        },
        [theme.breakpoints.down('sm')]: {
            position:'fixed',
            zIndex:40,
            top:'60px',
            width:'100%'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom:30,
        },
    },
    containerScrollNav: {
        [theme.breakpoints.down('sm')]: {
            maxWidth:'100%',
            flexBasis:'100%',
        },
    },
    sectionScrollNav: {
        borderRadius:'10px',
        backgroundColor:'#ffffff', 
        border:'1px solid rgba(0,0,0,0.1)',
        padding:'15px',
        [theme.breakpoints.between('xs', 'sm')]: {
            borderRadius:0,
            borderLeft:0,
            paddingTop:8,
            paddingBottom:8,
        },
    },
    list: {
        margin:0,
        padding:0,
        listStyle:'none',
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&.active': {
                color: '#F26419'
            }
        },
        [theme.breakpoints.down('sm')]: {
            display:'flex',
            flexDirection:'row',
            '& li': {
                marginRight:15
            }, 
            '& li:first-child': {
                display:'none',
                visibility:'hidden',
                marginRight:0,
            }
        },
    },
}));

export default function ScrollToNav({ data }) {
    const classes = useStyles();
    const [ activeLink, setActiveLink ] = useState('');

    const scrolltoHash = function (element_id) {
        setActiveLink(element_id);
        const element = document.getElementById(element_id);
        element?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    return (
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.floatingNav}>
            <Grid container direction="column">
                <Grid item xs={12} container className={classes.mb}>
                    <Grid item xs={12} sm={10} md={10} lg={10} className={classes.containerScrollNav}>
                        <Grid item xs={12} container justifyContent="space-between" className={classes.sectionScrollNav}>
                            <Grid item xs={12} sm={10} md={12} lg={12}>
                                <ul className={classes.list}>
                                    <li>
                                        Direct to: 
                                    </li>
                                    {data?.length > 0 && data?.map(({ title, refID}, i) =>
                                        <li key={`list-item-scroll-to-${i}`}>
                                            <a 
                                                href={refID} 
                                                title={`Go to ${title}`}
                                                onClick={() => scrolltoHash(refID)}
                                                className={activeLink === refID ? 'active' : ''}
                                            >
                                                {title}
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}