import {
    Routes,
    Route,
} from 'react-router-dom';
import JobCartCheckout from './job-cart-checkout';

export default function JobCartRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<JobCartCheckout />} />
        </Routes>
    )
}