import { makeStyles, Grid, CircularProgress, Tooltip } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Heading from "../base-components/typography/heading";
import Job from "./job";
import AddIcon from "@material-ui/icons/Add";
import BaseButton from "../base-components/base-button/new-button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: "30px",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        border: "1px solid rgba(0,0,0,0.1)",
        padding: "30px",
        width: "calc(100% - 15px)",
        height: "100%",
        '& [data-rbd-droppable-id="in-progress"]': {
            backgroundColor: "orange",
        },
        [theme.breakpoints.down('sm')]: {
            padding:15,
            marginTop:0,
            width: "calc(100%)",
        },
    },
    jobItems: {
        paddingRight: 0,
        paddingLeft: 0,
        width: "200px",
        height: "200px",
        transition: "all 0.2s linear",
        [theme.breakpoints.up("lg")]: {
            padding: 0,
            height: "100%",
            width: "100%",
        },
    },
    droppableList: {
        height: "100%",
        width: "auto",
        padding: "0",
    },
    gridItems: {
        [theme.breakpoints.down("md")]: {
            marginBottom: 30,
            justifyContent: "flex-start",
            "&:last-child": {
                justifyContent: "flex-start",
            },
        },
        [theme.breakpoints.down("xs")]: {
            "&:last-child": {
                justifyContent: "flex-start",
                marginTop: 30,
            },
        },
    },
    containerDraggableList: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    draggableList: {
        backgroundColor: "pink",
        width: "200px",
        height: "200px",
        color: "transparent",
        position: "relative",
        marginRight: 15,
        marginBottom: 15,
    },
    iconAction: {
        position: "absolute",
        top: 0,
        right: "-25px",
        zIndex: 9999,
        backgroundColor: "grey",
        color: "white",
        width: 25,
        height: 25,
    },
    buttons: {
        width: "100%",
    },
    buttonWrapper: {
        "& button": {
            maxWidth: "125px",
        },
    },
    heading: {
        width: "100%",
        minWidth: "225px",
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-start",
        marginBottom:0,
        [theme.breakpoints.down("lg")]: {
            fontSize:15,
            marginBottom:15,
        },
    },
    mb: {
        marginBottom: 15,
    },
    icons: {
        marginLeft: 15,
        marginBottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
            color: "#F26419",
        },
    },
}));

export default function JobCartDragNDrop({
    onDragEnd,
    columns,
    onClick,
    handleFavouriteClick,
    handleApplyJobs,
    handleMoveAllItems,
    loading,
}) {
    const classes = useStyles();

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {Object.values(columns)?.map((column) => (
                <Grid
                    key={column.id}
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    className={classes.gridItems}
                    justifyContent={
                        column.id === "favorited-jobs" ? "flex-start" : "center"
                    }
                >
                    <Grid item className={classes.section}>
                        <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={classes.mb}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={9}
                                style={{
                                    justifyContent: "start",
                                    flexDirection: "row",
                                    display: "flex",
                                    width: "100%",
                                }}
                            >
                                <Heading
                                    color="secondary.dark"
                                    variant="h6"
                                    className={classes.heading}
                                >
                                    {column.title}
                                    <div className={classes.icons}>
                                        {column.id === "favorited-jobs" ? (
                                            <Tooltip title="Add All to Selected" placement="right">
                                                <AddCircleOutlineIcon
                                                    fontSize="medium"
                                                    onClick={() =>
                                                        handleMoveAllItems("favorited-jobs", "selected-jobs")
                                                    }
                                                />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Remove All" placement="right">
                                                <RemoveCircleOutlineIcon
                                                    fontSize="medium"
                                                    onClick={() =>
                                                        handleMoveAllItems("selected-jobs", "favorited-jobs")
                                                    }
                                                />
                                            </Tooltip>
                                        )}
                                    </div>
                                </Heading>
                                
                            </Grid>
                            {column?.id === "selected-jobs" && (
                                <Grid
                                    container
                                    item
                                    xs={3}
                                    sm={3}
                                    md={12}
                                    lg={12}
                                    xl={3}
                                    justifyContent="flex-start"
                                    className={classes.buttonWrapper}
                                >
                                    <BaseButton
                                        label={"Apply Now"}
                                        color="primary"
                                        className={classes.buttons}
                                        onClick={handleApplyJobs}
                                        disabled={
                                            columns["selected-jobs"]?.items?.length === 0 ? "" : false
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Droppable droppableId={column.id} direction="vertical">
                            {(provided) => (
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={classes.droppableList}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        className={classes.containerDraggableList}
                                    >
                                        {loading ? (
                                            <div>
                                                <CircularProgress />
                                            </div>
                                        ) : (
                                            column?.items?.map((item, index) => (
                                                <Draggable
                                                    key={item?.id}
                                                    draggableId={item?.id}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                            snapshot={snapshot}
                                                            className={classes.draggableList}
                                                            onClick={() => onClick(item.id)}
                                                            style={{
                                                                userSelect: "none",
                                                                borderRadius: "4px",
                                                                backgroundColor: snapshot.isDragging
                                                                    ? "rgba(255,255,255,0.1)"
                                                                    : "#fff",
                                                                ...provided.draggableProps.style,
                                                            }}
                                                        >
                                                            <div
                                                                className={`${classes.jobItems} element-item ${item?.service}`}
                                                                key={item?.id}
                                                                data-category={item?.service}
                                                            >
                                                                <Job
                                                                    columnId={column.id}
                                                                    handleFavouriteClick={handleFavouriteClick}
                                                                    {...item}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))
                                        )}
                                        {provided.placeholder}
                                    </Grid>
                                </Grid>
                            )}
                        </Droppable>
                    </Grid>
                </Grid>
            ))}
        </DragDropContext>
    );
}
