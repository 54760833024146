import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    adLink: {
        backgroundColor: theme.palette.secondary.light,
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
        position: 'relative',
        textDecoration: 'none',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.3s linear',
        height: '100%',
    },
}));

export default function Ad({
    imgSrc = 'https://via.placeholder.com/300x300.png?text=PLACE+YOUR+JOB+AD+HERE',
    externalLink = '#'
}) {
    const classes = useStyles();
    return (
        <a
            href={externalLink}
            target={`_blank`}
            rel="noopener"
            className={classes.adLink}
            style={{ backgroundImage: `url(${imgSrc})` }}
        />

    )
}