import {
    Grid,
    Tooltip,
    makeStyles,
    Divider,
} from "@material-ui/core";
import {
    AddCircleOutline,
    RemoveCircleOutline
} from "@material-ui/icons";
import {
    CheckboxField,
    DatePickerField,
    TextField,
} from "../../base-components/form-fields";

const useStyles = makeStyles(() => ({
    link: {
        marginRight: 10,
        cursor: "pointer",
    },
    divider: {
        marginBottom: 30,
        width: "100%",
    },
    delete: {
        color: "#990000",
    },
}));

export default function Experience({
    index,
    data,
    onAddArrayItem,
    onRemoveArrayItem,
    onChange,
    fieldsWithError = {},
    setFieldError,
}) {
    const classes = useStyles();

    const onDataChange = ({ target }) => {
        const { id, value } = target;
        const newData = { ...data };
        newData[id] = value;

        if (id === "current" && value) {
            newData.ended = null;
        }
        onChange && onChange({ target: { id, value: newData } });
    };

    const onError = (id, validateError) => {
        const value = { ...fieldsWithError }
        if (validateError) {
            value[id] = validateError;
        } else {
            delete value[id];
        }
        setFieldError && setFieldError('experiences', index, value)
    }

    return (
        <Grid container spacing={2} direction="row">
            {
                index >= 0 &&
                <Grid container xs={12} sm={12} md={12} lg={12} justifyContent="flex-end">
                    <div className={classes.link} onClick={onAddArrayItem}>
                        <Tooltip title={'Add New Experience'} aria-label="edit" placement="top" arrow>
                            <AddCircleOutline color="primary" fontSize="small" />
                        </Tooltip>
                    </div>
                    {
                        index > 0 &&
                        <div className={classes.link} onClick={onRemoveArrayItem}>
                            <Tooltip title={'Delete Experience'} aria-label="edit" placement="top" arrow>
                                <RemoveCircleOutline className={classes.delete} fontSize="small" />
                            </Tooltip>
                        </div>
                    }
                </Grid>
            }
            <Grid item xs={12} container justifyContent="space-between">
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            id="jobTitle"
                            name="jobTitle"
                            label="Job Title"
                            value={data?.jobTitle || ""}
                            onChange={onDataChange}
                            required={true}
                            onError={onError}
                            hasError={fieldsWithError['jobTitle']}
                            errorMessage="Must fill in a Job Title"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CheckboxField
                            id="current"
                            name="current"
                            label="Current Experience"
                            value={data?.current || ""}
                            onChange={onDataChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <DatePickerField
                            id="started"
                            name="started"
                            label="Started"
                            value={data?.started}
                            onChange={onDataChange}
                            required={true}
                            onError={onError}
                            hasError={fieldsWithError["started"]}
                            errorMessage={"Must choose start date"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <DatePickerField
                            id="ended"
                            name="ended"
                            label="Ended"
                            value={data?.ended}
                            disabled={data?.current}
                            onChange={onDataChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            id="company"
                            name="company"
                            label="Company"
                            value={data?.company || ""}
                            onChange={onDataChange}
                            required={true}
                            onError={onError}
                            hasError={fieldsWithError['company']}
                            errorMessage="Must fill in a company"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            id="description"
                            name="description"
                            label="Description"
                            multiline
                            value={data.description || ""}
                            onChange={onDataChange}
                            inputProps={{ minLength: 2 }}
                        //error={!isInputValid}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
