import React, { useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core/";
import { getBGColorForService } from "../../../utils/index";
import BaseButton from "../../../components/base-components/base-button/new-button";
import LinkButton from "../../../components/base-components/links";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  active: {
    visibility: "visible",
    opacity: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  disabled: {
    display: "none",
    visibility: "hidden",
    opacity: "0",
    height: "0",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
      marginBottom: "15px",
    },
  },
  mainTitle: {
    color: theme.palette.secondary.dark,
    fontSize: "38px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "21px !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "21px",
    },
  },
  mainItems: {
    transition: "all 0.2s linear",
    height: "200px",
    aspectRatio: "1/1",

    [theme.breakpoints.up("xs")]: {
      height: "135px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "200px",
    },
  },
  mainItem: {
    backgroundColor: (p) => getBGColorForService(p.service),
    color: theme.palette.secondary.light,
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    fontFamily: "itc-avant-garde-gothic-pro",
    textTransform: "Capitalize",
    "&:hover": {
      cursor: "pointer",
    },
    "&.hospitality": {
      backgroundColor: (p) => getBGColorForService("hospitality"),
      "&:hover, &.selected": {
        backgroundColor: "#262626",
        boxShadow: `inset 0px 0px 0px 8px #E9D758`,
      },
    },
    "&.public": {
      backgroundColor: (p) => getBGColorForService("public"),
      "&:hover, &.selected": {
        backgroundColor: "#262626",
        boxShadow: `inset 0px 0px 0px 8px #CC2936`,
      },
    },
    "&.health": {
      backgroundColor: (p) => getBGColorForService("health"),
      "&:hover, &.selected": {
        backgroundColor: "#262626",
        boxShadow: `inset 0px 0px 0px 8px #059c75`,
      },
    },
    "&.engineering": {
      backgroundColor: (p) => getBGColorForService("engineering"),
      "&:hover, &.selected": {
        backgroundColor: "#262626",
        boxShadow: `inset 0px 0px 0px 8px #2589bd`,
      },
    },
    "&.professional": {
      backgroundColor: (p) => getBGColorForService("professional"),
      "&:hover, &.selected": {
        backgroundColor: "#262626",
        boxShadow: `inset 0px 0px 0px 8px #54457F`,
      },
    },
    "&.all": {
      "&.selected": {
        backgroundColor: (p) => getBGColorForService("professional"),
        boxShadow: `inset 0px 0px 0px 8px #CC2936`,
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  row: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0",
      paddingRight: "0",
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
}));

export default function Steps({ list, active, id, onClick }) {
  const classes = useStyles();

  const { type, items, mainTitle } = list || {};

  const countryArray = [];

  const [listOfServices, setListOfServices] = useState(countryArray || []);

  const nextCountry = () => {
    const stepOne = document.getElementById("step-1");
    const stepTwo = document.getElementById("step-2");

    stepOne.className = "";
    stepOne.classList.add("disabled");
    stepOne.style.display = "none";
    stepTwo.className = "";
    stepTwo.classList.add("active");

    if (type === "country") {
      const cookies = new Cookies();
      const cookieList = JSON.stringify(listOfServices);

      if (cookieList === "all" || listOfServices.length === 0) {
        cookies.set("country", [], {
          path: "/",
          maxAge: 2592000,
        });
      } else {
        cookies.set("country", cookieList, { path: "/", maxAge: 2592000 });
      }
    }
  };

  const nextIndustry = () => {
    const modal = document.getElementById("modal");
    modal.classList.add("hidden");

    if (type === "service") {
      const cookies = new Cookies();
      const cookieList = JSON.stringify(listOfServices);

      if (cookieList === "all" || listOfServices.length === 0) {
        cookies.set("service", [], { path: "/", maxAge: 2592000 });
      } else {
        cookies.set("service", cookieList, { path: "/", maxAge: 2592000 });
      }
    }

    onClick(onClick);
  };

  const handleRemoveItem = (title) => {
    const currentService = title;
    setListOfServices(
      listOfServices.filter((title) => title !== currentService)
    );
  };

  const handleButtonClick = (id, type) => {
    if (id === "all" && type === "country") {
      setListOfServices(
        listOfServices.length === 3 ? [] : ["aua", "bon", "cur"]
      );
    } else if (id === "all" && type === "service") {
      setListOfServices(
        listOfServices.length === 5
          ? []
          : ["public", "engineering", "hospitality", "professional", "health"]
      );
    } else {
      setListOfServices((prevSelectedButtons) =>
        prevSelectedButtons.includes(id)
          ? prevSelectedButtons.filter((name) => name !== id)
          : [...prevSelectedButtons, id]
      );
    }
  };

  return (
    <div
      id={id}
      className={active === true ? classes.active : classes.disabled}
    >
      <Typography variant="h1" className={classes.mainTitle}>
        {mainTitle}
      </Typography>
      <div className={classes.container}>
        <Grid container spacing={1} className={classes.row}>
          {items?.length > 0 &&
            items?.map(({ title, service, id }, i) => (
              <Grid
                item
                xs={`12 / ${items?.length}`}
                className={classes.mainItems}
                key={`${type}-item-${i}`}
              >
                <Grid
                  item
                  className={`${classes.mainItem} ${
                    service === "all" ? "all" : service
                  } ${id === "all" ? "all" : id} ${
                    listOfServices.includes(type === "service" ? service : id)
                      ? "selected"
                      : "notSelected"
                  }`}
                  onClick={() => handleButtonClick(id, type, i)}
                >
                  {title}
                </Grid>
              </Grid>
            ))}
        </Grid>
      </div>
      <div className={classes.container}>
        <BaseButton
          label={`Next`}
          service="orange"
          onClick={type === "country" ? nextCountry : nextIndustry}
          disabled={listOfServices.length > 0 ? "disabled" : ""}
        />
        <LinkButton
          title={`Skip`}
          onClick={type === "country" ? nextCountry : nextIndustry}
        />
      </div>
    </div>
  );
}
