import { useState, useEffect, useContext } from "react";
import {
    makeStyles,
    Grid,
    withWidth,
    Typography,
} from "@material-ui/core";
import Typesense from "typesense";
import Job from "./job";
import JobCartDragDropComponent from "./job-cart-dnd";
import JobModal from "./job-cart-modal";
import Heading from "../base-components/typography/heading";
import { Context } from "../../utils/context-provider";
import { searchServerConfig } from "../../utils/search/typesense-config";
import firebase from "../../utils/firebase";
import { applyForSelectedJobs } from "../../utils/server-functions";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        height: "100%",
    },
    mainPadding: {
        height: "100%",
        padding: "100px 60px 60px 60px",
        [theme.breakpoints.down("xl")]: {},
        [theme.breakpoints.down("lg")]: {},
        [theme.breakpoints.down("md")]: {
            padding: "90px 30px 60px 30px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "0",
        },
        [theme.breakpoints.down("xs")]: {},
    },
    mainContainerResponsive: {
        [theme.breakpoints.down("sm")]: {
            padding: "120px 15px 0 15px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "120px 15px 0 15px",
        },
    },
    container: {
        paddingRight: 30,
        flexDirection: "column",
    },
    buttons: {
        width: "100%",
    },
    relative: {
        position: "relative",
    },
    mb: {
        marginBottom: 15,
    },
    hideForSmallScreen: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
            visibility: "hidden",
        },
    },
    animateIn: {
        position: "fixed",
        bottom: 15,
        right: 15,
        backgroundColor: "#F26419",
        width: 40,
        height: 40,
        borderRadius: "50%",
        boxShadow: "1px 1px 2px rgba(0,0,0,0.3)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        "&:hover": {
            boxShadow: "2px 2px 3px rgba(0,0,0,0.4)",
            backgroundColor: "#c95a1e",
        },
    },
    icon: {
        color: "#FFF",
    },
    listSticky: {
        margin: 0,
        padding: 0,
        listStyle: "none",
        position: "fixed",
        bottom: 70,
        right: 21,
        "& > li ": {
            marginTop: 15,
        },
        "& > li > div": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            textDecoration: "none",
            color: theme.palette.secondary.dark,
            cursor: "pointer",
            "& > span": {
                fontSize: "11px",
            },
            "&:hover": {
                "& > div": {
                    boxShadow: "2px 2px 3px rgba(0,0,0,0.4)",
                    backgroundColor: "#262626",
                },
            },
        },
    },
    iconDiv: {
        backgroundColor: "#F26419",
        width: 30,
        height: 30,
        borderRadius: "50%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: 15,
        transition: "all 0.2s linear",
        "& > svg": {
            fontSize: "1.1rem !important",
        },
    },
    gridItems: {
        [theme.breakpoints.down("md")]: {
            marginBottom: 30,
            justifyContent: "flex-start",
            "&:last-child": {
                justifyContent: "flex-start",
            },
        },
        [theme.breakpoints.down("xs")]: {
            "&:last-child": {
                justifyContent: "flex-start",
                marginTop: 30,
            },
        },
    },
    section: {
        marginTop: "30px",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        border: "1px solid rgba(0,0,0,0.1)",
        padding: "30px",
        width: "calc(100% - 15px)",
        height: "100%",
        '& [data-rbd-droppable-id="in-progress"]': {
            backgroundColor: "orange",
        },
        [theme.breakpoints.down('sm')]: {
            padding:15,
            marginTop:0,
            width: "calc(100%)",
        },
    },
    jobItems: {
        paddingRight: 0,
        paddingLeft: 0,
        width: "auto",
        transition: "all 0.2s linear",
        marginRight: 15,
        marginBottom: 15,
        [theme.breakpoints.up("lg")]: {
            padding: 0,
            height: "200px",
            width: "200px",
        },
    },
    containerJobs: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    heading: {
        width: "100%",
        minWidth: "225px",
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-start",
        marginBottom:0,
        [theme.breakpoints.down("lg")]: {
            fontSize:15,
            marginBottom:15,
        },
    },
}));

const typesenseClient = new Typesense.Client(searchServerConfig);
const typesenseCollection = typesenseClient.collections("jobs");

export default withWidth()(function JobCartCheckout({ width }) {
    const classes = useStyles();

    const [appliedJobs, setAppliedJobs] = useState([]);
    const { user, loadingUserData } = useContext(Context);
    const userRef = firebase.firestore().collection("users").doc(user.uid);
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [currentId, setCurrentId] = useState("");
    const [noShowModal, setNoShowModal] = useState(false);
    const [errorsModal, setErrorsModal] = useState(false);
    const [notVerified, setNotVerified] = useState(false);
    const [errors, setErrors] = useState([]);

    const navigate = useNavigate();
    const params = useParams();

    const [columns, setColumns] = useState({
        "favorited-jobs": {
            id: "favorited-jobs",
            title: "Favorites (0)",
            items: [],
        },
        "selected-jobs": {
            id: "selected-jobs",
            title: "Selected to Apply (0)",
            items: [],
        },
    });

    // reuse this for applied jobs or combine!
    useEffect(() => {
        const fetchJobsData = async () => {
            setLoadingUpdate(true);

            const favoritedJobs = user?.favoritedJobs || [];
            const selectedJobs = user?.selectedJobs || [];
            const appliedJobs = (user?.appliedJobs || []).map((job) => job.id);

            try {
                const searchParameters = {
                    q: `*`,
                    filter_by:
                        `status:=published` &&
                        `id:[${favoritedJobs.join(",")}${selectedJobs ? `,${selectedJobs.join(",")}` : ""
                        }${appliedJobs ? `,${appliedJobs.join(",")}` : ""}]`,
                };

                const { hits } = await typesenseCollection
                    .documents()
                    .search(searchParameters);

                const jobDataArray = hits.map((doc) => doc.document); // Extract data from each document
                const colFavJobs = jobDataArray
                    .filter((job) => favoritedJobs.includes(job.id))
                    .map((job) => ({
                        ...job,
                        currentColumnId: "favorited-jobs",
                    }));
                const colSelJobs = jobDataArray
                    .filter((job) => selectedJobs.includes(job.id))
                    .map((job) => ({
                        ...job,
                        currentColumnId: "selected-jobs",
                    }));
                const colAppliedJobs = jobDataArray.filter((job) =>
                    appliedJobs.includes(job.id)
                );

                setColumns({
                    "favorited-jobs": {
                        id: "favorited-jobs",
                        title: `Favorites (${favoritedJobs.length})`,
                        items: colFavJobs,
                    },
                    "selected-jobs": {
                        id: "selected-jobs",
                        title: `Selected to Apply (${selectedJobs.length})`,
                        items: colSelJobs,
                    },
                });
                setLoadingUpdate(false);
                setAppliedJobs(colAppliedJobs);
            } catch (error) {
                console.error("Error fetching jobs data:", error);
                setLoadingUpdate(false);
            }
        };
        if (user && !loadingUserData) {
            fetchJobsData();
        }
    }, [user, loadingUserData]);

    const updateUserJobs = async (columns) => {
        const favoritedJobs = columns["favorited-jobs"].items.map(
            (item) => item.id
        );
        const selectedJobs = columns["selected-jobs"].items.map((item) => item.id);
        setLoadingUpdate(true);

        try {
            await userRef.set(
                {
                    favoritedJobs,
                    selectedJobs,
                },
                {
                    merge: true,
                }
            );
            setLoadingUpdate(false);
        } catch (error) {
            console.error("Error fetching jobs data:", error);
            setLoadingUpdate(false);
        }
    };

    const handleFavouriteClick = (e, id) => {
        e.stopPropagation();
        setCurrentId(id);

        if (!noShowModal) {
            setShowModal(true);
        } else {
            removeFavourite(id);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setNoShowModal(false);
    };
    const handleNoShowModalToggle = () => {
        setNoShowModal(!noShowModal);
    };

    const removeFavourite = async (id) => {
        const userDoc = await userRef.get();

        if (userDoc.exists) {
            const userData = userDoc.data();
            const itemToRemove = currentId || id; // item you want to remove the || id is used when user does not want to show modal anymore.

            // Check if the item exists in the favorites array
            if (
                userData.favoritedJobs &&
                userData.favoritedJobs.includes(itemToRemove)
            ) {
                // If the item exists, remove it
                return userRef
                    .update({
                        favoritedJobs:
                            firebase.firestore.FieldValue.arrayRemove(itemToRemove),
                    })
                    .then(() => {
                        setShowModal(false);
                        setCurrentId("");
                    })
                    .catch((error) => {
                        // Handle any errors that occurred during the update operation
                        console.error("Error removing item from favorites:", error);
                    });
            } else {
                console.warn("Item not found in favorites.");
                // You can display a message or perform other actions if the item isn't found.
            }
        }

        return null; // Handle the case where the user document doesn't exist
    };

    const handleApplyJobs = async () => {
        const isEmailVerified = checkUserEmailVerification();

        if (isEmailVerified) {
            console.log("thank you for verifiying.");
            checkUserData();

            if (errors?.length === 0) {
                await applyForSelectedJobs();

                console.log("Data filled");
            } else {
                console.log("unFilled");
            }
            setNotVerified(false);
        } else {
            setNotVerified(true);
            console.log("Please verify your email before applying for jobs.");
        }
    };

    const checkUserData = () => {
        const newErrors = [];

        if (user) {
            const fieldsToCheck = ["cvID", "firstName"];

            fieldsToCheck.forEach((field) => {
                if (!user[field]) {
                    if (field === "firstName" || field === "lastName") {
                        newErrors.push("First and last name not provided.");
                    } else if (field === "cvID") {
                        newErrors.push("CV information is missing.");
                    }
                }
            });
        }
        if (newErrors.length > 0) {
            setErrors(newErrors);
            setErrorsModal(true);
            // Show modal with errors
            // showModalWithErrors();
        }
    };

    const checkUserEmailVerification = () => {
        if (user) {
            const isEmailVerified = user.emailVerified;
            if (isEmailVerified) {
                console.log("Email is verified:", isEmailVerified);
                return true;
            } else {
                console.log("Email is not verified:", isEmailVerified);
                return false;
            }
        } else {
            console.log("No user signed in.");
            return false;
        }
    };

    const handleItemClick = (itemId) => {
        // Find the column that contains the clicked item
        const sourceColumn = Object.values(columns).find((column) =>
            column.items.some((item) => item.id === itemId)
        );

        if (!sourceColumn) return;

        // Determine the destination column ID for the item (toggle between destination and original)
        const newColumnId =
            sourceColumn.id === "favorited-jobs" ? "selected-jobs" : "favorited-jobs";

        // Find the index of the item in the source column
        const itemIndex = sourceColumn.items.findIndex(
            (item) => item.id === itemId
        );

        if (itemIndex === -1) return;

        // Remove the item from the source column
        const updatedSourceItems = [...sourceColumn.items];
        updatedSourceItems.splice(itemIndex, 1);

        const updatedSourceColumn = {
            ...sourceColumn,
            items: updatedSourceItems,
        };

        // Add the item to the new column
        const newDestinationColumn = columns[newColumnId];
        const updatedDestColumn = {
            ...newDestinationColumn,
            items: [...newDestinationColumn.items, sourceColumn.items[itemIndex]],
        };

        const updatedColumns = {
            ...columns,
            [sourceColumn.id]: updatedSourceColumn,
            [newColumnId]: updatedDestColumn,
        };

        updateUserJobs(updatedColumns);
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) return;

        if (source.droppableId === destination.droppableId) {
            const column = columns[source.droppableId];
            const newItems = Array.from(column.items);
            const [reorderedItem] = newItems.splice(source.index, 1);
            newItems.splice(destination.index, 0, reorderedItem);

            const updatedColumn = {
                ...column,
                items: newItems,
            };

            const updatedColumns = {
                ...columns,
                [source.droppableId]: updatedColumn,
            };

            updateUserJobs(updatedColumns);
        } else {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];

            const sourceItems = Array.from(sourceColumn.items);
            const destItems = Array.from(destColumn.items);

            const [movedItem] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, movedItem);

            const updatedSourceColumn = {
                ...sourceColumn,
                items: sourceItems,
            };

            const updatedDestColumn = {
                ...destColumn,
                items: destItems,
            };

            const updatedColumns = {
                ...columns,
                [source.droppableId]: updatedSourceColumn,
                [destination.droppableId]: updatedDestColumn,
            };

            updateUserJobs(updatedColumns);
        }
    };

    const handleMoveAllItems = (sourceColumnId, destColumnId) => {
        // Find source column
        const sourceColumn = columns[sourceColumnId];

        // Find destination column
        const destColumn = columns[destColumnId];

        // Save all items from source column
        const sourceItems = [...sourceColumn.items];

        // Update source column with no items
        const updatedSourceColumn = {
            ...sourceColumn,
            items: [],
        };

        // Update destination column with previous + new items
        const updatedDestColumn = {
            ...destColumn,
            items: [...destColumn.items, ...sourceItems],
        };

        // Update columns object
        const updatedColumns = {
            ...columns,
            [sourceColumnId]: updatedSourceColumn,
            [destColumnId]: updatedDestColumn,
        };

        updateUserJobs(updatedColumns);
    };

    //   const saveParamsOnLogout = () => {
    //     const queryParams = new URLSearchParams(window.location.search);
    //     navigate(`${queryParams.toString()}`);
    //   };
    const reauthenticateAfterEmailVerification = () => {
        const user = firebase.auth().currentUser;
        // const actionCodeSettings = {
        //   url: 'https://your-app-url.com', // Set the URL where the user will be redirected after verifying the email
        //   handleCodeInApp: true
        // };
        if (user) {
            user.reload(); // Reload the user to get the latest email verification status
            // Check if the user's email is verified
            if (user.emailVerified) {
                setNotVerified(false);
                console.log("Already Verified");

                // Force user reauthentication by signing out and signing in again
                // firebase
                //   .auth()
                //   .signOut()
                //   .then(() => {
                //     // Redirect or prompt the user to log in again
                //     // You can use signInWithEmailAndPassword or other login methods here
                //   })
                //   .catch((error) => {
                //     console.error("Error signing out:", error);
                //   });
            } else {
                // await sendEmailVerification(user);

                console.log("Email is not verified yet.");
                // Handle the case where email is not verified
            }
        } else {
            console.log("No user signed in.");
            // Handle the case where no user is signed in
        }
    };

    return (
        <>
            {showModal && (
                <JobModal
                    onClose={handleCloseModal}
                    onConfirm={removeFavourite}
                    isDefault={true}
                    favorites={true}
                    handleNoShowModalToggle={handleNoShowModalToggle}
                >
                    You are about to remove this job from your favorites. Are you sure?

                </JobModal>
            )}
            {notVerified && (
                <JobModal
                    onClose={() => setNotVerified(false)}
                    onConfirm={reauthenticateAfterEmailVerification}
                    notVerified={notVerified}
                >
                    Please verify your email to continue. Once verified, you will be
                    logged out. Please log in again.
                </JobModal>
            )}
            {errorsModal && (
                <JobModal onClose={() => setErrorsModal(false)} isErrors={true}>
                    <Typography>
                        In order to send your application, please make sure you have the
                        following:
                    </Typography>
                    <ul>
                        {errors?.map((error, index) => {
                            let link;
                            if (error === "First and last name not provided.") {
                                link = (
                                    <Link to="/profile" style={{ textDecoration: "none" }}>
                                        (Profile)
                                    </Link>
                                );
                            } else if (error === "CV information is missing.") {
                                link = (
                                    <Link to="/profile/cv/" style={{ textDecoration: "none" }}>
                                        (CV)
                                    </Link>
                                );
                            }

                            return (
                                <li key={`error-${index}`}>
                                    {error}
                                    {link}
                                </li>
                            );
                        })}
                    </ul>
                </JobModal>
            )}

            <Grid container direction="column" className={classes.mainContainer}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className={classes.mainPadding}>
                        <Grid container direction="row" className={classes.mainContainer}>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                className={classes.mainContainerResponsive}
                            >
                                <Grid container direction="column">
                                    <Grid item xs={12} container className={classes.mb}>
                                        <Grid container xs={12} sm={12} md={12} lg={12}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Heading color="secondary.dark" variant="h3">
                                                    Application Checkout
                                                </Heading>
                                                <Typography>
                                                    Select one or more favorites to apply.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row">
                                                <JobCartDragDropComponent
                                                    onDragEnd={onDragEnd}
                                                    columns={columns}
                                                    onClick={handleItemClick}
                                                    handleFavouriteClick={handleFavouriteClick}
                                                    handleApplyJobs={handleApplyJobs}
                                                    handleMoveAllItems={handleMoveAllItems}
                                                    loading={loadingUpdate}
                                                />
                                                <Grid
                                                    container
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={4}
                                                    className={classes.gridItems}
                                                    justifyContent="flex-end"
                                                >
                                                    <Grid item className={classes.section}>
                                                        <Grid
                                                            container
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            className={classes.mb}
                                                        >
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <Heading color="secondary.dark" variant="h6" className={classes.heading}>
                                                                    {`Latest Applications Sent (${appliedJobs?.length})`}
                                                                </Heading>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            container
                                                            justifyContent="flex-start"
                                                            alignItems="flex-start"
                                                            className={classes.containerJobs}
                                                        >
                                                            {appliedJobs?.length > 0 ? (
                                                                appliedJobs?.map((item, i) => (
                                                                    <div
                                                                        className={`${classes.jobItems} element-item ${item?.service}`}
                                                                        key={`${item?.id}-${i}`}
                                                                        data-category={item?.service}
                                                                    >
                                                                        <Job applied={true} {...item} />
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <Typography>No applied Jobs</Typography>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </>
    );
});
