import {
    Routes,
    Route,
} from 'react-router-dom';
import UpdateSettings from './update-settings';

export default function SettingsRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<UpdateSettings />} />
        </Routes>
    )
}