import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:'#FFFFFF'
    }
}));

const Preloader = ({ height }) => {
    const classes = useStyles();
    return (
        <div className={classes.container} style={{ height: `${height !== undefined ? height : '100%'}`}}>
            <CircularProgress />
        </div>
    )
}

export default Preloader;