import FireBaseUILogin from "./firebase-ui-login";
// import Login from "./login-form";

export default function Authentication() {

    return (
        <>
            <FireBaseUILogin />
            {/* <Login /> */}
        </>
    )
}