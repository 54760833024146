import { makeStyles, TextField as BaseTextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            backgroundColor: "transparent",
            fontFamily: "Roboto",
        },

        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #808080",
            borderRadius: "40px",
        },

        "& .Mui-error": {
            color: (props) => (props.hasError ? theme.palette.error.main : ""),
        },
        "& .MuiFormHelperText-root": {
            color: (props) => (props.hasError ? theme.palette.error.main : ""),
        },

        "& fieldset": {
            borderColor: "#808080",
        },
        "& .MuiOutlinedInput-root.Mui-error": {
            "& fieldset": {
                borderColor: (props) =>
                    props.hasError ? theme.palette.error.main : "",
            },
        },
    },

    disabled: {
        "& .Mui-disabled": {
            opacity: 0.5,
            cursor: "not-allowed",
        },
    },

    multiline: {
        "& .MuiOutlinedInput-multiline": {
            alignItems: "start",
        },
    },
}));

export default function TextField({
    id,
    name,
    label,
    type = "text",
    value,
    disabled = false,
    onChange,
    regex,
    hasError = false,
    onError,
    errorMessage = "Input is not valid",
    multiline,
    required,
    className,
    inputProps,
}) {
    const classes = useStyles({ hasError });

    const handleValidation = () => {
        let validateError = false;
        if (regex) {
            validateError = !regex.test(value);
        }
        if (validateError || required) {
            validateError = (value || "").trim().length <= 0;
        }
        onError && onError(id, validateError);
    };

    return (
        <BaseTextField
            id={id}
            name={name}
            type={type}
            value={value}
            defaultValue={value}
            label={label}
            multiline={multiline !== true ? false : true}
            disabled={disabled}
            onChange={onChange}
            onBlur={handleValidation}
            onFocus={() => onError && onError(id, false)}
            error={hasError}
            required={required}
            helperText={hasError ? errorMessage : ""}
            className={`${className} ${disabled ? classes.disabled : ""} ${multiline ? classes.multiline : ""
                } `}
            inputProps={inputProps}
            InputLabelProps={{
                shrink: value ? true : undefined,
            }}
        />
    );
}
