import {
  Button,
  makeStyles,
  FormControlLabel,
  Typography,
  Checkbox,
  Snackbar,
} from "@material-ui/core";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context-provider";
import { login } from "../../utils/authentication";
import TextField from "../base-components/form-fields/text-field";
import Alert from "@material-ui/lab/Alert";
import { getBGColorForService } from "../../utils";
import SnackbarComponent from "../snackbar";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  label: {
    "& .MuiTypography-body1": {
      fontFamily: "Roboto",
      margin: 0,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  rememberMe: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    padding: "15px 0",
  },
  submitButton: {
    margin: theme.spacing(2),
    border: `1px solid ${getBGColorForService("orange")}`,
    background: getBGColorForService("orange"),
    color: "white",
    width: "150px",
    borderRadius: "30px",
    "&:hover": {
      background: "#773512",
    },
  },
  link: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 14,
    textDecoration: "none",
    color: "black",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    cursor: "pointer",
    "& svg": {
      marginRight: 8,
    },
  },
}));
const passwordRE = /(.*[a-zA-Z0-9_!.-]){3}$/;
const emailRE = /^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+).([a-zA-Z]{2,5})$/;

export default function LoginForm({
  isModal,
  onContinue,
  onClose,
  setCurrentScreen,
}) {
  const classes = useStyles();
  const { user } = useContext(Context);
  const [email, setEmail] = useState("");
  const [hasErrors] = useState(false);
  const [fieldsWithError, setFieldsWithError] = useState({});
  const [open, setOpen] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleRememberMeChange = (event) => setRememberMe(event.target.checked);
  useEffect(() => {
    const cookies = new Cookies();

    const rememberedEmail = cookies.get("rememberedEmail");
    if (rememberedEmail) {
      setEmail(rememberedEmail);
      setRememberMe(true);
    }
  }, []);
  useEffect(() => {
    if (!user && process?.env?.REACT_APP_EMULATOR_LOGIN) {
      login(
        process?.env?.REACT_APP_EMULATOR_LOGIN_UN,
        process?.env?.REACT_APP_EMULATOR_LOGIN_PW
      );
    }

    // const cookies = new Cookies();
    // const authToken = cookies.get("authToken");
    // if (user) {
    //     navigate("/dashboard");
    // }
    // else{
    //     navigate("/")
    // }
  }, [user]);

  const setFieldError = (id, hasError) => {
    let check = { ...fieldsWithError };
    if (hasError) {
      check[id] = true;
    } else {
      delete check[id];
    }
    setFieldsWithError(check);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let check = { ...fieldsWithError };
    const requiredFields = ["email"];
    requiredFields.forEach((requiredField) => {
      if (!email || check[requiredField]) {
        check[requiredField] = true;
      } else {
        delete check[requiredField];
      }
    });
    if (!email || Object.keys(check).length > 0) {
      setFieldsWithError(check);
      setOpen(true);
    } else {
      onContinue(email);
    }
    // if (Object.keys(check).length > 0) {
    //   setFieldsWithError(check);
    // } else {
    //   login(email, password)
    //     .then(({ token }) => {
    //       console.log("token", token);
    //       const cookies = new Cookies();
    //         cookies.set("authToken", token, {
    //         maxAge: 2592000,
    //         domain: "localhost",
    //       });
    //       if (rememberMe) {
    //         cookies.set("rememberedEmail", email, { maxAge: 2592000 });
    //       } else {
    //         cookies.remove("rememberedEmail");
    //       }
    //       // Use the token to authenticate with Cloud Firestore

    //       // Save the token to local storage

    //       // Redirect to the dashboard
    //       // window.location.href = "http://localhost:3000";
    //     })
    //     .catch(() => setHasErrors(true));
    // }
  };

  return (
    <form id="login-form" onSubmit={handleSubmit} className={classes.form}>
      {/* {Object.keys(fieldsWithError).length > 0 && (
        <>
          <Alert severity="error">Incorrect email or password</Alert>
          <br />
        </>
      )} */}
      <TextField
        id="email"
        name="email"
        label="Email"
        type="email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        required={true}
        hasError={""}
        regex={emailRE}
        onError={setFieldError}
        // errorMessage="Email is not a valid Email Address"
        className={"login"}
      />
      <div className={classes.rememberMe}>
        <div
          className={classes.link}
          onClick={() => {
            setCurrentScreen("forgot-password");
          }}
        >
          Forgot password
        </div>

        <FormControlLabel
          control={<Checkbox color="primary" />}
          label="Remember me"
          checked={rememberMe}
          onChange={handleRememberMeChange}
          className={classes.label}
        />
      </div>
      <Button
        disabled={hasErrors !== false ? "disabled" : ""}
        className={classes.submitButton}
        onClick={handleSubmit}
      >
        Continue
      </Button>

      {/* <Button
        type="submit"
        disabled={hasErrors !== false ? "disabled" : ""}
        className={classes.submitButton}
      >
        Continue
      </Button> */}

      <SnackbarComponent
        message={"Please enter a valid email"}
        open={open}
        onOpen={setOpen}
      />
    </form>
  );
}
