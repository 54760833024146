import { 
    makeStyles,
    Grid,
    FormHelperText
} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-evenly'
    },
    socialLink: {
        color:'#262626',
    }
}));

const Icons = {
    'facebook': FacebookIcon,
    'instagram': InstagramIcon,
    'linkedin': LinkedInIcon,
    'twitter': TwitterIcon,
}

const socialMediaLinks = [
    {
        link: 'https://www.facebook.com/mikiertraha',
        title: 'Go to Facebook',
        type: 'facebook'
    },
    {
        link: 'https://www.linkedin.com/mikiertraha',
        title: 'Go to Linkedin',
        type: 'linkedin'
    },
    {
        link: 'https://www.instagram.com/mikiertraha',
        title: 'Go to Instagram',
        type: 'instagram'
    },
    {
        link: 'https://www.twitter.com/mikiertraha',
        title: 'Go to twitter',
        type: 'twitter'
    },
]

export default function SocialMedia() {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            {
                socialMediaLinks?.length > 0 && socialMediaLinks.map(({ link, title, type }, i) => {
                    const Icon = Icons[type] ? Icons[type] : Icons['facebook'];
                    return (
                        <a className={classes.socialLink} key={`social-media-icon-${type}-${i}`} href={link} title={title} target="_blank" rel="noopener">
                            <Icon iconColor="defaultPrimary" />
                        </a>
                    )
                })
            }
        </div>
    )
}