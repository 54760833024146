import { useState, useEffect } from "react";
import { connectHits, connectStateResults } from "react-instantsearch-dom";
import SiteLayout from "../../layout/dashboard-layout/jobs-layout/index"
import DashboardLayout from "../../layout/dashboard-layout/index"
import Steps from "./steps/index";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import TypesenseSearchInterface from "../../utils/search/typesense-search-interface";
import { searchServerConfig } from "../../utils/search/typesense-config";
import TypesenseResultsTable from "../../utils/search/typesense-results-jobs";
import { makeStyles } from "@material-ui/styles";
import { getBGColorForService } from "../../utils/index";

const ResultsTable = connectHits(TypesenseResultsTable);

const useStyles = makeStyles((theme) => ({
    button: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "125px",
        height: "30px",
        backgroundColor: getBGColorForService("health"),
        borderRadius: "50px",
        fontWeight: "bold",
        color: "white",
        cursor: "pointer",
        padding: "20px 20px",
        transition: "all 0.3s ease-in-out",
        border: `solid 4px ${getBGColorForService("health")}`,

        "&:hover": {
            color: "black",

            backgroundColor: "white",
        },
    },
    buttonWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export default function JobsOverview() {

    const classes = useStyles();

    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [viewModal, setViewModal] = useState();
    const [hitsPerPage, setHitsPerPage] = useState(50);

    const searchParameters = {
        query_by:
            "title," +
            "companyName," +
            "description," +
            "service," +
            /*
                      "country," +
                      "createdText," +
                  */
            "",
        sort_by: "publishDate:desc,_text_match:desc",
        filter_by: "status:!=[trash, draft]",
    };
    const valueMapping = {
        AUA: "aua",
        BON: "bon",
        CUR: "cur",
    };

    const mappedCountry = selectedCountries?.map(
        (value) => valueMapping[value] || value
    );

    //   if (selectedServices !== "all" || selectedCountries !== "all") {
    //     var countryArr = selectedCountries;
    //     var countryArrAll = countryArr.indexOf("all") > -1;
    //     var serviceArr = selectedServices;
    //     var serviceArrAll = serviceArr.indexOf("all") > -1;

    if (selectedServices?.length > 0) {
        searchParameters["filter_by"] += `&& service:=[${selectedServices}]`;
    }
    if (selectedCountries?.length > 0) {
        searchParameters["filter_by"] += `&& country:=[${mappedCountry}]`;
    }

    useEffect(() => {
        setHitsPerPage(50);
    }, [selectedServices, selectedCountries]);


    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: searchServerConfig,
        additionalSearchParameters: searchParameters,
    });
    const searchClient = typesenseInstantsearchAdapter.searchClient;

    useEffect(() => {

        const filterParams = localStorage.getItem("filterParams");
        if (filterParams) {
            const params = JSON.parse(filterParams);
            const services = params ? params.services : "";
            setSelectedServices(services);
        }
    }, []);


    const onSetSelectedService = (service) => {
        if (service === "all") {
            setSelectedServices([]);
        } else if (selectedServices?.includes(service)) {
            setSelectedServices((prevFilters) =>
                prevFilters.filter((f) => f !== service)
            );
        } else {
            setSelectedServices((prevFilters) => [...prevFilters, service]);
        }
    };

    const onSetSelectedCountry = (country) => {
        if (country === "all") {
            setSelectedCountries([]);
        } else if (selectedCountries?.includes(country)) {
            setSelectedCountries((prevFilters) =>
                prevFilters.filter((f) => f !== country)
            );
        } else {
            setSelectedCountries((prevFilters) => [...prevFilters, country]);
        }
    };

    return (
        <TypesenseSearchInterface
            indexName={"jobs"}
            client={searchClient}
            hitsPerPage={hitsPerPage}
        >
            <DashboardLayout /*{...props.options} seo={props.pageData.seo}*/
                pageCategory="overview"
                onSetSelectedService={onSetSelectedService}
                selectedServices={selectedServices}
                onSetSelectedCountry={onSetSelectedCountry}
                selectedCountries={selectedCountries}
                setSelectedServices={setSelectedServices}
                setSelectedCountries={setSelectedCountries}
            >
                <ResultsTable
                    setHitsPerPage={setHitsPerPage}
                    hitsPerPage={hitsPerPage}
                />
            </DashboardLayout>
        </TypesenseSearchInterface>
    );
}
