import {
    makeStyles,
    MenuList,
    MenuItem,
    Grow,
    Popper,
    ClickAwayListener,
    ListSubheader,
} from "@material-ui/core/";
import { logout } from "../../../utils/authentication";
import ProfileIcon from "../../../assets/svg/profile.svg";
import CVIcon from "../../../assets/svg/cv.svg";
import LogOutIcon from "../../../assets/svg/logout.svg";
import SettingsIcon from "../../../assets/png/Gear.png";

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: "none",
    },
    menuList: {
        backgroundColor: "#FFFFFF",
        margin: 0,
        top: 12,
        padding: 0,
        right: 15,
        paddingTop: 15,
        paddingBottom: 15,
        border: "1px solid black",
        borderTop: "none",
    },
    menuItem: {
        color: "#262626",
        fontSize: "14px",
        lineHeight: "28px",
        padding: "5px 30px",
        minWidth: "175px",
        borderBottom: "0",
        fontFamily: "Roboto",
        fontWeight: "400",
        "&:hover, &:active": {
            color: "#000000",
            backgroundColor: "#ffffff !important",
        },
    },
    menuIcon: {
        marginRight: 5,
        width: 20,
        height: 20,
    },
    fullName: {
        borderBottom: "1px solid rgba(255,255,255,0.1)",
        backgroundColor: "#262626",
        color: "#FFF",
        fontSize: "12px",
        textTransform: "uppercase",
    },
}));

export default function AccountMenu({ open, anchorEl, onClick, fullName,handleNavigation}) {
    const classes = useStyles();

    return (
        <Popper
            open={open}
            anchorEl={anchorEl}
            role={undefined}
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === "bottom" ? "center top" : "center bottom",
                    }}
                >
                    <ClickAwayListener onClickAway={onClick}>
                        <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            className={classes.menuList}
                        // subheader={<ListSubheader className={classes.fullName}>{fullName || ""}</ListSubheader>}
                        >
                            <MenuItem
                                onClick={() => handleNavigation("profile/")}
                                className={classes.menuItem}
                            >
                                <img
                                    src={ProfileIcon}
                                    alt={`Profile ${fullName}`}
                                    className={classes.menuIcon}
                                />{" "}
                                Profile
                            </MenuItem>
                            <MenuItem
                                onClick={() => handleNavigation("profile/cv/")}
                                className={classes.menuItem}
                            >
                                <img
                                    src={CVIcon}
                                    alt={`CV ${fullName}`}
                                    className={classes.menuIcon}
                                />{" "}
                                CV
                            </MenuItem>
                            <MenuItem
                                onClick={() => handleNavigation("settings/")}
                                className={classes.menuItem}
                            >
                                <img src={SettingsIcon} className={classes.menuIcon} /> Settings
                            </MenuItem>
                            <MenuItem onClick={() => logout()} className={classes.menuItem}>
                                <img
                                    src={LogOutIcon}
                                    alt={`Logout Mi Kier Traha`}
                                    className={classes.menuIcon}
                                />
                                Log Out
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Grow>
            )}
        </Popper>
    );
}
