import { makeStyles, Grid, Typography } from "@material-ui/core/";
import FiveBlocks from "../../../icons/block";

const useStyles = makeStyles((theme) => ({
  main: {
    position: "relative",
    marginTop: 70,
    backgroundColor: theme.palette.secondary.light,
    minHeight: "calc( 100vh - 70px )",
    height: "100%",
  },
  grid: {
    height: "calc( 100vh - 70px )",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: "14px !important",
    marginTop: "15px !important",
  },
}));

export default function Loader({ searching }) {
  const classes = useStyles();

  return (
    <main role="main" className={classes.main}>
      <Grid container item xs={12} className={classes.grid}>
        {/* <img src={PreloadAnimation} alt="Loading Mi Kier Traha content" width="30" height="30" /> */}
        {searching ? <FiveBlocks /> : "No Results"}
      </Grid>
    </main>
  );
}
