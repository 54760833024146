import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { useContext } from "react";
import { Context } from "../../utils/context-provider";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoginModal from "./login-modal";
// import { useNavigate, useLocation } from "react-router-dom";
// import Cookies from "universal-cookie";
// import RegisterCompanyForm from "../../components/authentication/register-company-form";

const useStyles = makeStyles((theme) => ({
  root: (isModal) => ({
    height: "100%",
    margin: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
  tabContainer: {
    margin: "20px 0",
  },
  formContainer: {
    width: "80%",
    maxWidth: "900px",
    justifyContent: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  formControl: {
    margin: "10px 0",
  },
  rememberMe: {
    margin: "10px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  customIndicator: {
    backgroundColor: "#059c75",
    width: "100%",
  },
  logo: {
    display: "block",
    height: "75px",
    maxWidth: "auto",
    padding: 0,
    margin: "0 auto",
  },
  lineItem: {
    width: "100%",
  },
  tabPanel: {
    alignItems: "center",
  },
  tab: {
    "&.MuiTab-root": {
      minWidth: "50%",
    },
  },
}));

const LoginPage = ({ isModal }) => {
  const classes = useStyles(isModal);
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const pageState = searchParams.get("lastPage");

  const { user, loadingAuthState } = useContext(Context);

  useEffect(() => {
    if (user) {
      navigate(pageState !== null ? `/job/${pageState}` : `/jobs`);
    } else {
      const loginUrl = pageState !== null ? `/login/?lastPage=${pageState}` : "/login";
      navigate(loginUrl);
    }
  }, [user, isModal, navigate, pageState]);


  //   const location = useLocation();
  //   const userType = new URLSearchParams(location.search).get("userType"); // or use location.pathname for URL-based approach

  //   const isCompanyPath = location.pathname === "/company";

  //   useEffect(() => {
  //     if (user) {
  //       navigate("/dashboard");
  //     } else {
  //       const cookies = new Cookies();
  //       // cookies.remove("service");
  //       // cookies.remove("country");
  //       cookies.remove("authToken");
  //     }
  //   }, [user]);

  if (loadingAuthState) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  //   const TabPanel = (props) => {
  //     const { children, value, index, ...other } = props;

  //     return (
  //       <Typography
  //         component="div"
  //         role="tabpanel"
  //         hidden={value !== index}
  //         id={`simple-tabpanel-${index}`}
  //         aria-labelledby={`simple-tab-${index}`}
  //         {...other}
  //       >
  //         <Box p={isModal ? 0 : 3}>{children}</Box>
  //       </Typography>
  //     );
  //   };

  return (
    <div className={classes.root}>
      <Grid container className={classes.formContainer}>
        <LoginModal />
      </Grid>
    </div>
  );
};

export default LoginPage;
