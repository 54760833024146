import { makeStyles, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import ForgotPasswordForm from "./forgot-password-form";
import ModalSuccess from "./modal-success";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },

  modalBody: {
    textAlign: "center",
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const [showSuccess, setShowSuccess] = useState(false);
  const [email, setEmail] = useState("");

  const handleClose = (value) => {
    setShowSuccess(false);
  };

  return (
    <>
      <ForgotPasswordForm
        onSubmitComplete={setShowSuccess}
        email={email}
        setEmail={setEmail}
      />

      <ModalSuccess open={showSuccess} onClose={handleClose}>
        <Grid
          container
          direction="row"
          className={classes.container}
          spacing={0}
        >
          <Grid item xs={12} md={12} lg={8} className={classes.modalBody}>
            <Typography className={classes.modalBody}>
              An email instructions has been sent to {email}. Follow the link
              for more instructions to reset your password.
            </Typography>
          </Grid>
        </Grid>
      </ModalSuccess>
    </>
  );
}
