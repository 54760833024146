import { useState } from 'react';
import {
    makeStyles,
    InputBase,
    useMediaQuery,
    useTheme,
    Hidden
} from '@material-ui/core/';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        width: '250px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft:15,
    },
    searchMobile: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft:0,
    },
    inputRoot: {
        width: '100%',
        height: '100%',
        marginBottom: '0 !important',
        borderRadius:'22px',
        borderWidth:'2px',
        borderStyle:'solid',
        borderColor: theme.palette.secondary.dark,
        backgroundColor: theme.palette.secondary.light,
        fontSize: '11px !important',
        paddingLeft:'10px',
    },
    inputInput: {
        fontSize: 11,
    },
    iconSearch: {
        position:'absolute',
        right:8,
    },
    searchContainer: {
        position:'relative',
        top:0,
        left:0,
        width:'100%',
        backgroundColor:'white',
        borderBottom:'1px solid rgba(0,0,0,0.2)',
        padding:15,
    },
    toggleSearch: {
        cursor:'pointer',
    },
}));

export default function TypesenseSearchBox({
    currentRefinement,
    refine,
    delay = 250,
    isSearchStalled,
    placeholderText = 'Search...' ,
    onSearchClick,
    isSearchOpen,
}) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [isOpen, setOpen] = useState(false);

    const [timerId, setTimerId] = useState(null);
    const [value, setValue] = useState(currentRefinement);

    const onChangeDebounced = ({ currentTarget }) => {
        const { value } = currentTarget;
        clearTimeout(timerId)
        setTimerId(setTimeout(() => refine(value), delay));
        setValue(value);
    }

    return (
        <>
            <Hidden mdDown>
                {!matches &&
                    <div className={classes.search}>
                        <InputBase
                            placeholder={placeholderText}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            value={value}
                            onChange={onChangeDebounced}
                        />
                        <SearchIcon color="secondary" className={classes.iconSearch} />
                        {isSearchStalled && ''}
                    </div>
                }
            </Hidden>
            <Hidden mdUp>
                {matches &&
                    <>
                        <div className={classes.searchContainer}>
                            <div className={classes.searchMobile}>
                                <InputBase
                                    placeholder={placeholderText}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    value={value}
                                    onChange={onChangeDebounced}
                                />
                                {isSearchStalled && ''}
                            </div>
                        </div>
                    </>
                }
            </Hidden>
        </>
    )
}