import {
    TextField,
    makeStyles,
    createStyles,
    Popper
} from '@material-ui/core/';
import Heading from '../typography/heading';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) =>
    createStyles({
        "& .MuiOutlinedInput-adornedEnd": {
            backgroundColor: 'green',
        },
        root: {
            paddingLeft:20,
            paddingRight:20,
            zIndex: 60,
            
            "& .MuiOutlinedInput-notchedOutline": {
                border:'2px solid #808080',
                borderRadius: '40px',
            },
            "& .MuiPaper-root": {
                margin: 0,
            },
            "& .MuiAutocomplete-listbox": {
                padding: 0,
                "& li": {
                    backgroundColor: theme.palette.secondary.light,
                    borderBottom: '1px solid #ECECEC',
                    "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        "& h6": {
                            color: theme.palette.secondary.light,
                        }
                    },
                },
                "& .MuiAutocomplete-option[data-focus='true'], & .MuiAutocomplete-option[aria-selected='true']": {
                    backgroundColor: theme.palette.primary.main,
                    borderBottom: '1px solid rgba(255,255,255,0.2)',
                    "& h6": {
                        color: theme.palette.secondary.light,
                    }
                },
                "& h6": {
                    fontSize: 12,
                    textTransform: 'none',
                    margin: 0
                }
            }
        }
    })
);

export default function AutoCompleteField({
    id = "auto-complete-field",
    name = "auto-complete-field",
    options = [],
    label = "Auto complete field",
    value,
    onChange,
    multiple,
    //validation,
}) {
    const classes = useStyles();

    let selectedItem;
    if (multiple) {
        selectedItem = options?.filter(i => value?.some(v => v?.id === i?.id));
    }
    else {
        selectedItem = options?.find(v => v?.id === value);
    }

    const handleChange = (_, option) => {
        onChange && onChange({ target: { id, value: option?.id || undefined } })
    };

    const handleOptionSelected = (option, value) => {
        return option?.id === value?.id
    };

    return (
        <Autocomplete
            id={id}
            name={name}
            value={selectedItem || null}
            options={options || []}
            getOptionLabel={({ id, label }) => label || id || ''}
            autoComplete={true}
            multiple={multiple}
            fullWidth
            onChange={handleChange}
            getOptionSelected={handleOptionSelected}
            renderInput={params => <TextField {...params} label={label} variant="outlined" fullWidth />}
            renderOption={({ label }) => <Heading variant="h6" color="secondary">{label}</Heading>}
            PopperComponent={props => <Popper {...props} className={classes.root}></Popper>}
        />
    )
}