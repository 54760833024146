import { useEffect } from "react";
import { makeStyles, Grid } from "@material-ui/core/";
import {
  getBGColorForService,
  getColorForService,
} from "../../../../utils/index";
import Heading from "../../../../components/base-components/typography/heading";

const cubeSize = {
  xs: "calc((100vw - 4rem) / 2)",
  sm: "calc((100vw - 5rem) /3)",
  md: "calc((100vw - 5rem) / 6)",
  lg: "calc((100vw - 5rem) / 6)",
  xsc: "calc((100vw - 5rem) / 4)",
  smc: "calc((100vw - 6rem) / 6)",
  mdc: "calc((100vw - 135px) / 12)",
  lgc: "calc((100vw - 135px) / 12)",
};

const useStyles = makeStyles((theme) => ({
  nav: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 60,
    paddingBottom: 60,
  },
  jobItems: {
    position: "relative",
    height: "100%",
    paddingTop: 4,
    paddingRight: 4,
    paddingBottom: 4,
    paddingLeft: 4,
    "&.country": {
      padding: 4,
    },

    "&:first-child": {
      paddingLeft: 8,
    },
    // "&:last-child": {
    //   paddingRight: 8,
    // },
  },
  jobItem: {
    width: "100%",
    height: "100%",
    textTransform: "capitalize",
    fontWeight: "bold",
    cursor: "pointer",
    backgroundColor: (p) => getBGColorForService(p.service),
    color: (p) => getColorForService(p.service),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.3s ease-in-out",

    "&.active": {
      boxShadow:
        "inset -5px -5px 9px rgba(65, 65, 65, 0.45), inset 5px 5px 9px rgba(94,104,121,0.3)",
      "& $extraInformation": {
        opacity: 1,
      },
    },
    "&.health,&.aua": {
      backgroundColor: (p) => getBGColorForService("health"),
    },
    "&.professional,&.bon": {
      backgroundColor: (p) => getBGColorForService("professional"),
    },
    "&.engineering,&.cur": {
      backgroundColor: (p) => getBGColorForService("engineering"),
    },
    "&.hospitality": {
      backgroundColor: (p) => getBGColorForService("hospitality"),
    },
    "&.public": { backgroundColor: (p) => getBGColorForService("public") },

    //   button:active {
    // 	transform: translateY(3px);
    // 	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    //   }

    //Header square has the same width but half size...
    "&.headerCountry": {
      [theme.breakpoints.up("xs")]: {
        height: cubeSize.xsc,
        width: cubeSize.xs,
      },
      [theme.breakpoints.up("sm")]: {
        height: cubeSize.smc,
        width: cubeSize.sm,
      },
      [theme.breakpoints.up("md")]: {
        height: cubeSize.mdc,
        width: cubeSize.md,
      },
      [theme.breakpoints.up("lg")]: {
        height: cubeSize.lgc,
        width: cubeSize.lg,
      },
    },

    // country square has half of the bigsquare
    "&.country": {
      [theme.breakpoints.up("xs")]: {
        height: cubeSize.xsc,
        width: cubeSize.xsc,
      },
      [theme.breakpoints.up("sm")]: {
        height: cubeSize.smc,
        width: cubeSize.smc,
      },
      [theme.breakpoints.up("md")]: {
        height: cubeSize.mdc,
        width: cubeSize.mdc,
      },
      [theme.breakpoints.up("lg")]: {
        height: cubeSize.lgc,
        width: cubeSize.lgc,
      },
    },

    // These are the original size of the bigger squares

    [theme.breakpoints.up("xs")]: {
      height: cubeSize.xs,
      width: cubeSize.xs,
    },
    [theme.breakpoints.up("sm")]: {
      height: cubeSize.sm,
      width: cubeSize.sm,
    },
    [theme.breakpoints.up("md")]: {
      height: cubeSize.md,
      width: cubeSize.md,
    },
    [theme.breakpoints.up("lg")]: {
      height: cubeSize.lg,
      width: cubeSize.lg,
    },

    "&:hover": {
      "& $extraInformation": {
        opacity: 1,
      },
    },

    "&.header": {
      backgroundColor: "unset",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  clear: {
    position: "absolute",
    top: 15,
    right: 0,
    height: 50,
    width: 50,
  },
  bold: {
    color: "white",
  },
  header: {
    wordBreak: "break-word",
    color: "black",
  },
  extraInformation: {
    opacity: 0,
    position: "absolute",
    top: 10,
    left: 10,
    backgroundColor: "#262626",
    width: "calc(100% - 20px)",
    height: "calc(100% - 20px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.15s linear",

    "&.country": {},
  },
  filterContainer: {
    flexWrap: "nowrap!important",
    overflowX: "auto",
    flexDirection: "column",

    [theme.breakpoints.up("xs")]: {
      overflowX: "scroll",
      justifyContent: "space-unset",
      flexDirection: "row",
    },

    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
      overflowX: "auto",
      flexDirection: "row",
    },
  },
}));

export default function FilterNav({
  onSelected,
  selected,
  filters,
  title,
  isCountry,
}) {
  const classes = useStyles();

  return (
    <Grid container className={classes.filterContainer}>
      <Grid item className={`${classes.jobItems} `}>
        <div
          className={`${classes.jobItem} header  ${
            isCountry && "headerCountry"
          }`}
        >
          <Heading
            color="secondary.dark"
            variant="h6"
            className={`${classes.header}`}
          >
            {title}
          </Heading>
        </div>
      </Grid>

      {filters?.map((service, i) => {
        const valueMapping = {
          AUA: "aua",
          BON: "bon",
          CUR: "cur",
        };
        const categories = [
        //   {
        //     listOfCategories: ["it", "developer", "electrician"],
        //     industry: "engineering",
        //   },
        //   {
        //     listOfCategories: ["medic", "doctor", "etc"],
        //     industry: "health",
        //   },
        ];

        const catForIndustry = categories.find(
          (cat) => cat.industry === service
        );
        const mapped = isCountry ? valueMapping[service] : service;
        return (
          <Grid
            key={`service-filter-item-${service}-${i}`}
            item
            className={`${classes.jobItems} ${isCountry && "country"}`}
            data-service={service}
            onClick={() => {
              if (isCountry) {
                return onSelected(mapped);
              } else {
                return onSelected(service);
              }
            }}
          >
            <div
              className={`${classes.jobItem} ${service.toLowerCase()} ${
                selected?.includes(mapped) ? "active" : ""
              } ${isCountry && "country"}`}
            >
              <Heading variant="h2" color="secondary.light">
                {service}
              </Heading>
              <div className={classes.extraInformation}>
                <Heading variant="h2" color="secondary.light">
                  {service}
                </Heading>
                {catForIndustry &&
                  !isCountry &&
                  catForIndustry?.listOfCategories.map((cat) => {
                    return <div key={cat}>{cat}</div>;
                  })}
              </div>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}
