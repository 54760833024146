import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
    palette: {
        primary: {
            main: '#2589BD',
        },
        secondary: {
            light: '#FFFFFF',
            dark: '#262626',
            main: '#707070'
        },
    },
    typography: {
        fontFamily: 'itc-avant-garde-gothic-pro',
        h1: {
            fontSize: '40px',
            fontWeight: 900,
            lineHeight: '40px',
            letterSpacing: 0,
            textTransform:'none',
            marginBottom: 15,
            // color:secondary.dark,
        },
        h2: {
            fontSize: ' clamp( 16px, 18px, 21px );',
            fontWeight: 600,
            lineHeight: "24px",
            textTransform: 'uppercase',
            marginBottom: 15,
        },
        h3: {
            fontSize: '29px',
            fontWeight: 600,
            lineHeight: '38px',
            textTransform: 'none',
            marginBottom: 15,
        },
        h4: {
            fontSize: '26px',
            fontWeight: 600,
            lineHeight: '38px',
            textTransform: 'none',
            marginBottom: 15,
        },
        h5: {
            fontSize: '23px',
            fontWeight: 600,
            lineHeight: '38px',
            textTransform: 'none',
            marginBottom: 15,
        },
        h6: {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '38px',
            textTransform: 'none',
            marginBottom: 15,
        },
        body1: {
            fontSize: '14px',
            lineHeight: '28px',
            marginBottom: 15,
            fontFamily:'Roboto',
        },
        body2: {
            fontSize: '14px',
            lineHeight: '28px',
            marginBottom: 15
        }
    },
    shape: {
        borderRadius: 0
    },
    overrides: {
        MuiToolbar: {
            regular: {
                minHeight: '60px !important'
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none',
                height: '43px',
                padding: '15px 15px',
                minWidth: '175px',
                width:'100%',
                fontSize: '14px',
                lineHeight: '28px',
                fontWeight: 400,
                letterSpacing: 0
            },
        },
        MuiOutlinedInput: {
            root: {
                height: '45px',
                backgroundColor: 'transparent',
            },
            input: {
                border: 0,
                fontSize:12,
                padding: '0 15px',
            },
            notchedOutline: {
                border:'2px solid #808080',
                borderRadius: '40px',
            },
            multiline: {
                height: '250px',
                paddingRight:0, 
                borderRadius:'10px !important',
            },
            inputMultiline: {
                height: '240px',
                marginTop:10,
                marginBottom:10, 
                borderRadius:'10px !important',
            },
            adornedEnd: {
                backgroundColor:'transparent',
            },
        },
        MuiToggleButton: {
            root: {
                '&.Mui-selected': {
                    backgroundColor:'rgba(0,0,0,0.2)',
                    color:'#FFF'
                },
            },
        },
        MuiFormLabel: {
            root: {
                color: '#9C9C9C',
                fontWeight: 'normal',
                fontSize:13,
                transform: 'translate(0px, 0px) scale(1)',
                left:10,
            },
            filled: {
            }
        },
        MuiInputLabel: {
            shrink: {
                transform: 'translate(7px, -3px) scale(0.75) !important'
            },
            formControl: {
                left:10
            },
            outlined: {
                lineHeight:"10px"
            },
        },
        MuiSelect: {
            root: {
                height: '100%',
            },
            select: {
                '&:focus': {
                    backgroundColor:'#FFFFFF'
                },
            },
            icon: {
                color:'#2589BD'
            }
        },
        MuiInputBase: {
            root: {
                marginBottom:0,
            },
            formControl: {
                backgroundColor:'transparent',
                "& .MuiOutlinedInput-input": {
                    backgroundColor:'transparent',
                }, 
            },   
            input: {
                color:'black'
            }        
        },
        MuiMenu: {
            list: {
                padding:0,
            }
        },
        MuiMenuItem: {
            root: {
                fontSize:12,
                lineHeight:'28px',
                marginBottom: 0,
                marginTop:0,
                '&.Mui-selected, &.Mui-selected:hover, &:hover': {
                    backgroundColor:'#2589BD',
                    color:'#FFFFFF'
                }
            }
        },
        MuiIconButton: {
            root: {
                padding:0
            }
        },
        MuiDialogActions: {
            root: {
                '& button.MuiButton-containedPrimary': {
                    padding:0,
                    backgroundColor:'transparent',
                    color:'#2589BD',
                    boxShadow: 'none',
                }
            }
        },
        MuiInputAdornment: {
            root: {
                height: '100%',
                maxHeight: '100%',
                "& p": {
                    marginBottom: 0,
                    fontSize:12,
                }
            }
        },
        MuiFormHelperText: {
            root: {
                "&.Mui-error": {
                    margin:'0 0 30px 0'
                }
            }
        },
        MuiTabs: {
            indicator: {
                backgroundColor: '#FC5000'
            }
        },
        MuiDivider: {
            root: {
                marginBottom: 15,
                marginTop: 15
            }
        },
        MuiFormControl: {
            root: {
                "&.datepicker": {
                    width:"100%",
                    "& .MuiFormLabel-root": {
                        color: '#9C9C9C',
                        font:'inherit',
                        fontSize:13,
                        marginBottom: 0,
                        left: '24px',
                        top: '10px',
                        transform: 'translate(0px, 0px) scale(1)',
                    },
                    '& .MuiInputLabel-shrink': {
                        transform: 'translate(-7px, -19px) scale(0.75) !important'
                        
                    },
                    "& .MuiInputBase-root": {
                        borderRadius:'40px',
                    },
                    "& .MuiOutlinedInput-input": {
                        fontSize: '13px',
                        fontWeight: 'bold',
                        fontFamily: 'inherit',
                    }
                }
            }
        },
        MuiButtonBase: {
            root: {
                '&.Mui-selected': {
                    backgroundColor:'#2589BD !important'
                }
            }
        },
    },
    props: {
        MuiButton: {
            disableElevation: false,
            disableRipple: true,
            variant: 'contained',
            color: 'primary'
        },
        MuiTextField: {
            fullWidth: true,
            variant: 'outlined',
            color: 'primary',
            
        },
        MuiIconButton: {
            disableRipple: true
        },
    }
});

export default theme;