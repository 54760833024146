import { Grid, makeStyles, Button } from "@material-ui/core";
import { getBGColorForService } from "../../utils/";
import Heading from "../../components/base-components/typography/heading";
// import { motion } from 'framer-motion';
import { getFormattedDateShort } from "../../utils/date-format";
// import moment from 'moment';
import { Favorite } from "@material-ui/icons";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useContext } from "react";
import { Context } from "../../utils/context-provider";

const useStyles = makeStyles((theme) => ({
    jobLink: {
        backgroundColor: (p) => getBGColorForService(p.service),
        cursor: "pointer",
        position: "relative",
        textDecoration: "none",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",

        "&:hover": {
            "& $extraInformation": {
                opacity: 1,
            },
        },
    },
    jobInfo: {
        color: theme.palette.secondary.light,
        paddingLeft: 15,
        paddingRight: 15,
        position: "absolute",
        height: "50%",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
    },
    title: {
        top: "0",
        fontWeight: 900,
        alignItems: "flex-end",
        marginBottom: "0 !important",
        [theme.breakpoints.down('sm')]: {
            fontSize:13,
            lineHeight:'17px',
        },
    },
    companyName: {
        fontSize: 16,
        lineHeight: "21px",
        fontWeight: 400,
        margin: 0,
        fontStyle: "none",
        display: "flex",
        position: "absolute",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "flex-start",
        bottom: 0,
        fontFamily: "Roboto",
        [theme.breakpoints.down('sm')]: {
            fontSize:13,
            lineHeight:'17px',
        },
    },
    country: {
        fontSize: 16,
        lineHeight: "18px !important",
        fontWeight: 400,
        bottom: -22,
        alignItems: "center",
        margin: 0,
        textTransform: "uppercase",
        fontStyle: "normal",
        [theme.breakpoints.down('sm')]: {
            fontSize:13,
            lineHeight:'17px',
        },
    },
    extraInformation: {
        opacity: 0,
        position: "absolute",
        top: 8,
        left: 8,
        backgroundColor: "#262626",
        width: "calc(100% - 16px)",
        height: "calc(100% - 16px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.15s linear",
    },
    metaInfo: {
        fontFamily: "Roboto",

        color: theme.palette.secondary.light,
        fontSize: 14,
        lineHeight: "21px",
        paddingLeft: 8,
        paddingRight: 8,
        textTransform: "uppercase",
        fontWeight: 600,

        "&.centerInfo": {
            marginTop: 8,
            marginBottom: 8,
            fontWeight: 600,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize:12,
            lineHeight:'21px',
        },
    },
    topSection: {
        position: "absolute",
        top: 3,
        left: 3,
        height: "25%",
    },
    favorite: {
        height: "18px",
        width: "18px",
        position: "absolute",
        top: 7,
        right: 7,
        color: "white",
    },
}));

export default function Job({
    id,
    service,
    title,
    companyName,
    level,
    minimumExperienceRequired,
    type,
    publishDate,
    country,
}) {
    const classes = useStyles({ service: service?.toLowerCase() || "" });
    const { user } = useContext(Context);
    const isFavorite = user?.favoritedJobs?.includes(id);
    const isSelectedJob = user?.selectedJobs?.includes(id);

    const appliedJob = user?.appliedJobs?.find((job) => job.id === id);

    const isApplied = !!appliedJob;
    const appliedDate = isApplied ? getFormattedDateShort(appliedJob.appliedForDate) : null;

    return (
        <a href={`/job/${id}`} passHref>
            <Grid
                className={classes.jobLink}
                // component={motion.div}
                // animate={{ opacity: 1, x: 0, y: 0 }}
                // initial={{ opacity: 0, }}
                // exit={{ opacity: 0, display: "none" }}
                layout
            >
                {(isFavorite || isSelectedJob) && <Favorite className={classes.favorite} />}
                {isApplied && <CheckCircleOutlineIcon className={classes.favorite} />}

                {/* <HeartOutlineIcon className="favorite-icon" /> */}
                {/* <EditButton onClick={() => navigate(`/add-or-update-job/${id}`)} /> */}
                {/* <DeleteButton onClick={() => onDeleteItem(id)} /> */}
                <div className={classes.topSection}>
                    {publishDate > 0 && (
                        <div className={`${classes.metaInfo}`} color="secondary.light">
                            {getFormattedDateShort(publishDate)}
                        </div>
                    )}
                </div>
                <Heading
                    variant="h2"
                    className={`${classes.jobInfo} ${classes.title}`}
                    color="secondary.light"
                >
                    {title}
                </Heading>
                <span
                    className={`${classes.jobInfo} ${classes.companyName}`}
                    color="secondary.light"
                >
                    {companyName}
                    <em className={`${classes.country}`}>{country || "AUA"}</em>
                </span>
                {isApplied ? (
                    <div className={classes.extraInformation}>
                        <div
                            className={`${classes.metaInfo} centerInfo`}
                            color="secondary.light"
                        >
                            Applied on {appliedDate}
                        </div>
                    </div>
                ) : (
                    <div className={classes.extraInformation}>
                        {level && (
                            <div className={`${classes.metaInfo}`} color="secondary.light">
                                {level}
                            </div>
                        )}
                        {minimumExperienceRequired && (
                            <div
                                className={`${classes.metaInfo} centerInfo`}
                                color="secondary.light"
                            >
                                {minimumExperienceRequired}
                            </div>
                        )}
                        {type && (
                            <div className={`${classes.metaInfo}`} color="secondary.light">
                                {type === "full-time" ? "Full time" : "Part time"}
                            </div>
                        )}
                        {/* {publishDate && <div className={`${classes.metaInfo}`} color="secondary.light">{moment(publishDate).startOf('day').fromNow()}</div>} */}
                    </div>
                )}
            </Grid>
        </a>
    );
}
