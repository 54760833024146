import { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {
    CalendarToday
} from '@material-ui/icons';
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    hideField: {
        display: 'none'
    },
}));

export default function DatePickerField({
    id,
    name,
    label,
    value,
    onChange,
    hasError = false,
    onError,
    errorMessage,
    required,
    disabled = false,
}) {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const dtValue = value ? new Date(value) : null;

    const handleDateChange = (date) => {
        let dt = date ? new Date(date).getTime() : null;
        onChange && onChange({ target: { id, value: dt } });
        setIsOpen(false);
    };

    const handleValidation = () => {
        let validateError = (required && value == null);
        onError && onError(id, validateError);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
            <KeyboardDatePicker
                id={id}
                name={name}
                label={label}
                value={dtValue}
                onChange={handleDateChange}
                onBlur={handleValidation}
                disabled={disabled}
                format="MMMM dd, yyyy"
                variant="dialog"
                className={disabled ? classes.hideField : ''}
                KeyboardButtonProps={{
                    'id': 'calendar-button-icon',
                    'aria-label': 'change date',
                    onFocus: e => {
                        setIsOpen(!dtValue);
                    }
                }}
                PopoverProps={{
                    disableRestoreFocus: true,
                    onClose: () => {
                        setIsOpen(false);
                    }
                }}
                open={isOpen}
                keyboardIcon={<CalendarToday color="primary" />}
                InputProps={{
                    onFocus: () => {
                        setIsOpen(!dtValue);
                    },
                    startAdornment:
                        dtValue ?
                            <IconButton onClick={() => handleDateChange()} disabled={!value} style={{ order: 1 }}>
                                <ClearIcon color="disabled" fontSize="small" />
                            </IconButton>
                            :
                            null
                }}
                InputAdornmentProps={{
                    position: "end",
                    style: { order: 2, marginLeft: 5, marginTop: -1 }
                }}
                error={hasError}
                helperText={hasError ? errorMessage : null}
            />
        </MuiPickersUtilsProvider>
    );
}