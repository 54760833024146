import { useContext } from "react";
import { Link } from "react-router-dom";
import {
    makeStyles,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from "@material-ui/core/";
import LogoCR38TE from "../../../assets/svg/logo_cr38te.svg";
import FavoritesIcon from "../../../assets/svg/favorite.svg";

// import DashboardIcon from "../../../assets/svg/dashboard.svg";
import JobsIcon from "../../../assets/svg/jobs.svg";
import AppliedJobsIcon from "../../../assets/svg/applied-jobs.svg";
import CVIcon from "../../../assets/svg/cv.svg";
import LogOutIcon from "../../../assets/svg/logout.svg";
import Settingsicon from "../../../assets/png/Gear.png";
import ProfileIcon from "../../../assets/svg/profile.svg";
// import AdsIcon from "../../../assets/svg/ads.svg";
// import CompaniesIcon from "../../../assets/svg/companies.svg";
import { Context } from "../../../utils/context-provider";
import { IS_ADMIN, IS_JOBSEEKER } from "../../../utils/constants";
import { logout } from "../../../utils/authentication";

const useStyles = makeStyles((theme) => ({
    drawerContainer: {
        overflow: "auto",
        marginTop: "60px",
    },
    list: {
        backgroundColor: theme.palette.secondary.light,
        padding: 0,
        height: "calc( 100vh - calc( 60px + 40px ))",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    link: {
        fontFamily: theme.typography.fontFamily,
        fontSize: "14px",
        lineHeight: "28px",
        fontWeight: 600,
        letterSpacing: 0,
        color: theme.palette.secondary.main,
        textDecoration: "none",
        padding: 0,
    },
    logo: {
        display: "block",
        height: "calc( 220px - 100px)",
        maxWidth: "auto",
        padding: 0,
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        marginBottom: 0,
        width: "80px",
    },
    listItem: {
        padding: "0 !important",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        margin: 0,
        fontFamily: "Roboto",
        fontWeight: "400",
        "&:hover, &:active": {
            backgroundColor: "#FFFFFF",
            color: "#262626",
        },
    },
    listItemIcon: {
        width: "75px",
        height: "70px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    listItemText: {
        marginTop: 8,
        "& span, & svg": {
            fontSize: "14px",
            fontFamily: "Roboto",
            color: "#262626",
            fontWeight: "500",
            margin: 0,
            "&:hover": {
                color: "#000",
            },
        },
    },
    footer: {
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: theme.palette.secondary.light,
        width: "100%",
        padding: "0",
        textAlign: "center",
        fontSize: "12px",
        lineHeight: "24px",
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        textDecoration: "none",
        margin: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        height: "40px",
        fontFamily: theme.typography.fontFamily,
        alignItems: "center",
        overflow: "hidden",
    },
    drawerToggle: {
        display: "flex",
        justifyContent: "flex-end",
        "& a": {
            width: "24px",
            color: theme.palette.primary.main,
            textDecoration: "none",
            marginRight: 10,
            "& > svg": {
                transform: "rotate(180deg)",
            },
        },
    },
    removeMargin: {
        marginTop: 0,
        marginBottom: 0,
    },
    menuIcon: {
        marginRight: 0,
        width: 35,
        height: 35,
        color: "black",
    },
}));

export default function ListMainNav() {
    const classes = useStyles();
    const { user } = useContext(Context);

    const isAdmin = !!user && user?.claims && user?.claims[IS_ADMIN];
    const isJobSeeker = !!user && user?.claims && user?.claims[IS_JOBSEEKER];

    return (
        <div className={`${classes.drawerContainer}`}>
            <List component="nav" className={classes.list}>
                {/* {isAdmin && (
                    <>
                        <Link to="/admin" className={classes.link}>
                            <ListItem button className={classes.listItem}>
                                <Tooltip title="Dashboard" placement="right" arrow>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <img
                                            src={DashboardIcon}
                                            alt={`Dashboard`}
                                            className={classes.menuIcon}
                                        />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    primary={"Dashboard"}
                                    className={classes.listItemText}
                                />
                            </ListItem>
                        </Link>
                        <Link to="/admin/jobs/" className={classes.link}>
                            <ListItem button className={classes.listItem}>
                                <Tooltip title="Job Listings" placement="right" arrow>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <img
                                            src={JobsIcon}
                                            alt={`Job Listings`}
                                            className={classes.menuIcon}
                                        />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    primary={"Job Listings"}
                                    className={classes.listItemText}
                                />
                            </ListItem>
                        </Link>
                        <Link to="/admin/companies/" className={classes.link}>
                            <ListItem button className={classes.listItem}>
                                <Tooltip title="Company Listings" placement="right" arrow>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <img
                                            src={CompaniesIcon}
                                            alt={`Company Listings`}
                                            className={classes.menuIcon}
                                        />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    primary={"Company Listings"}
                                    className={classes.listItemText}
                                />
                            </ListItem>
                        </Link>
                        <Link to="/admin/ads/" className={classes.link}>
                            <ListItem button className={classes.listItem}>
                                <Tooltip title="Ad Listings" placement="right" arrow>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <img
                                            src={AdsIcon}
                                            alt={`Ad Listings`}
                                            className={classes.menuIcon}
                                        />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    primary={"Ad Listings"}
                                    className={classes.listItemText}
                                />
                            </ListItem>
                        </Link>
                        <Link to="/admin/user-accounts/" className={classes.link}>
                            <ListItem button className={classes.listItem}>
                                <Tooltip title="User Accounts" placement="right" arrow>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <img
                                            src={ProfileIcon}
                                            alt={`User Accounts`}
                                            className={classes.menuIcon}
                                        />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    primary={"User Accounts"}
                                    className={classes.listItemText}
                                />
                            </ListItem>
                        </Link>
                    </>
                )} */}
                {(isAdmin || isJobSeeker) && (
                    <>
                        <Link to="/jobs" className={classes.link}>
                            <ListItem button className={classes.listItem}>
                                <Tooltip title="Jobs" placement="right" arrow>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <img
                                            src={JobsIcon}
                                            alt={`Job`}
                                            className={classes.menuIcon}
                                        />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    primary={"Jobs"}
                                    className={classes.listItemText}
                                />
                            </ListItem>
                        </Link>
                        <Link to="/applied-jobs" className={classes.link}>
                            <ListItem button className={classes.listItem}>
                                <Tooltip title="Job Applications" placement="right" arrow>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <img
                                            src={AppliedJobsIcon}
                                            alt={`Job Applications`}
                                            className={classes.menuIcon}
                                        />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    primary={"Job Applications"}
                                    className={classes.listItemText}
                                />
                            </ListItem>
                        </Link>
                        <Link to="/job-cart" className={classes.link}>
                            <ListItem button className={classes.listItem}>
                                <Tooltip title="Favorites" placement="right" arrow>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <img
                                            src={FavoritesIcon}
                                            alt={`Favorites`}
                                            className={classes.menuIcon}
                                        />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    primary={"Favorites"}
                                    className={classes.listItemText}
                                />
                            </ListItem>
                        </Link>
                        <Link to="/profile/" className={classes.link}>
                            <ListItem button className={classes.listItem}>
                                <Tooltip title="Profile" placement="right" arrow>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <img
                                            src={ProfileIcon}
                                            alt={`Profile`}
                                            className={classes.menuIcon}
                                        />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    primary={"Profile"}
                                    className={classes.listItemText}
                                />
                            </ListItem>
                        </Link>
                        <Link to="/profile/cv/" className={classes.link}>
                            <ListItem button className={classes.listItem}>
                                <Tooltip title="CV" placement="right" arrow>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <img
                                            src={CVIcon}
                                            alt={`CV`}
                                            className={classes.menuIcon}
                                        />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    primary={"CV"}
                                    className={classes.listItemText}
                                />
                            </ListItem>
                        </Link>
                        <Link to="/settings/" className={classes.link}>
                            <ListItem button className={classes.listItem}>
                                <Tooltip title="Settings" placement="right" arrow>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        {/* <img src={SettingIcon} alt={`Log out`} className={classes.menuIcon} /> */}

                                        <img
                                            src={Settingsicon}

                                            className={classes.menuIcon}
                                            alt={`Settings`}
                                        />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    primary={"Profile"}
                                    className={classes.listItemText}
                                />
                            </ListItem>
                        </Link>
                    </>
                )}
                <ListItem onClick={() => logout()} button className={classes.listItem}>
                    <Tooltip title="Log Out" placement="right" arrow>
                        <ListItemIcon className={classes.listItemIcon}>
                            <img
                                src={LogOutIcon}
                                alt={`Log Out`}
                                className={classes.menuIcon}
                            />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={"Log Out"} className={classes.listItemText} />
                </ListItem>
            </List>
            <a
                href="https://www.cr38te.com"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footer}
            >
                <img className={classes.logo} src={LogoCR38TE} alt="CR38TE" />
            </a>
        </div>
    );
}
