import { useState } from "react";

import Alert from "@material-ui/lab/Alert";
import TextField from "../../../components/base-components/form-fields/text-field";
import { passwordResetMail } from "../../../utils/server-functions";
import { Button, ButtonBase, makeStyles } from "@material-ui/core";
import { getBGColorForService } from "../../../utils";

const emailRE = /^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+).([a-zA-Z]{2,5})$/;

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(2),
    border: `1px solid ${getBGColorForService("orange")}`,
    background: getBGColorForService("orange"),
    color: "white",
    borderRadius: "30px",

    width: "150px",
    "&:hover": {
      background: "#773512",
    },
  },
  form: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "300px",
  },
}));
export default function ForgotPasswordForm({
  onSubmitComplete,
  email,
  setEmail,
}) {
  const classes = useStyles();

  const [hasErrors, setHasErrors] = useState(false);
  const [fieldsWithError, setFieldsWithError] = useState({});

  const setFieldError = (id, hasError) => {
    let check = { ...fieldsWithError };
    if (hasError) {
      check[id] = true;
    } else {
      delete check[id];
    }
    setFieldsWithError(check);
  };

  const handleSubmit = (event) => {
    let check = { ...fieldsWithError };
    const requiredFields = ["email"];
    requiredFields.forEach((requiredField) => {
      if (!email || check[requiredField]) {
        check[requiredField] = true;
      } else {
        delete check[requiredField];
      }
    });
    if (Object.keys(check).length > 0) {
      setFieldsWithError(check);
    } else {
      //toOpenDialog!
      onSubmitComplete(true);

      passwordResetMail(email).catch((error) => {
        console.log(error);
        setHasErrors(true);
      });
    }
    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <TextField
        id="email"
        name="email"
        label="Email Address"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required={true}
        hasError={fieldsWithError["email"]}
        regex={emailRE}
        onError={setFieldError}
        errorMessage="Please enter a valid email address."
      />
      <Button
        variant="contained"
        color="primary"
        className={classes.submitButton}
        type="submit"
      >
        Forgot Password
      </Button>
    </form>
  );
}
