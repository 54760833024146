import {
    useState,
    useEffect,
    useContext,
    useRef,
} from 'react';
import {
    makeStyles,
    Grid,
    Tooltip,
    withWidth,
    CircularProgress,
} from '@material-ui/core';
import {
    CloudUpload,
    Cancel,
} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import {
    TextField,
    NativeSelectField,
    DatePickerFieldX,
    FileField,
} from '../base-components/form-fields';
import Heading from '../base-components/typography/heading';
import firebase from '../../utils/firebase';
import BaseButton from '../base-components/base-button/new-button';
import { genderOptions } from '../../utils/constants';
import { Context } from '../../utils/context-provider';
import { updateUser } from '../../utils/server-functions';
import AutocompleteCountryOptions from '../base-components/form-fields/autocomplete-country-options';
import ScrollToNav from '../profile/update-cv/scroll-to-nav';
import SnackbarComponent from "../../components/snackbar";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        height: '100%'
    },
    mainPadding: {
        padding: '120px 60px 60px 60px',
        [theme.breakpoints.down('xl')]: {
        },
        [theme.breakpoints.down('lg')]: {
        },
        [theme.breakpoints.down('md')]: {
            padding: '90px 30px 60px 30px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
        [theme.breakpoints.down('xs')]: {
        },
    },
    mainContainerResponsive: {
        [theme.breakpoints.down('sm')]: {
            padding: '120px 15px 0 15px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '120px 15px 0 15px',
        },
    },
    container: {
        paddingRight: 30,
        flexDirection: 'column',
    },
    floatingBox: {
        backgroundColor: '#FFFFFF',
        padding: 30,
        boxShadow: '-7px 7px 20px #00000029',
        width: '100%',
        position: 'sticky',
        top: 110,
        height: 375,
        [theme.breakpoints.down('lg')]: {
            height: 425
        },
    },
    buttons: {
        width: '100%',
    },
    imageContainer: {
        position: 'relative',
        marginBottom: 30,
        "&:hover": {
            "& svg": {
                display: "block"
            }
        }
    },
    profileImage: {
        width: '170px',
        height: '170px',
        display: 'block',
        borderRadius: '50%',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
    },
    removeImage: {
        position: 'absolute',
        top: '0',
        left: '150px',
        cursor: 'pointer',
        color: '#990000'
    },
    relative: {
        position: 'relative',
    },
    avatar: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
    editIcon: {
        cursor: 'pointer',
        position: 'absolute',
        top: 12,
        left: 120,
        fontSize: 16,
        color: '#059c75',
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: '5px 8px',
        fontSize: 10,
        marginLeft: 5,
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    mb: {
        marginBottom: 30
    },
    section: {
        marginTop: '30px',
        borderRadius: '10px',
        backgroundColor: '#ffffff',
        border: '1px solid rgba(0,0,0,0.1)',
        padding: '30px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            marginBottom: 30,
        },
        [theme.breakpoints.down('xs')]: {
            padding: 15,
            marginBottom: 15,
        },
    },
    animateIn: {
        position: 'fixed',
        bottom: 15,
        right: 15,
        backgroundColor: '#F26419',
        width: 40,
        height: 40,
        borderRadius: '50%',
        boxShadow: '1px 1px 2px rgba(0,0,0,0.3)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '2px 2px 3px rgba(0,0,0,0.4)',
            backgroundColor: '#c95a1e',
        }
    },
    icon: {
        color: '#FFF'
    },
    listSticky: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        position: 'fixed',
        bottom: 70,
        right: 21,
        '& > li ': {
            marginTop: 15
        },
        '& > li > div': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            textDecoration: 'none',
            color: theme.palette.secondary.dark,
            cursor: 'pointer',
            '& > span': {
                fontSize: '11px',
            },
            '&:hover': {
                '& > div': {
                    boxShadow: '2px 2px 3px rgba(0,0,0,0.4)',
                    backgroundColor: '#262626',
                }
            }
        }
    },
    iconDiv: {
        backgroundColor: '#F26419',
        width: 30,
        height: 30,
        borderRadius: '50%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 15,
        transition: 'all 0.2s linear',
        '& > svg': {
            fontSize: '1.1rem !important',
        }
    },
    hideForSmallScreen: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
            visibility: 'hidden'
        },
    }
}));

export default withWidth()(function UpdateProfile({ width }) {
    const classes = useStyles();

    const divRef = useRef(null);
    const [isOpenNav, setIsOpenNav] = useState(false);

    const { user } = useContext(Context);
    const id = user?.id || '';
    const storage = firebase.storage();

    const [accountInfo, setAccountInfo] = useState({ ...user });
    const [fieldsWithError, setFieldsWithError] = useState({});
    const [loading, setLoading] = useState(false);
    const [toastOpen,setToastOpen]=useState(false);


    useEffect(() => {
        setAccountInfo({ ...user });
    }, [user])

    // useLayoutEffect(() => {
    //     const handleScroll = () => {
    //         setScrollY(window.scrollY);
    //         setRefY(window.innerHeight);

    //         if (divRef.current) {
    //             const divRect = divRef.current?.getBoundingClientRect();

    //             const yPos = divRect.top + window.scrollY;
    //             const scrollY = window.scrollY;

    //             if (scrollY >= yPos) {
    //                 setRefY(yPos);
    //             }

    //             if (scrollY === 0) {
    //                 setIsOpenNav(false);
    //             }
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, [divRef.current])

    const showStickyNav = () => {
        setIsOpenNav(!isOpenNav);
    }

    const setFieldError = (id, hasError) => {
        let check = { ...fieldsWithError };
        if (hasError) {
            check[id] = true;
        } else {
            delete check[id];
        }
        setFieldsWithError(check);
    };

    const save = async (event) => {
        let check = { ...fieldsWithError };
        const requiredFields = ['firstName', 'lastName', 'phone'];
        event.preventDefault();

        requiredFields.forEach((requiredField) => {
            if (!accountInfo[requiredField] || check[requiredField]) {
                check[requiredField] = true;
            } else {
                delete check[requiredField];
            }
        });

        if (Object.keys(check).length > 0) {
            setFieldsWithError(check);
            setToastOpen(true)
        } else {
            console.log("entered")
            //handle Update
            setLoading(true);

            try {
                await updateUser({
                    id,
                    ...accountInfo
                })
            }
            catch (error) {
                console.log(error);
            };
            setLoading(false);
            setToastOpen(true)
        }
    };

    const updatePhotoURL = async () => {
        const imageRef = storage.ref(`users/${id}/profilePicture/profile-picture.jpg`);
        try {
            const photoURL = await imageRef.getDownloadURL();
            await updateUser({ photoURL });
        }
        catch (error) {
            console.log(error);
        };
    }

    const onChange = (e) => {
        if (e.target.name === 'phone') {
            const onlyNums = e.target.value.replace(/[^0-9()+]{1}/g, '');
            setAccountInfo({ ...accountInfo, [e.target.id]: onlyNums });
        } else {
            setAccountInfo({ ...accountInfo, [e.target.id]: e.target.value });
        }
    };

    const onClickRemoveProfilePhoto = () => {
        setAccountInfo({
            ...accountInfo,
            photoURL: ''
        });
    }

    // if (loading) {
    //     return <Preloader />
    // }
    // if (error) {
    //     return <p>Something went wrong, please try again later</p>
    // }

    const labelText = 'Save';
    const labelTextSaving = <CircularProgress />;

    const refIDs = [
        {
            'title': 'Personal Information',
            'refID': '#personal-information'
        },
        // {
        //     'title': 'Documents',
        //     'refID': '#documents'
        // },
        // {
        //     'title': 'Security',
        //     'refID': '#security'
        // },
    ];

    return (
        <>
            <Grid container direction="column" className={classes.mainContainer} ref={divRef}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className={classes.mainPadding}>
                        <Grid container direction="row" className={classes.mainContainer}>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                <ScrollToNav data={refIDs} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={7} xl={6} className={classes.mainContainerResponsive}>
                                <Grid container direction="column">
                                    <Grid item xs={12} container className={classes.mb}>
                                        <Grid item xs={12} sm={12} md={8} lg={8}>
                                            <Heading color="secondary.dark" variant="h3">My Profile</Heading>
                                        </Grid>
                                        <Grid container xs={6} sm={6} md={4} lg={4} justifyContent="flex-end" className={classes.hideForSmallScreen}>
                                            <BaseButton
                                                label={loading ? labelTextSaving : labelText}
                                                onClick={save}
                                                color="primary"
                                                endIcon={<CloudUpload />}
                                                className={classes.buttons}
                                            />
                                        </Grid>
                                        <Grid id="personal-information" item xs={12} container justifyContent="space-between" className={classes.section}>
                                            <Grid container spacing={2} direction="row">
                                                <Grid item sm={12} md={12} lg={12}>
                                                    {
                                                        accountInfo?.photoURL
                                                            ?
                                                            <Grid item xs={7} sm={7} md={7} lg={7}>
                                                                <div className={classes.imageContainer}>
                                                                    <div
                                                                        className={classes.profileImage}
                                                                        style={{ 'backgroundImage': `url(${accountInfo.photoURL}` }}
                                                                        onError={() => {
                                                                            console.log('Error loading image, removing src url');
                                                                            setAccountInfo({ ...accountInfo, photoURL: '' });
                                                                        }}
                                                                    />
                                                                    <Tooltip
                                                                        title={`Remove current profile image and upload a new one.`}
                                                                        aria-label='upload'
                                                                        placement='top'
                                                                        arrow
                                                                    >
                                                                        <Cancel
                                                                            className={classes.removeImage}
                                                                            fontSize="small"
                                                                            onClick={onClickRemoveProfilePhoto}
                                                                        />
                                                                    </Tooltip>
                                                                </div>
                                                            </Grid>
                                                            :
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <FileField
                                                                    id="upload-file-input"
                                                                    name="upload-file-input"
                                                                    accept=".jpeg, .jpg, .png,"
                                                                    setRefreshList={updatePhotoURL}
                                                                    storagePath={`users/${id}/profilePicture/`}
                                                                    labelButton="Upload profile photo"
                                                                    profile={true}
                                                                />
                                                            </Grid>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <Heading color="secondary.dark" variant="h6">Personal information</Heading>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                                    <TextField
                                                        id="firstName"
                                                        name="firstName"
                                                        label="First Name"
                                                        value={accountInfo?.firstName}
                                                        onChange={onChange}
                                                        required={true}
                                                        hasError={fieldsWithError['firstName']}
                                                        onError={setFieldError}
                                                        errorMessage="Must fill in a first name"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                                    <TextField
                                                        id="middleName"
                                                        name="middleName"
                                                        label="Middle Name"
                                                        value={accountInfo?.middleName}
                                                        onChange={onChange}

                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                                    <TextField
                                                        id="lastName"
                                                        name="lastName"
                                                        label="Last Name"
                                                        value={accountInfo?.lastName}
                                                        onChange={onChange}
                                                        required={true}
                                                        hasError={fieldsWithError['lastName']}
                                                        onError={setFieldError}
                                                        errorMessage="Must fill in a last name"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                                    <TextField
                                                        id="email"
                                                        name="email"
                                                        label="Email"
                                                        disabled={true}
                                                        value={accountInfo?.email}
                                                        onChange={onChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                                    <NativeSelectField
                                                        id="gender"
                                                        name="gender"
                                                        label="Gender"
                                                        value={accountInfo?.gender}
                                                        options={genderOptions}
                                                        onChange={onChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                                    <DatePickerFieldX
                                                        id="dateOfBirth"
                                                        name="dateOfBirth"
                                                        label="Date of Birth"
                                                        value={accountInfo?.dateOfBirth}
                                                        className="datepicker"
                                                        onChange={onChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                                    <AutocompleteCountryOptions
                                                        id="placeOfBirth"
                                                        name="placeOfBirth"
                                                        label="Place of Birth"
                                                        value={accountInfo?.placeOfBirth}
                                                        onChange={onChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                                    <AutocompleteCountryOptions
                                                        id="phoneCode"
                                                        name="phoneCode"
                                                        label="Country Code"
                                                        value={accountInfo?.phoneCode}
                                                        showCode={true}
                                                        onChange={onChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                                    <TextField
                                                        id="phone"
                                                        name="(Mobile) Phone"
                                                        label="Phone"
                                                        value={accountInfo?.phone}
                                                        onChange={onChange}
                                                        required={true}
                                                        hasError={fieldsWithError['phone']}
                                                        onError={setFieldError}
                                                        errorMessage="Must fill in a phone number"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid id="documents" item xs={12} container justifyContent="space-between" className={classes.section}>
                                            <Grid container spacing={2} direction="row">
                                                <Grid item sm={12} md={12} lg={12}>
                                                    <Heading color="secondary.dark" variant="h6">Documents</Heading>
                                                    <Typography variant="body1">
                                                        *** Upload here documents such as: driver license(s), green card, permits etc.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid> */}
                                        {/* <Grid id="security" item xs={12} container justifyContent="space-between" className={classes.section}>
                                            <Grid container spacing={2} direction="row">
                                                <Grid item sm={12} md={12} lg={12}>
                                                    <Heading color="secondary.dark" variant="h6">Security</Heading>
                                                    <Security />
                                                </Grid>
                                            </Grid>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

                <div
                className={classes.animateIn }
                onClick={showStickyNav}
            >
                <AddIcon className={classes.icon} />
            </div>
            <SnackbarComponent
        message={"Profile has been updated!"}
        open={toastOpen}
        onOpen={setToastOpen}
      />
            {isOpenNav &&
                <ul className={classes.listSticky}>
                    <li>
                        <div onClick={save}>
                            <span>{loading ? labelTextSaving : labelText}</span>
                            <div className={classes.iconDiv}>
                                <SaveIcon className={classes.icon} />
                            </div>
                        </div>
                    </li>
                </ul>
            }
        </>
    )
});