import {
  makeStyles,
  Grid,
  Badge,
  IconButton,
  useTheme,
  useMediaQuery,
  Hidden,
  MenuItem,
  Avatar,
} from "@material-ui/core/";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { connectSearchBox } from "react-instantsearch-dom";
import Filter from "../../../../icons/filter";
import TypesenseSearchBox from "../../../../utils/search/typesense-search-box-overview";
import AccountMenu from "../../header/account-menu";
import { useNavigate } from "react-router-dom";
import Logo from "../../../../assets/svg/logo_dark.svg";

import SettingsIcon from "@material-ui/icons/Settings";
import { useContext, useRef, useState } from "react";
import { Context } from "../../../../utils/context-provider";
import { Notifications } from "@material-ui/icons";
import { useEffect } from "react";

const SearchBox = connectSearchBox(TypesenseSearchBox);

const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    height: "70px",
    padding: 0,
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 3px 6px #0000000D",
    borderBottom: "1px solid #0000000D",
    backgroundColor: theme.palette.secondary.light,
  },
  logo: {
    display: "block",
    height: "39px",
    width: "200px",
    padding: 0,
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      height: "auto",
      display: "block",
    },
  },
  filterButton: {
    marginLeft: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontSize: 14,
    lineHeight: "28px",
    fontWeight: "bold",
    transition: "all 0.2s linear",
    cursor: "pointer",
    width: "100%",
  },
  searchButton: {
    width: 250,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  backButton: {
    position: "absolute",
    left: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontSize: 14,
    lineHeight: "28px",
    fontWeight: "bold",
    transition: "all 0.2s linear",

    "&:hover": {
      "& $textBackButton": {
        opacity: 1,
        visibility: "visible",
      },
    },
  },
  iconButton: {
    marginRight: "8px",
  },
  textBackButton: {
    opacity: 0,
    visibility: "hidden",
  },
  customBadge: {
    backgroundColor: "#48ff00",
    height: 12,
    width: 12,
    borderRadius: "50%",
  },
  notifications: {
    marginRight: 20,
    width: 30,
    height: 30,
  },
}));

export default function Header({
  mainNavigation,
  pageCategory,
  onCloseFilterNav,
  onShowFilterNav,
  onSearchClick,
  isOpen,
  isSearchOpen,
  isFiltered,
  singlePage,

}) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const { user } = useContext(Context);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [numberFavorites, setNumberFavorites] = useState(0);

  useEffect(() => {
    setNumberFavorites(user?.favoritedJobs?.length);
  }, [user]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleNotificationClick = () => {
    navigate("/job-cart");
  };

  return (
    <header role="header" className={classes.header}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={3}
          md={4}
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >

          {pageCategory === "overview" && (
            <div className={classes.filterButton}>
              {isOpen ? (
                <div onClick={() => onCloseFilterNav(true)}>
                  {isFiltered ? (
                    <Badge
                      variant="dot"
                      classes={{ badge: classes.customBadge }}
                    >
                      <Filter />
                    </Badge>
                  ) : (
                    <Filter />
                  )}
                </div>
              ) : (
                <div onClick={() => onShowFilterNav(false)}>
                  {isFiltered ? (
                    <Badge
                      variant="dot"
                      classes={{ badge: classes.customBadge }}
                    >
                      <Filter />
                    </Badge>
                  ) : (
                    <Filter />
                  )}
                </div>
              )}
              <Hidden mdDown>
                {!matches && <SearchBox placeholderText="Search jobs" />}
              </Hidden>
            </div>
          )}
          {/* {pageCategory !== "home" && (
                        <NextLink className={classes.backButton} href="/">
                            <KeyboardBackspaceIcon className={classes.iconButton} />
                            <span className={classes.textBackButton}>back</span>
                        </NextLink>
                    )} */}
        </Grid>
        <Grid
          item
          xs={3}
          md={4}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <a href="/">
            <img className={classes.logo} src={Logo} alt="Mi Kier Traha" />
          </a>
        </Grid>
        <Hidden mdUp>
          {matches && !singlePage ? (
            <Grid
              item
              xs={3}
              md={3}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <div className={classes.searchButton}>
                <SearchBox
                  placeholderText="Search jobs"
                  onSearchClick={onSearchClick}
                  isSearchOpen={isSearchOpen}
                />
              </div>
            </Grid>
          ) : null}
        </Hidden>
        {/* <Grid
          item
          xs={3}
          md={3}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <MenuItem
            onClick={() => navigate("/admin/account/")}
            className={classes.menuItem}
          >
            <SettingsIcon className={classes.menuIcon} /> Account
          </MenuItem>
        </Grid> */}

        <Grid
          item
          xs={4}
          md={4}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <IconButton onClick={handleNotificationClick} color="inherit"  className={classes.notifications}>
            <Badge
              badgeContent={
                numberFavorites>= 1
                  ? numberFavorites
                  : 0
              }
              color="primary"
            >
              <Notifications  />
            </Badge>
          </IconButton>
          <Avatar
            className={classes.avatar}
            alt={user.firstName || ""}
            src={user.photoURL}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup={true}
            onClick={() => setOpen((prevOpen) => !prevOpen)}
          />
          <AccountMenu
            open={open}
            setOpen={setOpen}
            anchorEl={anchorRef.current}
            onClick={handleClose}
            fullName={`Howdy, ${user.firstName || ""}`}
          />
        </Grid>

        {/* {user && <AccountMenuItem user={user} signOut={signOut} />}
                {!user && <LoginSignUpMenuItem />} */}
        {/* <FilterNavContainer theme={theme} /> */}
        {/* <StyledSearch /> */}
        {/* <MainNavigation menuItems={mainNavigation} /> */}
      </Grid>
    </header>
  );
}
