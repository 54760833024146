import {
    makeStyles,
    Grid,
    Box,
    Typography,
    CircularProgress,
} from "@material-ui/core";

import Heading from "../base-components/typography/heading";
import { searchServerConfig } from "../../utils/search/typesense-config";
import Typesense from "typesense";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../utils/context-provider";
import Job from "../job-cart/job";

const useStyles = makeStyles(() => ({
    mainPadding: {
        padding: "90px 15px 15px 15px",
    },
    mt: {
        marginTop: "15px",
        display: "flex",
        flexWrap: "wrap",
    },
}));

const typesenseClient = new Typesense.Client(searchServerConfig);
const typesenseCollection = typesenseClient.collections("jobs");

export default function AppliedJobsOverview() {
    const classes = useStyles();
    const { user } = useContext(Context);

    const [jobsData, setJobsData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchJobsData = async () => {
            try {
                setLoading(true);

                if (user.appliedJobs.length === 0) {
                    setLoading(false);
                    return; // Exit function if list is empty
                }

                const searchParameters = {
                    q: `*`,
                    filter_by: `status:=published`,
                };
                // if (user.appliedJobs.length > 0) {
                const idFilters = user.appliedJobs.map((job) => `${job.id}`);
                searchParameters.filter_by += ` && id:[${idFilters.join(",")}]`;
                // }
                // else {
                //   searchParameters.filter_by += ` && id:nonexistent_id`;
                // }

                const { hits } = await typesenseCollection
                    .documents()
                    .search(searchParameters);

                const jobDataArray = hits.map((doc) => doc.document);
                const userAppliedJob = user?.appliedJobs.reduce((obj, item) => {
                    obj[item.id] = item;
                    return obj;
                }, {});

                // Loop through items and add date prop from map
                const itemsCombined = jobDataArray?.map((item) => {
                    return {
                        companyName: item.companyName,
                        service: item.service,
                        country: item.country,
                        title: item.title,
                        publishDate: item.publishDate,
                        appliedForDate: (userAppliedJob[item.id] || {}).appliedForDate,
                    };
                });
                setLoading(false);
                setJobsData(itemsCombined);
            } catch (error) {
                setLoading(false);

                console.error("Error fetching jobs data:", error);
            }
        };

        if (user.appliedJobs.length > 0) {
            fetchJobsData();
        }
    }, [user?.appliedJobs]);

    return (
        <Grid container direction="column" className={classes.mainPadding}>
            <Grid item xs={12} container justifyContent="space-between">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Heading color="secondary.dark" variant="h3">
                        Applied Jobs
                    </Heading>
                </Grid>
            </Grid>
            {!loading ? (
                <Grid item spacing={1} xs={12} container>
                    {jobsData?.length > 0 ? (
                        jobsData?.map((item, i) => (
                            <Grid item>
                                <div
                                    className={`${classes.jobItems} element-item ${item?.service}`}
                                    key={`${item?.id}-${i}`}
                                    data-category={item?.service}
                                >
                                    <Job applied={true} {...item} />
                                </div>
                            </Grid>
                        ))
                    ) : (
                        <Grid item xs={12} md={12}>
                            <Typography>
                                You have not applied to any jobs yet. Let's change that!
                                Applying to jobs on our site is easy. Here are some tips to get
                                started:
                                <ul>
                                    <li>
                                        Visit the jobs overview page and browse openings by category
                                        or keyword.
                                    </li>
                                    <li>Favourite jobs you're interested in to review later.</li>
                                    <li>
                                        Make sure your profile accurately represents your
                                        background.
                                    </li>
                                    <li>
                                        Highlight key achievements, skills, and past
                                        responsibilities.
                                    </li>
                                    <li>
                                        Customize your application for each job with a tailored
                                        cover letter.
                                    </li>
                                    <li>
                                        Apply to several jobs that appeal to you. The more
                                        applications, the better your chances!
                                    </li>
                                    <li>
                                        Set up alerts so you never miss suitable job openings as
                                        soon as they are posted.
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            ) : (
                <CircularProgress />
            )}
        </Grid>
    );
}
