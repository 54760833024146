import { useState, useContext, useRef, useLayoutEffect } from "react";
import {
  makeStyles,
  Grid,
  withWidth,
  CircularProgress,
} from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Heading from "../../base-components/typography/heading";
import firebase, {
  useCollectionData,
  useDocumentData,
} from "../../../utils/firebase";
import BaseButton from "../../base-components/base-button/new-button";
import Preloader from "../../preloader";
import { Context } from "../../../utils/context-provider";
import Experience from "./experience";
import Education from "./education";
import { ChipSelectField } from "../../base-components/form-fields";
import {
  CVS_COLLECTION,
  SKILLS_COLLECTION,
  USERS_COLLECTION,
} from "../../../utils/constants";
import generateCV from "../../../utils/generate-cv";
import { cvDataConverter } from "../../../utils/data-converters";
import ScrollToNav from "./scroll-to-nav";
import SnackBarComponent from "../../../components/snackbar";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100%",
  },
  mainPadding: {
    padding: "120px 60px 60px 60px",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      padding: "90px 30px 60px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
    [theme.breakpoints.down("xs")]: {},
  },
  mainContainerResponsive: {
    [theme.breakpoints.down("sm")]: {
      padding: "120px 15px 0 15px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "120px 15px 0 15px",
    },
  },
  container: {
    paddingRight: 30,
    flexDirection: "column",
  },
  floatingBox: {
    backgroundColor: "#FFFFFF",
    padding: 30,
    boxShadow: "-7px 7px 20px #00000029",
    width: "100%",
    position: "sticky",
    top: 110,
    height: 375,
    [theme.breakpoints.down("lg")]: {
      height: 425,
    },
  },
  buttons: {
    width: "100%",
  },
  section: {
    marginTop: "30px",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    border: "1px solid rgba(0,0,0,0.1)",
    padding: "30px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: 30,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 15,
      marginBottom: 15,
    },
  },
  animateIn: {
    position: "fixed",
    bottom: 15,
    right: 15,
    backgroundColor: "#F26419",
    width: 40,
    height: 40,
    borderRadius: "50%",
    boxShadow: "1px 1px 2px rgba(0,0,0,0.3)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "2px 2px 3px rgba(0,0,0,0.4)",
      backgroundColor: "#c95a1e",
    },
  },
  icon: {
    color: "#FFF",
  },
  listSticky: {
    margin: 0,
    padding: 0,
    listStyle: "none",
    position: "fixed",
    bottom: 70,
    right: 21,
    "& > li ": {
      marginTop: 15,
    },
    "& > li > div": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      textDecoration: "none",
      color: theme.palette.secondary.dark,
      cursor: "pointer",
      "& > span": {
        fontSize: "11px",
      },
      "&:hover": {
        "& > div": {
          boxShadow: "2px 2px 3px rgba(0,0,0,0.4)",
          backgroundColor: "#262626",
        },
      },
    },
  },
  iconDiv: {
    backgroundColor: "#F26419",
    width: 30,
    height: 30,
    borderRadius: "50%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 15,
    transition: "all 0.2s linear",
    "& > svg": {
      fontSize: "1.1rem !important",
    },
  },
  hideForSmallScreen: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
      visibility: "hidden",
    },
  },
}));

export default withWidth()(function UpdateCV({ width }) {
  const classes = useStyles();
  const divRef = useRef(null);
  const [isOpenNav, setIsOpenNav] = useState(false);

  const { user, skillsData } = useContext(Context);

  const [data, loading, error, onChange] = useDocumentData(
    CVS_COLLECTION,
    user.cvID,
    cvDataConverter
  );

  const [fieldsWithError, setFieldsWithError] = useState({
    experiences: [{}],
    educations: [{}],
    expertises: [],
  });

  const [skillsOption] = useCollectionData(SKILLS_COLLECTION);
  const [saving, setSaving] = useState([{}]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);

  // useLayoutEffect(() => {
  //     const handleScroll = () => {
  //         setScrollY(window.scrollY);
  //         setRefY(window.innerHeight);

  //         if (divRef.current) {
  //             const divRect = divRef.current?.getBoundingClientRect();

  //             const yPos = divRect.top + window.scrollY;
  //             const scrollY = window.scrollY;

  //             if (scrollY >= yPos) {
  //                 setRefY(yPos);
  //             }

  //             if (scrollY === 0) {
  //                 setIsOpenNav(false);
  //             }
  //         }
  //     };

  //     window.addEventListener("scroll", handleScroll);

  //     return () => {
  //         window.removeEventListener("scroll", handleScroll);
  //     };
  // }, [divRef.current]);

  const showStickyNav = () => {
    setIsOpenNav(!isOpenNav);
  };

  const setFieldError = (id, index, hasError) => {
    let currentErrors = { ...fieldsWithError };

    currentErrors[id] = currentErrors[id] || [];
    currentErrors[id][index] = hasError;

    setFieldsWithError(currentErrors);
  };

  const validateFields = () => {
    let currentErrors = { ...fieldsWithError };
    let isValid = true;
    const { experiences = [], educations = [] } = data;

    for (let i = 0; i < experiences.length; i++) {
      const { jobTitle, company, started } = experiences[i] || {};
      if (!jobTitle || jobTitle.length <= 0) {
        currentErrors["experiences"][i]["jobTitle"] =
          !jobTitle || jobTitle.length <= 0;
        isValid = false;
      }
      if (!company || company.length <= 0) {
        currentErrors["experiences"][i]["company"] =
          !company || company.length <= 0;
        isValid = false;
      }
      if (!started) {
        currentErrors["experiences"][i]["started"] = !started;
        isValid = false;
      }
    }

    for (let i = 0; i < educations.length; i++) {
      const { title, institution, started } = educations[i] || {};
      if (!title || title.length <= 0) {
        currentErrors["educations"][i]["title"] = !title || title.length <= 0;
        isValid = false;
      }
      if (!institution || institution.length <= 0) {
        currentErrors["educations"][i]["institution"] =
          !institution || institution.length <= 0;
        isValid = false;
      }
      if (!started) {
        currentErrors["educations"][i]["started"] = !started;
        isValid = false;
      }
    }

    setFieldsWithError(currentErrors);

    return isValid;
  };

  const save = async () => {
    const isValid = validateFields();

    if (!isValid) return;

    setSaving(true);
    setLoadingSave(true);
    try {
      let cvID = user.cvID;

      if (!cvID) {
        cvID = firebase.firestore().collection(CVS_COLLECTION).doc().id;
        await firebase
          .firestore()
          .collection(USERS_COLLECTION)
          .doc(user.id)
          .set({ cvID, userChange: true }, { merge: true });
      }
      setToastOpen(false);

      await firebase
        .firestore()
        .collection(CVS_COLLECTION)
        .doc(cvID)
        .withConverter(cvDataConverter)
        .set({ ...data, userChange: true }, { merge: true });
      setLoadingSave(false);
      setToastOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  //   if (loading) {
  //     return <Preloader />;
  //   }
  if (error) {
    return <p>Something went wrong, please try again later</p>;
  }

  const labelText = "Save";
  const labelTextSaving = (
    <CircularProgress style={{ width: "25px", height: "25px" }} />
  );

  const onAddArrayItem = (id, index) => {
    const arr = [...(data[id] || [])];
    arr.splice(index, 0, {});
    onChange({ target: { id, value: arr } });
  };

  const onRemoveArrayItem = (id, index) => {
    const arr = [...(data[id] || [])];
    arr.splice(index, 1);
    onChange({ target: { id, value: arr } });
  };

  const onArrayChange = (id, index, item) => {
    const arr = [...(data[id] || [])];
    arr[index] = {
      ...(arr[index] || {}),
      ...item,
    };
    onChange({ target: { id, value: arr } });
  };

  const refIDs = [
    {
      title: "Experience",
      refID: "#experiences",
    },
    {
      title: "Education",
      refID: "#educations",
    },
    {
      title: "Skills",
      refID: "#skills",
    },
  ];

  data.experiences = data?.experiences ? data?.experiences : [{}];
  data.educations = data?.educations ? data?.educations : [{}];

  const isExperiencesEmpty = Object.keys(data.experiences[0]).length === 0;
  const isEducationsEmpty = Object.keys(data.educations[0]).length === 0;

//   const result = isExperiencesEmpty && isEducationsEmpty ? "" : "fill";


  return (
    <>
      <Grid
        container
        direction="column"
        className={classes.mainContainer}
        ref={divRef}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className={classes.mainPadding}>
            <Grid container direction="row" className={classes.mainContainer}>
              <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                <ScrollToNav data={refIDs} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={7}
                xl={6}
                className={classes.mainContainerResponsive}
              >
                <Grid container direction="column">
                  <Grid item xs={12} container className={classes.mb}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Heading color="secondary.dark" variant="h3">
                        My CV
                      </Heading>
                    </Grid>
                    <Grid
                      container
                      xs={6}
                      sm={6}
                      md={4}
                      lg={4}
                      justifyContent="flex-start"
                      className={classes.hideForSmallScreen}
                    >
                      <BaseButton
                        label={"Generate CV"}
                        onClick={() => generateCV(data, user, skillsData)}
                        color="primary"
                        endIcon={<CloudUpload />}
                        className={classes.buttons}
                        // disabled={result}
                      />
                    </Grid>
                    <Grid
                      container
                      xs={6}
                      sm={6}
                      md={4}
                      lg={4}
                      justifyContent="flex-end"
                      className={classes.hideForSmallScreen}
                    >
                      <BaseButton
                        label={loadingSave ? labelTextSaving : labelText}
                        onClick={save}
                        color="primary"
                        endIcon={<CloudUpload />}
                        className={classes.buttons}
                      />
                    </Grid>
                    <Grid
                      id="experiences"
                      item
                      xs={12}
                      container
                      justifyContent="space-between"
                      className={classes.section}
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Heading color="secondary.dark" variant="h6">
                          Experience
                        </Heading>
                        {data.experiences.map((experience, i) => (
                          <Experience
                            id={`user-cv-experience-${i}`}
                            key={`user-cv-experience-${i}`}
                            index={i}
                            data={experience}
                            onChange={(e) =>
                              onArrayChange("experiences", i, e.target.value)
                            }
                            onAddArrayItem={() =>
                              onAddArrayItem("experiences", i)
                            }
                            onRemoveArrayItem={() =>
                              onRemoveArrayItem("experiences", i)
                            }
                            setFieldError={setFieldError}
                            fieldsWithError={
                              (fieldsWithError["experiences"] || [])[i]
                            }
                          />
                        ))}
                      </Grid>
                    </Grid>
                    <Grid
                      id="educations"
                      item
                      xs={12}
                      container
                      justifyContent="space-between"
                      className={classes.section}
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Heading color="secondary.dark" variant="h6">
                          Education
                        </Heading>
                        {data.educations.map((education, i) => (
                          <Education
                            key={`user-cv-education-${i}`}
                            index={i}
                            data={education}
                            onChange={(e) =>
                              onArrayChange("educations", i, e.target.value)
                            }
                            onAddArrayItem={() =>
                              onAddArrayItem("educations", i)
                            }
                            onRemoveArrayItem={() =>
                              onRemoveArrayItem("educations", i)
                            }
                            setFieldError={setFieldError}
                            fieldsWithError={
                              (fieldsWithError["educations"] || [])[i]
                            }
                          />
                        ))}
                      </Grid>
                    </Grid>
                    <Grid
                      id="skills"
                      item
                      xs={12}
                      container
                      justifyContent="space-between"
                      className={classes.section}
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Heading color="secondary.dark" variant="h6">
                          Skills
                        </Heading>
                        <ChipSelectField
                          id="expertises"
                          name="expertises"
                          label="Expertises"
                          value={data.expertises || []}
                          options={skillsOption}
                          optionsId={SKILLS_COLLECTION}
                          onChange={onChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <div className={classes.animateIn} onClick={showStickyNav}>
        <AddIcon className={classes.icon} />
      </div>
      <SnackBarComponent
        message={"CV been updated!"}
        open={toastOpen}
        onOpen={setToastOpen}
      />
      {isOpenNav && (
        <ul className={classes.listSticky}>
          <li>
            <div onClick={() => generateCV(data, user, skillsData)}>
              <span>Generate C.V.</span>
              <div className={classes.iconDiv}>
                <PictureAsPdfIcon className={classes.icon} />
              </div>
            </div>
          </li>
          <li>
            <div onClick={save}>
              <span>{loadingSave ? labelTextSaving : labelText}</span>
              <div className={classes.iconDiv}>
                <SaveIcon className={classes.icon} />
              </div>
            </div>
          </li>
        </ul>
      )}
    </>
  );
});
