import { TextField, makeStyles, createStyles, Box } from "@material-ui/core/";
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { countries } from "../../../utils/constants";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            zIndex: 60,
            margin: 0,
            padding: 0,
            "& .MuiPaper-root": {
                margin: 0,
            },
        },
    })
);

export default function AutocompleteCountryOptions({
    id = "auto-complete-field",
    name = "auto-complete-field",
    label = "Auto complete field",
    value,
    onChange,
    showCode = false,
}) {
    const classes = useStyles();

    const handleChange = (_, option) => {
        onChange && onChange({ target: { id, value: option?.code || undefined } })
    };

    const handleOptionSelected = (option, value) => {
        return option?.code === value;
    };

    const filterOptions = createFilterOptions({
        stringify: (option) => `${option.label} (+${option.phone})`, // Combine name and phone number for searching
    });

    return (
        <Autocomplete
            id={id}
            name={name}
            value={value || null}
            options={countries || []}
            getOptionLabel={(props) => {
                const country = countries.find((item) => item.code === props);
                const { label = "", phone = "" } = country || {};
                return showCode ? `(+${phone})` : label;
            }}
            autoComplete={true}
            fullWidth
            onChange={handleChange}
            getOptionSelected={handleOptionSelected}
            renderInput={(params) => (
                <TextField {...params} label={label} variant="outlined" fullWidth />
            )}
            renderOption={(option) => {
                const code = option?.code?.toLowerCase() || "";
                return (
                    <Box
                        className={classes.root}
                        component="li"
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            margin: 0,
                            padding: "8px 16px",
                            backgroundColor: "#f5f5f5",
                            borderBottom: "1px solid #f5f5f5",
                            fontSize: "10px",
                            marginTop: 0,
                            marginBottom: 0,
                            "& > img": {
                                marginRight: "10px",
                                flexShrink: 0,
                                marginLeft: "10px",
                            },
                        }}
                    >
                        <img
                            src={`https://flagcdn.com/16x12/${code}.png`}
                            width="16"
                            height="12"
                            alt="South Africa"
                        />
                        {id === "placeOfBirth" ? (
                            <>
                                {option.label} ({option.code})
                            </>
                        ) : (
                            <>+ {option.phone}</>
                        )}
                    </Box>
                );
            }}
            filterOptions={filterOptions}
        />
    );
}
