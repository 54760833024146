import firebase from './firebase'

export const toFirestoreDateConverter = date => {
    const fromDate = firebase.firestore.Timestamp.fromDate;
    return date ? fromDate(new Date(date)) : null;
};

export const fromFirestoreDateConverter = date => {
    return (date?.toDate ? date?.toDate().getTime() : null);
};

export const toNumber = value => {
    if (!value) return 0;
    if (typeof value === 'number') return value;
    let newValue = +value.replace(',', '.');
    if (isNaN(newValue)) {
        const pIndex = value.indexOf('.');
        const cIndex = value.indexOf(',');
        if (pIndex > -1 && cIndex > -1) {
            newValue = pIndex > cIndex ? +value.replace(',', '') : +value.replace('.', '').replace(',', '.');
        } else {
            newValue = 0;
        }
    }
    return newValue;
};

export const jobDataConverter = {
    toFirestore(job) {
        return {
            ...job,
            titleLine1: job?.titleLine1 || '',
            updated: toFirestoreDateConverter(job.updated),
            created: toFirestoreDateConverter(job.created),
            publishDate: toFirestoreDateConverter(job.publishDate),
            unpublishDate: toFirestoreDateConverter(job.unpublishDate),
            status: job.status || 'draft',
        };
    },
    fromFirestore(snapshot) {
        const data = snapshot.data();
        return {
            ...data,
            updated: fromFirestoreDateConverter(data.updated),
            created: fromFirestoreDateConverter(data.created),
            publishDate: fromFirestoreDateConverter(data.publishDate),
            unpublishDate: fromFirestoreDateConverter(data.unpublishDate),
        }
    },
};

export const companyDataConverter = {
    toFirestore(company) {
        return {
            ...company,
            updated: toFirestoreDateConverter(company.updated),
            created: toFirestoreDateConverter(company.created),
            status: company.status || 'draft',
        };
    },
    fromFirestore(snapshot) {
        const data = snapshot.data();
        return {
            ...data,
            updated: fromFirestoreDateConverter(data.updated),
            created: fromFirestoreDateConverter(data.created),
        }
    },
};

export const adDataConverter = {
    toFirestore(ad) {
        return {
            ...ad,
            updated: toFirestoreDateConverter(ad.updated || new Date().getTime()),
            created: toFirestoreDateConverter(ad.created || new Date().getTime()),
            publishDate: toFirestoreDateConverter(ad.publishDate),
            unpublishDate: toFirestoreDateConverter(ad.unpublishDate),
            position: toNumber(ad.position),
            status: ad.status || 'draft',
        };
    },
    fromFirestore(snapshot) {
        const data = snapshot.data();
        return {
            ...data,
            updated: fromFirestoreDateConverter(data.updated),
            created: fromFirestoreDateConverter(data.created),
            publishDate: fromFirestoreDateConverter(data.publishDate),
            unpublishDate: fromFirestoreDateConverter(data.unpublishDate),
        }
    },
};

export const userAccountDataConverter = {
    toFirestore(user) {
        const appliedJobs = (user.appliedJobs || []).map((appliedJob) => {
            return {
                ...appliedJob,
                appliedForDate: toFirestoreDateConverter(appliedJob.appliedForDate),
            };
        });

        return {
            ...user,
            appliedJobs,
            updated: toFirestoreDateConverter(user.updated),
            created: toFirestoreDateConverter(user.created),
            status: user.status || 'draft',
        };
    },
    fromFirestore(snapshot) {
        const user = snapshot.data();
        const appliedJobs = (user.appliedJobs || []).map((appliedJob) => {
            return {
                ...appliedJob,
                appliedForDate: fromFirestoreDateConverter(appliedJob.appliedForDate),
            };
        });
        return {
            ...user,
            appliedJobs,
            updated: fromFirestoreDateConverter(user.updated),
            created: fromFirestoreDateConverter(user.created),
        };
    },
};

export const cvDataConverter = {
    toFirestore(cv) {
        const experiences = (cv.experiences || []).map(({ started, ended, ...experience }) => {
            return {
                ...experience,
                started: toFirestoreDateConverter(started),
                ended: toFirestoreDateConverter(ended),
            };
        });
        const educations = (cv.educations || []).map(({ started, ended, ...education }) => {
            return {
                ...education,
                started: toFirestoreDateConverter(started),
                ended: toFirestoreDateConverter(ended),
            };
        });

        return {
            ...cv,
            experiences,
            educations,
            updated: toFirestoreDateConverter(cv.updated || new Date().getTime()),
            created: toFirestoreDateConverter(cv.created || new Date().getTime()),
            status: cv.status || 'draft',
        };
    },
    fromFirestore(snapshot) {
        const cv = snapshot.data();
        const experiences = (cv.experiences || []).map(({started, ended, ...experience}) => {
            return {
                ...experience,
                started: fromFirestoreDateConverter(started),
                ended: fromFirestoreDateConverter(ended),
            };
        });
        const educations = (cv.educations || []).map(({started, ended, ...education}) => {
            return {
                ...education,
                started: fromFirestoreDateConverter(started),
                ended: fromFirestoreDateConverter(ended),
            };
        });
        return {
            ...cv,
            experiences,
            educations,
            updated: fromFirestoreDateConverter(cv.updated),
            created: fromFirestoreDateConverter(cv.created),
        };
    },
};