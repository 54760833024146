// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {
    GoogleAuthProvider,
    getAuth,
    // FacebookAuthProvider,
    signInWithPopup,
} from "firebase/auth";
import { Button, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "../../assets/svg/google.svg";
// import FacebookIcon from "../../assets/svg/facebook-f.svg";
import { logout } from "../../utils/authentication";
// import LinkedInIcon from "../../assets/svg/linkedin-in.svg";
// import { collection, doc } from "firebase/firestore";
// import firebase from "../../utils/firebase";

// const uiConfig = {
//     // Redirect to / after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
//     signInSuccessUrl: '/',
//     // We will display GitHub as auth providers.
//     signInOptions: [
//         firebase.auth.EmailAuthProvider.PROVIDER_ID,
//         firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//         firebase.auth.FacebookAuthProvider.PROVIDER_ID,
//         // firebase.auth.GithubAuthProvider.PROVIDER_ID,
//     ],
// };

const useStyles = makeStyles((theme) => ({
    button: {
        background: "#F8F8F8",
        borderRadius: 30,
        marginBottom: 30,
        height: "55px!important",
        width: "100%",
        color: "#262626",
        borderColor: "#808080",
        fontFamily: "Roboto",
        "&:hover": {
            backgroundColor: "#F26419",
            color: "white",
        },
    },
    img: {
        width: "20px",
        height: "20px",
        marginRight: "15px",
    },
}));

export default function FireBaseUILogin({ register, isModal }) {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleGoogleLogin = async () => {
        const provider = new GoogleAuthProvider();

        signInWithPopup(getAuth(), provider)
            .then((result) => {
                console.log('Sign in/Register with Google')
                const user = result.user;

                // Reload user data until custom claims are updated
                let attempts = 0;
                const maxAttempts = 5; // Set a limit to avoid potential infinite loops

                const reloadUserData = async () => {
                    console.log('reloading user data');
                    try {
                        await user.reload();
                        const tokenResult = await user.getIdTokenResult(true);

                        // Check if custom claims are updated
                        if (tokenResult?.claims?.isJobseeker) {
                            console.log('Custom claims updated');
                            window.location.href = '/';
                        } else {
                            // Retry after a delay
                            attempts++;
                            console.error(`Custom claims not updated, attemps: ${attempts}`);
                            if (attempts < maxAttempts) {
                                setTimeout(() => {
                                    reloadUserData();
                                }, 750);
                            } else {
                                console.error('Maximum retry attempts reached. Custom claims not updated. Log out');
                                logout();
                            }
                        }
                    } catch (error) {
                        console.error('Error reloading user data:', error);
                    }
                };
                user.getIdTokenResult()
                    .then((tokenResult) => {
                        if (!tokenResult?.claims || !tokenResult?.claims?.isJobseeker) {
                            reloadUserData();
                        }
                    });
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                console.log("errr", error);
            });
    };
    // const handleFacebookLogin = async () => {
    //     const provider = new FacebookAuthProvider();
    //     try {
    //         signInWithPopup(getAuth(), provider)
    //             .then((result) => {
    //                 console.log('Sign in/Register with Facebook')
    //                 const user = result.user;

    //                 // Reload user data until custom claims are updated
    //                 let attempts = 0;
    //                 const maxAttempts = 5; // Set a limit to avoid potential infinite loops

    //                 const reloadUserData = async () => {
    //                     console.log('reloading user data');
    //                     try {
    //                         await user.reload();
    //                         const tokenResult = await user.getIdTokenResult(true);

    //                         // Check if custom claims are updated
    //                         if (tokenResult?.claims?.isJobseeker) {
    //                             console.log('Custom claims updated');
    //                             window.location.href = '/';
    //                         } else {
    //                             // Retry after a delay
    //                             attempts++;
    //                             console.error(`Custom claims not updated, attemps: ${attempts}`);
    //                             if (attempts < maxAttempts) {
    //                                 setTimeout(() => {
    //                                     reloadUserData();
    //                                 }, 750);
    //                             } else {
    //                                 console.error('Maximum retry attempts reached. Custom claims not updated. Log out');
    //                                 logout();
    //                             }
    //                         }
    //                     } catch (error) {
    //                         console.error('Error reloading user data:', error);
    //                     }
    //                 };
    //             })
    //             .catch((error) => {
    //                 // Handle Errors here.
    //                 const errorCode = error.code;
    //                 const errorMessage = error.message;
    //                 // The email of the user's account used.
    //                 const email = error.customData.email;
    //                 // The AuthCredential type that was used.
    //                 const credential = FacebookAuthProvider.credentialFromError(error);

    //                 // ...
    //             });

    //         //   const { uid, email } = result.user;
    //         //   // Check if user exists in Firestore
    //         //   const userRef = db.collection("users").doc(uid);
    //         //   const doc = await userRef.get();
    //         //   if (!doc.exists) {
    //         //     // Create new user document in Firestore
    //         //     await userRef.set({
    //         //       email,
    //         //     });
    //         //   }
    //         //   // Redirect to home page
    //         //   Router.push("/");
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // const handleLinkedInLogin = async () => {
    // const provider = new firebase.auth.OAuthProvider("linkedin.com");
    // const { user } = await firebase.auth().signInWithPopup(provider);

    // const { uid, displayName, photoURL, email } = user;
    // const userData = { displayName, photoURL, email };

    // const db = firebase.firestore();
    // await db.collection('users').doc(uid).set(userData, { merge: true });
    // };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {isModal &&
                (!register ? (
                    <>
                        <h1>Login</h1>
                        <p>Please sign-in:</p>
                    </>
                ) : (
                    <>
                        <h1>Register</h1>
                        <p>Please sign-up:</p>
                    </>
                ))}

            {/* <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} /> */}
            <Button className={classes.button} onClick={handleGoogleLogin}>
                <img className={classes.img} src={GoogleIcon} alt="google icon" />
                Login with Google
            </Button>
            {/* 
            <Button className={classes.button} onClick={handleFacebookLogin}>
                <img className={classes.img} src={FacebookIcon} alt="facebook icon" />
                Login with Facebook
            </Button> 
            */}
            {/* 
            <Button className={classes.button} onClick={handleLinkedInLogin}>
                <img className={classes.img} src={LinkedInIcon} alt="facebook icon" />
                Login with LinkedIn
            </Button> 
        */}
        </div>
    );
}
