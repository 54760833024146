import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Autosuggest from "react-autosuggest";
import { escapeRegexCharacters } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    "& input": {
      width: "100%",
      height: "55px",
    },
    "& .react-autosuggest__container": {
      position: "relative",
    },
    "& .react-autosuggest__input": {
      height: 55,
      padding: "0 15px",
      border: "2px solid #808080",
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderRadius: "40px",
      boxShadow: "none",
      fontSize: "14px",
      boxSizing: "border-box",
      fontFamily: "itc-avant-garde-gothic-pro",
      fontWeight: "400",
      "&:hover": {
        borderColor: "rgba(0, 0, 0, 0.87)",
      },
    },
    "& .react-autosuggest__input--focused": {
      outline: "none",
    },

    "& .react-autosuggest__input--open": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    "& .react-autosuggest__suggestions-container": {
      display: "none",
    },

    "& .react-autosuggest__suggestions-container--open": {
      display: "block",
      position: "absolute",
      top: 55,
      width: "100%",
      border: "1px solid #c4c4c4",
      backgroundColor: "#c4c4c4",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: 12,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      zIndex: 2,
    },

    "& .react-autosuggest__suggestions-list": {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },

    "& .react-autosuggest__suggestion": {
      cursor: "pointer",
      padding: "10px 20px",
    },

    "& .react-autosuggest__suggestion--highlighted": {
      backgroundColor: "#ddd",
    },
  },
}));

const AddNewItem = (value) => <span>+ Add {value}</span>;

const AutoSugguestInputField = ({ options = [], onChange, placeholder }) => {
  const classes = useStyles();

  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const onInputChange = (e, { newValue }) => setValue(newValue);
  const getSuggestionValue = ({ isNew, label }) => (isNew ? value : label);
  const renderSuggestion = ({ isNew, label }) =>
    isNew ? AddNewItem(value) : label;
  const onSuggestionsFetchRequested = ({ value }) =>
    setSuggestions(getSuggestions(value));
  const onSuggestionsClearRequested = () => setSuggestions([]);
  const onSuggestionSelected = (e, { suggestion }) =>
    onChange({ value, ...suggestion });

  const getSuggestions = (value) => {
    const escapedValue = escapeRegexCharacters(value.trim());
    const regex = new RegExp("^" + escapedValue, "i");
    const suggestions = options.filter(({ label }) => regex.test(label));
    return suggestions.length === 0
      ? [{ isNew: true }]
      : suggestions.slice(0, 10);
  };

  const inputProps = {
    placeholder: `${placeholder}...`,
    value,
    onChange: onInputChange,
  };
  return (
    <div className={classes.inputContainer}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
      />
    </div>
  );
};
export default AutoSugguestInputField;
