import {
    useState,
    useEffect,
    useContext,
    useRef,
    useLayoutEffect,
} from "react";
import { makeStyles, Grid, withWidth } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";

import Heading from "../base-components/typography/heading";
import BaseButton from "../base-components/base-button/new-button";
import Preloader from "../preloader";
import { Context } from "../../utils/context-provider";
import { updateUser } from "../../utils/server-functions";
import Security from "./security";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        height: "100%",
    },
    mainPadding: {
        padding: "120px 60px 60px 60px",
        [theme.breakpoints.down("xl")]: {},
        [theme.breakpoints.down("lg")]: {},
        [theme.breakpoints.down("md")]: {
            padding: "90px 30px 60px 30px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "0",
        },
        [theme.breakpoints.down("xs")]: {},
    },
    mainContainerResponsive: {
        [theme.breakpoints.down("sm")]: {
            padding: "120px 15px 0 15px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "120px 15px 0 15px",
        },
    },
    container: {
        paddingRight: 30,
        flexDirection: "column",
    },
    floatingBox: {
        backgroundColor: "#FFFFFF",
        padding: 30,
        boxShadow: "-7px 7px 20px #00000029",
        width: "100%",
        position: "sticky",
        top: 110,
        height: 375,
        [theme.breakpoints.down("lg")]: {
            height: 425,
        },
    },
    buttons: {
        width: "100%",
    },
    imageContainer: {
        position: "relative",
        marginBottom: 30,
        "&:hover": {
            "& svg": {
                display: "block",
            },
        },
    },
    profileImage: {
        width: "170px",
        height: "170px",
        display: "block",
        borderRadius: "50%",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
    },
    removeImage: {
        position: "absolute",
        top: "0",
        left: "150px",
        cursor: "pointer",
        color: "#990000",
    },
    relative: {
        position: "relative",
    },
    avatar: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
    editIcon: {
        cursor: "pointer",
        position: "absolute",
        top: 12,
        left: 120,
        fontSize: 16,
        color: "#059c75",
    },
    popover: {
        pointerEvents: "none",
    },
    paper: {
        padding: "5px 8px",
        fontSize: 10,
        marginLeft: 5,
        textTransform: "uppercase",
        fontWeight: "bold",
    },
    mb: {
        marginBottom: 30,
    },
    section: {
        marginTop: "30px",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        border: "1px solid rgba(0,0,0,0.1)",
        padding: "30px",
        [theme.breakpoints.down("sm")]: {
            marginTop: 0,
            marginBottom: 30,
        },
        [theme.breakpoints.down("xs")]: {
            padding: 15,
            marginBottom: 15,
        },
    },
    animateIn: {
        position: "fixed",
        bottom: 15,
        right: 15,
        backgroundColor: "#F26419",
        width: 40,
        height: 40,
        borderRadius: "50%",
        boxShadow: "1px 1px 2px rgba(0,0,0,0.3)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        "&:hover": {
            boxShadow: "2px 2px 3px rgba(0,0,0,0.4)",
            backgroundColor: "#c95a1e",
        },
    },
    icon: {
        color: "#FFF",
    },
    listSticky: {
        margin: 0,
        padding: 0,
        listStyle: "none",
        position: "fixed",
        bottom: 70,
        right: 21,
        "& > li ": {
            marginTop: 15,
        },
        "& > li > div": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            textDecoration: "none",
            color: theme.palette.secondary.dark,
            cursor: "pointer",
            "& > span": {
                fontSize: "11px",
            },
            "&:hover": {
                "& > div": {
                    boxShadow: "2px 2px 3px rgba(0,0,0,0.4)",
                    backgroundColor: "#262626",
                },
            },
        },
    },
    iconDiv: {
        backgroundColor: "#F26419",
        width: 30,
        height: 30,
        borderRadius: "50%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: 15,
        transition: "all 0.2s linear",
        "& > svg": {
            fontSize: "1.1rem !important",
        },
    },
    hideForSmallScreen: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
            visibility: "hidden",
        },
    },
}));

export default withWidth()(function UpdateSettings({ width }) {
    const classes = useStyles();
    const [scrollY, setScrollY] = useState(0);
    const [refY, setRefY] = useState(0);
    const divRef = useRef(null);
    const [isOpenNav, setIsOpenNav] = useState(false);

    const { user } = useContext(Context);
    const id = user?.id || "";

    const [accountInfo, setAccountInfo] = useState({ ...user });
    const [hasErrors, setHasErrors] = useState(false);
    const [fieldsWithError, setFieldsWithError] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setAccountInfo({ ...user });
    }, [user]);

    useLayoutEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
            setRefY(window.innerHeight);

            if (divRef.current) {
                const divRect = divRef.current?.getBoundingClientRect();

                const yPos = divRect.top + window.scrollY;
                const scrollY = window.scrollY;

                if (scrollY >= yPos) {
                    setRefY(yPos);
                }

                if (scrollY === 0) {
                    setIsOpenNav(false);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [divRef.current]);

    const showStickyNav = () => {
        setIsOpenNav(!isOpenNav);
    };

    const setFieldError = (id, hasError) => {
        console.log("id", id, hasError);
        let check = { ...fieldsWithError };
        if (hasError) {
            check[id] = true;
        } else {
            delete check[id];
        }
        setFieldsWithError(check);
    };

    const save = async (event) => {
        let check = { ...fieldsWithError };
        const requiredFields = ["firstName", "lastName", "phone"];

        requiredFields.forEach((requiredField) => {
            if (!accountInfo[requiredField] || check[requiredField]) {
                check[requiredField] = true;
            } else {
                delete check[requiredField];
            }
        });

        if (Object.keys(check).length > 0) {
            setFieldsWithError(check);
        } else {
            setLoading(true);

            try {
                await updateUser({
                    id,
                    ...accountInfo
                });
            } catch (error) {
                console.log(error);
                setHasErrors(true);
            }
            setLoading(false);
        }
        event.preventDefault();
    };

    if (loading) {
        return <Preloader />;
    }
    // if (error) {
    //     return <p>Something went wrong, please try again later</p>
    // }

    const labelText = "Update";
    const labelTextSaving = "Updating...";

    // accountInfo.photoURL = undefined;

    return (
        <>
            <Grid
                container
                direction="column"
                className={classes.mainContainer}
                ref={divRef}
            >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className={classes.mainPadding}>
                        <Grid container direction="row" className={classes.mainContainer}>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={8}
                                lg={7}
                                xl={6}
                                className={classes.mainContainerResponsive}
                            >
                                <Grid container direction="column">
                                    <Grid item xs={12} container className={classes.mb}>
                                        <Grid item xs={12} sm={12} md={8} lg={8}>
                                            <Heading color="secondary.dark" variant="h3">
                                                Settings
                                            </Heading>
                                        </Grid>
                                        <Grid
                                            container
                                            xs={6}
                                            sm={6}
                                            md={4}
                                            lg={4}
                                            justifyContent="flex-end"
                                            className={classes.hideForSmallScreen}
                                        >
                                            <BaseButton
                                                label={loading ? labelTextSaving : labelText}
                                                onClick={save}
                                                color="primary"
                                                endIcon={<CloudUpload />}
                                                className={classes.buttons}
                                            />
                                        </Grid>

                                        <Grid
                                            id="security"
                                            item
                                            xs={12}
                                            container
                                            justifyContent="space-between"
                                            className={classes.section}
                                        >
                                            <Grid container spacing={2} direction="row">
                                                <Grid item sm={12} md={12} lg={12}>
                                                    <Heading color="secondary.dark" variant="h6">
                                                        Security
                                                    </Heading>
                                                    <Security />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            <div
                className={`${scrollY >= refY ? classes.animateIn : ''}`}
                onClick={showStickyNav}
            >
                <AddIcon className={classes.icon} />
            </div>

            {isOpenNav && (
                <ul className={classes.listSticky}>
                    <li>
                        <div onClick={save}>
                            <span>{loading ? labelTextSaving : labelText}</span>
                            <div className={classes.iconDiv}>
                                <SaveIcon className={classes.icon} />
                            </div>
                        </div>
                    </li>
                </ul>
            )}
        </>
    );
});
