import {
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import DashboardLayout from '../../layout/dashboard-layout';
// import DashboardRoutes from '../../components/dashboard/dashboard-routes';
import AppliedJobsRoutes from '../../components/applied-jobs/applied-jobs-routes';
import RequireAuth from '../../components/require-auth';
import ProfileRoutes from '../../components/profile/profile-routes';
import SettingsRoutes from '../../components/settings/settings-routes';
import JobCartRoutes from '../../components/job-cart/job-cart-routes';
import { IS_ADMIN, IS_JOBSEEKER } from '../../utils/constants';

export default function Dashboard() {
    return (
        <DashboardLayout>
            <Routes>
                {/* <Route element={<RequireAuth allowedRoles={[IS_ADMIN]} />}>
                    <Route path="admin/*" element={<DashboardRoutes />} />
                </Route> */}
                <Route element={<RequireAuth allowedRoles={[IS_ADMIN, IS_JOBSEEKER]} />}>
                    <Route path="applied-jobs/*" element={<AppliedJobsRoutes />} />
                    <Route path="profile/*" element={<ProfileRoutes />} />
                    <Route path="settings/*" element={<SettingsRoutes />} />
                    <Route path="job-cart/*" element={<JobCartRoutes />} />
                    <Route path="*" element={<Navigate to="/jobs" />} />
                </Route>
            </Routes>
        </DashboardLayout>
    )
}