import { makeStyles, Grid } from "@material-ui/core/";
import Heading from "../base-components/typography/heading";
import Job from "./job";
// import Ad from '../ad';

const useStyles = makeStyles((theme) => ({
    relatedContainer: {
        paddingTop: 60,
        paddingBottom: 60,

        [theme.breakpoints.down("xs")]: {
            paddingTop: 30,
            paddingBottom: 15,
        },
    },
    title: {
        paddingTop: 15,
        paddingBottom: 15,
        [theme.breakpoints.down("xs")]: {
            fontSize:24
        }
    },
    jobItems: {
        paddingTop: 8,
        paddingRight: 8,

        [theme.breakpoints.down("xs")]: {
            height: "calc( 100vw / 2 )",
            width: "calc( 100vw / 2 )",
            "&:nth-child(1n+1)": {
                paddingLeft: 8,
                paddingRight: 8,
            },
            "&:nth-child(even)": {
                paddingLeft: 0,
            },
        },
        [theme.breakpoints.up("sm")]: {
            height: "calc( calc( 100vw - 24px ) / 2 )",
            width: "calc( calc( 100vw - 24px ) / 2 )",
            "&:nth-child(2n+1)": {
                paddingLeft: 8,
            },
        },
        [theme.breakpoints.up("md")]: {
            height: "calc( calc( 100vw - 40px ) / 4 )",
            width: "calc( calc( 100vw - 40px ) / 4 )",
            "&:nth-child(2n+1)": {
                paddingLeft: 0,
            },
            "&:nth-child(4n+1)": {
                paddingLeft: 8,
            },
        },
        [theme.breakpoints.up("lg")]: {
            height: "calc( calc( 100vw - 56px ) / 6 )",
            width: "calc( calc( 100vw - 56px ) / 6 )",
            "&:nth-child(2n+1)": {
                paddingLeft: 0,
            },
            "&:nth-child(4n+1)": {
                paddingLeft: 0,
            },
            "&:nth-child(6n+1)": {
                paddingLeft: 8,
            },
        },
    },
}));

export default function RelatedJobs({ jobs, service, id }) {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.relatedContainer}
        >
            <Grid item xs={12}>
                <Heading color="main.primary" variant="h1" className={classes.title}>
                You May Also Like
                </Heading>
            </Grid>
            <Grid container item xs={12}>
                {jobs?.length > 0 &&
                    jobs.map(({ document }) => (
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            md={3}
                            lg={2}
                            className={classes.jobItems}
                            key={document.id}
                        >
                            <Job {...document} />
                        </Grid>
                    ))}
            </Grid>
        </Grid>
    );
}
