import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import firebase from "../../utils/firebase";

import Logo from "../../assets/svg/logo_dark.svg";
import Login from "../../components/authentication/login";
import LoginSocials from "../../screens/login-password-reset/login-socials";
import RegisterForm from "./register-form";
import CenterText from "./center-text";
import { useContext } from "react";
import { Context } from "../../utils/context-provider";
import { useNavigate } from "react-router-dom";
import { getAuth, fetchSignInMethodsForEmail } from "firebase/auth";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { getBGColorForService } from "../../utils";
import ForgotPassword from "./password-reset/forgot-password";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

const useStyles = makeStyles((theme) => ({
    root: (isModal) => ({
        height: "100vh",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    }),
    tabContainer: {
        margin: "20px 0",
    },
    formContainer: {
        width: "80%",
        maxWidth: "900px",
        marginTop: 20,
        justifyContent: "center",

        [theme.breakpoints.down("md")]: {
            width: "100%",
            maxWidth: "100%",
        },
    },
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
    },
    formControl: {
        margin: "10px 0",
    },
    rememberMe: {
        margin: "10px 0",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    customIndicator: {
        backgroundColor: "#059c75",
        width: "100%",
    },
    logo: {
        display: "block",
        height: "45px",
        maxWidth: "auto",
        padding: 0,
        margin: "0 auto",
    },
    lineItem: {
        width: "100%",
        background: "transparent",
    },
    tabPanel: {
        alignItems: "center",
    },
    tab: {
        "&.MuiTab-root": {
            minWidth: "50%",
        },
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
    },
    submitButton: {
        margin: theme.spacing(10),
        border: `1px solid ${getBGColorForService("orange")}`,
        background: getBGColorForService("orange"),
        color: "white",
        width: "150px",
        borderRadius: "30px",
        "&:hover": {
            background: "#773512",
        },
    },
    titles: {
        fontSize: 13,
        lineHeight: "24px",
        textAlign: "center"
    },
    wrapperForgotPass: {
        maxWidth: 310
    },
    link: {
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: 14,
        textDecoration: "none",
        color: "black",
        width: "50%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        cursor: "pointer",
        "& svg": {
            marginRight: 8
        },
        marginBottom: 15,
    },
    text: {
        textAlign: "center"
    }
}));

const LoginModal = ({ isModal, onClose, setIdModal }) => {
    const classes = useStyles(isModal);

    const [userExist, setUserExist] = useState(false);
    const [currentScreen, setCurrentScreen] = useState("login");
    const { user, loadingAuthState } = useContext(Context);
    const navigate = useNavigate();
    //  const location = useLocation();
    //   const { from, search } = location.state || { from: '/', search: '' };
    //   console.log("search",search);

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        password: "",
    });

    if (loadingAuthState) {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        );
    }

    const checkUserExists = async (email) => {
        const auth = getAuth();

        try {
            const signInMethods = await fetchSignInMethodsForEmail(auth, email);
            return signInMethods.length > 0; // User exists if any sign-in methods are associated with the email
        } catch (error) {
            console.error("Error checking user existence:", error);
            throw error;
        }
    };

    const onContinue = async (emailInput) => {
        try {
            setFormData({ ...formData, email: emailInput });
            const userExists = await checkUserExists(emailInput);

            if (userExists) {
                // User exists in the database
                setUserExist(true);
            } else {
                // User does not exist in the database
                setUserExist(false);
            }

            setCurrentScreen("register"); // Navigate to screenId 1 (register screen)
        } catch (error) {
            console.error("Error checking user existence:", error);
        }
    };

    const handleLogin = async () => {
        if (!loading) {
            await firebase
                .auth()
                .signInWithEmailAndPassword(formData.email, formData.password);
        }
    };

    const loginRender = () => {
        return (
            <Grid container className={classes.formContainer}>
                <Typography variant="h6" className={classes.titles}>
                    Create an account or login
                </Typography>
                <Grid item xs={12}>
                    <LoginSocials isModal={isModal} />
                    <Grid item xs={12} classes={classes.lineItem}>
                        <CenterText text={"Or better yet"} />
                    </Grid>
                    <Login
                        isModal={isModal}
                        onContinue={onContinue}
                        setCurrentScreen={setCurrentScreen}
                    />
                </Grid>
            </Grid>
        );
    };

    const registerRender = () => {
        return (
            <Grid container className={classes.formContainer}>
                <Grid item xs={12}>
                    <RegisterForm
                        isModal={isModal}
                        email={formData.email}
                        userExist={userExist}
                        setCurrentScreen={setCurrentScreen}
                        setIdModal={setIdModal}
                        onClose={onClose}
                        loading={loading}
                        setLoading={setLoading}
                        formData={formData}
                        setFormData={setFormData}
                    />
                </Grid>
            </Grid>
        );
    };
    const registerValidation = () => {
        return (
            <Grid container className={classes.formContainer}>
                <Grid item xs={12} className={classes.text}>
                    Thank you for registering! We're excited to have you on board. We've
                    sent you a verification link that will allow you to confirm your
                    account please check your email.
                </Grid>
                <div className={classes.buttonWrapper}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.submitButton}
                        onClick={() => handleLogin()}
                        disabled={loading}
                    >
                        {loading ? (
                            <CircularProgress style={{ width: "25px", height: "25px" }} />
                        ) : (
                            "Continue"
                        )}
                    </Button>
                </div>
            </Grid>
        );
    };

    const forgotPassword = () => {
        return (
            <Grid container className={classes.formContainer}>
                <Grid item xs={12} className={classes.wrapperForgotPass}>
                    <div className={classes.link} onClick={() => navigate("/")}>
                        <KeyboardBackspaceIcon /> Back to login
                    </div>
                    <Typography variant="h6" className={classes.titles}>
                        Forgotten your password? Just enter your email below and we'll send
                        you a secure link to reset it.
                    </Typography>
                </Grid>

                <ForgotPassword />
            </Grid>
        );
    };

    return (
        <div className={classes.root}>
            <img src={Logo} alt="mi kier traha logo" className={classes.logo} />
            {currentScreen === "login" && loginRender({ setCurrentScreen })}
            {currentScreen === "register" && registerRender({ setCurrentScreen })}
            {currentScreen === "registerValidation" && registerValidation()}
            {currentScreen === "forgot-password" && forgotPassword()}
        </div>
    );
};

export default LoginModal;
