import { useState, useEffect } from 'react';

import firebase from 'firebase/compat/app';
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/functions";

const firebaseConfig = {
    apiKey: process?.env?.REACT_APP_API_KEY,
    authDomain: process?.env?.REACT_APP_AUTH_DOMAIN,
    databaseURL: process?.env?.REACT_APP_DATABASE_URL,
    projectId: process?.env?.REACT_APP_PROJECT_ID,
    storageBucket: process?.env?.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process?.env?.REACT_APP_MESSAGING_SENDER_ID,
    appId: process?.env?.REACT_APP_ID,
    measurementId: process?.env?.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
if (window.location.hostname === "localhost") {
    firebase.functions().useEmulator("localhost", 5001);
    firebase.firestore().useEmulator("localhost", 8080);
    firebase.storage().useEmulator("localhost", 9199);
    firebase.auth().useEmulator("http://localhost:9099");
}
export default firebase;

export const useCollectionData = (collectionName, documentConverter = null, optionalQuery, excludeDocRef = true) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        let unsubscribe = () => { };

        const load = () => {
            setLoading(true);
            let ref = firebase.firestore().collection(collectionName);
            ref = documentConverter ? ref.withConverter(documentConverter) : ref;
            ref = optionalQuery ? optionalQuery(ref) : ref;
            unsubscribe = ref.onSnapshot(querySnap => {
                const allData = querySnap.docs.map(
                    doc => ({ id: doc.id, ...doc.data(), ...(!excludeDocRef) && { doc } })
                );
                setData(allData);
                setLoading(false);
            }, error => {
                console.error(error);
                setError(error);
                setLoading(false);
            });
        };
        load();
        return () => unsubscribe();
    }, [collectionName, documentConverter, optionalQuery]);

    return [data, loading, error];
};

export const queryDocumentData = async (ref) => {
    try {
        const doc = await ref.get();
        return doc.exists ? { id: doc.id, ...doc.data() } : { id: doc.id };
    }
    catch (error) {
        throw error;
    };
}

export const useDocumentData = (collectionName, docID, documentConverter = null, initialDataProps) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState({});

    useEffect(() => {
        let unsubscribe = () => { };
        const load = async () => {
            setLoading(true);
            const collectioRef = firebase.firestore().collection(collectionName);
            let ref = docID ? collectioRef.doc(docID) : collectioRef.doc();
            ref = documentConverter ? ref.withConverter(documentConverter) : ref;
            unsubscribe = ref.onSnapshot((doc) => {
                const docData = doc.exists ? { id: doc.id, ...doc.data() } : { ...initialDataProps, id: doc.id };
                setData(docData);
                setLoading(false);
                setError(null);
            }, error => {
                console.error(error);
                setError(error);
                setLoading(false);
            });
        }
        load();
        return () => unsubscribe();
    }, [collectionName, docID, documentConverter, initialDataProps]);

    const onChange = ({ target }) => {
        setData({ ...data, [target.id]: target.value });
    }

    return [data, loading, error, onChange];
};