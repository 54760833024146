import {
    FormControl,
    makeStyles,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
// import ForkLiftIcon from '../../assets/icons/forklift.svg';
// import HourGlassIcon from '../../assets/icons/hourglass.svg';
// import SnowFlakeIcon from '../../assets/icons/snowflake.svg';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginRight: 30,
        marginBottom: '2px',
    },
    formGroup: {
        alignItems: 'center'
    },
    formLabel: {
        color: theme.palette.secondary.main,
        marginRight: 7,
        marginLeft: 0,
        '& .MuiTypography-body1': {
            marginBottom: 0,
            lineHeight: '14px',
        },
    },
    customIcon: {
        width: 25,
        height: 25,
    },
    checkbox: {
        padding: 0
    }
}));

const Icons = {
};

export default function CheckboxField({
    id,
    label,
    name,
    value,
    onChange,
    icon,
}) {
    const classes = useStyles();
    const Icon = Icons[icon] ? Icons[icon] : Icons[''];

    const handleChange = ({ target }) => {
        onChange({ target: { id, value: target.checked } });
    };

    return (
        <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup row className={classes.formGroup}>
                <FormControlLabel
                    label={label}
                    className={classes.formLabel}
                    control={
                        <Checkbox
                            className={classes.checkbox}
                            id={id}
                            name={name}
                            checked={value}
                            onChange={handleChange}
                        />
                    }
                />
                {icon && <img className={classes.customIcon} src={Icon} alt={label} />}
            </FormGroup>
        </FormControl>
    );
}