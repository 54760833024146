export const getFormattedDate = (timestamp) => {
  if (!timestamp) {
    return "";
  }
  const date = new Date(timestamp);
  const month = date.toLocaleString("en-en", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

export const getFormattedDateShort = (timestamp, includeTime = false) => {
  if (!timestamp) {
    return "";
  }
  //APR.04.2022
  const date = new Date(timestamp);
  const month = date.toLocaleString("en-en", { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month}.${day < 10 ? `0${day}` : day}.${year}${
    includeTime
      ? ` - ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
      : ""
  }`;
};
