import { Checkbox, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import BaseButton from "../base-components/base-button/new-button";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 99999999,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",

    "&.hidden": {
      display: "none",
      visibility: "hidden",
    },
  },
  modalContainer: {
    backgroundColor: "#fff",
    width: "750px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      height: "calc(50vh - 30px)",
    },

    "& .active": {
      visibility: "visible",
      opacity: "1",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .disabled": {
      visibility: "hidden",
      opacity: "0",
      height: "0",
    },
  },
  modalContent: {
    position: "relative",
    overflow: "auto",
    height: "100%",
    padding: 30,
    display: "flex",
    flexDirection: "column",
    minHeight: 250,
    zIndex: 100,
    [theme.breakpoints.down("xl")]: {
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 15,
      maxHeight: 200,
    },
  },
  close: {
    position: "absolute",
    right: 15,
    top: 15,
    zIndex: 101,
    cursor: "pointer",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  wrapperCheckbox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  },
  checkboxText: {
    margin: 0,
  },
  action:{
    width: "100%",
  }
}));

export default function JobModal({
  onClose,
  children,
  onConfirm,
  notVerified,
  isErrors,
  isDefault,
  favorites,
  handleNoShowModalToggle,
}) {
  const classes = useStyles();

  return (
    <div id="modal" className={classes.modal}>
      <div className={classes.modalContainer}>
        <div className={classes.close} onClick={onClose}>
          Close
        </div>
        <div className={classes.modalContent}>{children}</div>
        <div className={classes.action}>
          <div className={classes.buttonContainer}>
            {isErrors && (
              <BaseButton
                label={"Ok"}
                color="primary"
                className={classes.buttons}
                onClick={onConfirm}
              />
            )}
            {notVerified && (
              <BaseButton
                label={"Confirm"}
                color="primary"
                className={classes.buttons}
                onClick={onConfirm}
              />
            )}
            {isDefault && (
              <>
                <BaseButton
                  label={favorites ? "Yes" : "Confirm"}
                  color="primary"
                  className={classes.buttons}
                  onClick={onConfirm}
                />
                <BaseButton
                  label={"Cancel"}
                  color="primary"
                  className={classes.buttons}
                  onClick={onClose}
                />
              </>
            )}{" "}
          </div>
          {favorites ? (
            <div className={classes.wrapperCheckbox}>
              <Checkbox
                onClick={handleNoShowModalToggle}
                style={{ color: "#2589bd" }}
              ></Checkbox>
              <Typography color={"primary"} className={classes.checkboxText}>
                {"Do not show this message"}
              </Typography>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
