import { getBGColorForService } from "../utils/index";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  blocksContainer: {
    display: "flex",
  },
  smallerBlocks: {
    height: "2px",
    width: "10px",
  },
  block: {
    height: "5px",
    width: "50px",
    animationName: "$blockAnimation",
    animationDuration: "1.5s",
    animationTimingFunction: "ease-in-out",
    animationIterationCount: "infinite",
  },
}));

export default function FiveBlocks({ noAnimation }) {
  const classes = useStyles();

  return (
    <>
      {noAnimation ? (
        <div className={classes.blocksContainer}>
          <div
            className={classes.smallerBlocks}
            style={{ backgroundColor: getBGColorForService("health") }}
          />
          <div
            className={classes.smallerBlocks}
            style={{ backgroundColor: getBGColorForService("professional") }}
          />
          <div
            className={classes.smallerBlocks}
            style={{ backgroundColor: getBGColorForService("engineering") }}
          />
          <div
            className={classes.smallerBlocks}
            style={{ backgroundColor: getBGColorForService("hospitality") }}
          />
          <div
            className={classes.smallerBlocks}
            style={{ backgroundColor: getBGColorForService("public") }}
          />
        </div>
      ) : (
        <div className={classes.blocksContainer}>
          <div
            className={classes.block}
            style={{ backgroundColor: getBGColorForService("health") }}
          />
          <div
            className={classes.block}
            style={{ backgroundColor: getBGColorForService("professional") }}
          />
          <div
            className={classes.block}
            style={{ backgroundColor: getBGColorForService("engineering") }}
          />
          <div
            className={classes.block}
            style={{ backgroundColor: getBGColorForService("hospitality") }}
          />
          <div
            className={classes.block}
            style={{ backgroundColor: getBGColorForService("public") }}
          />
        </div>
      )}
    </>
  );
}
