import { Fragment } from "react";
import { makeStyles, Grid } from "@material-ui/core/";

import Job from "./job";
import Ad from "./ad";

const useStyles = makeStyles((theme) => ({
    main: {
        position: "relative",
        marginTop: 70,
        backgroundColor: theme.palette.secondary.light,
        height: "auto",
        paddingBottom: 60,
        [theme.breakpoints.down("sm")]: {
            marginTop: 0,
        }
    },
    title: {
        paddingTop: 15,
        paddingBottom: 15,

        "&.pt": {
            paddingTop: 40,
        },
    },
    jobItems: {
        paddingTop: 8,
        paddingRight: 8,
        transition: "all 0.2s linear",

        [theme.breakpoints.down("xs")]: {
            height: "calc( 100vw / 2 )",
            width: "calc( 100vw / 2 )",
            "&:nth-child(1n+1)": {
                paddingLeft: 8,
                paddingRight: 8,
            },
            "&:nth-child(even)": {
                paddingLeft: 0,
            },
        },
        [theme.breakpoints.up("sm")]: {
            height: "calc( calc( 100vw - 24px ) / 2 )",
            width: "calc( calc( 100vw - 24px ) / 2 )",
            "&:nth-child(2n+1)": {
                paddingLeft: 8,
            },
        },
        [theme.breakpoints.up("md")]: {
            height: "calc( calc( 100vw - 40px ) / 4 )",
            width: "calc( calc( 100vw - 40px ) / 4 )",
            "&:nth-child(2n+1)": {
                paddingLeft: 0,
            },
            "&:nth-child(4n+1)": {
                paddingLeft: 8,
            },
        },
        [theme.breakpoints.up("lg")]: {
            height: "calc( calc( 100vw - 56px ) / 6 )",
            width: "calc( calc( 100vw - 56px ) / 6 )",
            "&:nth-child(2n+1)": {
                paddingLeft: 0,
            },
            "&:nth-child(4n+1)": {
                paddingLeft: 0,
            },
            "&:nth-child(6n+1)": {
                paddingLeft: 8,
            },
        },
    },
}));

export default function JobGrid(props) {
    const classes = useStyles();
    const { filtered, ads = [] } = props;

    // if (filtered === "all") {
    //     setDisplayData(data.items);
    //     return;
    // }


    return (
        <main role="main" className={classes.main}>
            <Grid
                container
                item
                xs={16}
                id="job-overview"
                className={classes.grid}
                layout
            >
                {filtered?.length > 0 &&
                    filtered.map((job, i) => {
                        const ad = ads.find((ad) => ad.position == i + 1);
                        const service = job.service.toLowerCase();
                        return (
                            <Fragment key={`job-overview-item-${i}`}>
                                {ad && (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={3}
                                        lg={2}
                                        className={classes.jobItems}
                                    >
                                        <Ad {...ad} />
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={6}
                                    sm={6}
                                    md={3}
                                    lg={2}
                                    className={`${classes.jobItems} element-item ${service}`}
                                    key={job.id}
                                    data-category={service}
                                >
                                    <Job {...job} />
                                </Grid>
                            </Fragment>
                        );
                    })}
            </Grid>
        </main>
    );
}
