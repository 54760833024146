import { useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select as BaseSelect,
    makeStyles,
    FormHelperText,
    MenuItem
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: 0,
        fontSize: 12,
    },
    errorText: {
        color: 'red'
    }
}));

export default function SelectField({
    id,
    name,
    label,
    value,
    onChange,
    onError,
    options,
    validation,
    defaultValue,
	disabled=false
}) {
    const classes = useStyles();
    const [error, setError] = useState(false);

    const handleChange = ({ target }) => {
        const { value, name } = target;
        const ids = options.map(({ id }) => id);
        const isError = validation && !ids.includes(value);
        setError(isError);
        onError && onError(isError);
        onChange && onChange({ target: { id: name, value } });
    };

    return (
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <InputLabel id={`label-${id}`}>{label}</InputLabel>
            <BaseSelect
                labelId={`label-${id}`}
                id={id}
                label={label}
                name={name}
                value={value}
                onChange={handleChange}
                defaultValue={defaultValue}
                validation={validation ? 'true' : 'false'}
				disabled={disabled}
            >
                {options?.map(({ id, label, disabled }, i) =>
                    <MenuItem key={`${id}-menu-item-${i}`} value={id} disabled={disabled}>{label}</MenuItem>
                )}
            </BaseSelect>
            {error && <FormHelperText className={classes.errorText}>Must choose an item</FormHelperText>}
        </FormControl>
    );
}