import {
    FormControl,
    InputLabel,
    Select as BaseSelect,
    makeStyles,
    FormHelperText,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: 0,
        fontSize: 12,
    },
    errorText: {
        color: theme.palette.error.main, 
        margin:0, 
    },
    select: {
        appearance: 'none',
        "& fieldset": {
            border:'2px solid #808080',
            borderRadius: '40px',
        },
    },
    options: {
        fontSize: 14,
        fontFamily: 'Arial',
        fontWeight: 'normal',
    },
    label: {
        width: '20%',
        color: props => props.hasError ? theme.palette.error.main : ""
    },
}));

export default function NativeSelectField({
    id,
    name,
    label,
    value,
    onChange,
    hasError = false,
    onError,
    errorMessage,
    required,
    options,
    defaultValue,
    validation
}) {
    const classes = useStyles({ hasError });

    const handleChange = ({ target }) => {
        const { value, name } = target;
        onChange && onChange({ target: { id: name, value } });
    };

    const handleBlur = () => {
        const ids = options.map(({ id }) => id);
        const isError = !value || !ids.includes(value);
        onError && onError(id, isError);
    }

    const astretic = validation === true ? '*' : '';

    return (
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <InputLabel id={`label-${id}`} className={classes.label}>{label} {astretic}</InputLabel>
            <BaseSelect
                native
                labelId={`label-${id}`}
                id={id}
                label={label}
                name={name}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={() => onError && onError(id, false)}
                defaultValue={defaultValue}
                required={required}
                className={classes.select}
            >
                {options?.map(({ id, label, disabled }, i) =>
                    <option key={`${id}-menu-item-${i}`} value={id} disabled={disabled} className={classes.options}>{label}</option>
                )}
            </BaseSelect>
            {hasError && <FormHelperText className={classes.errorText}>{errorMessage}</FormHelperText>}
        </FormControl>
    );
}