import enLocale from "date-fns/locale/en-US";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function DatePickerFieldX({
    id,
    name,
    label,
    value,
    onChange,
    hasError = false,
    onError,
    errorMessage,
    required,
    disabled = false,
    className,
}) {
    const dtValue = value ? new Date(value) : null;

    const handleDateChange = (date) => {
        let dt = date ? new Date(date).getTime() : null;
        onChange && onChange({ target: { id, value: dt } });
    };

    const handleValidation = () => {
        let validateError = (required && value == null);
        onError && onError(id, validateError);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
            <DatePicker 
                id={id}
                name={name}
                label={label}
                value={dtValue}
                onChange={handleDateChange}
                onBlur={handleValidation}
                disabled={disabled}
                format="MMMM dd, yyyy"
                className={className}
                slotProps={{ field: { clearable: true } }}
            />
        </LocalizationProvider>
    );
}