import {
    Routes,
    Route,
} from 'react-router-dom';
import UpdateProfile from './update-profile';
import UpdateCV from './update-cv';

export default function ProfileRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<UpdateProfile />} />
            <Route exact path="/cv" element={<UpdateCV />} />
        </Routes>
    )
}