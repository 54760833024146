import React from 'react';
import {
    Typography
} from '@material-ui/core';

const Heading = ({ variant = "h1", color = "primary", className = "", children }) => {
    return (
        <Typography variant={variant} color={color} className={className}>
            {children}
        </Typography>
    )
}

export default Heading;