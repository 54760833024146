import { makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    paddingTop: 15,
    paddingBottom: 15,
  },
  link: {
    textDecoration: "none",
    color: "#262626",
    textTransform: "uppercase",
    fontSize: 12,
    fontWeight: "bold",
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <footer role="contentinfo" className={classes.footer}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <a
            className={classes.link}
            href="https://www.cr38te.com"
            target="_blank"
          >
            By CR38TE
          </a>
        </Grid>
      </Grid>
    </footer>
  );
}
