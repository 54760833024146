export const getBGColorForService = service => {
    switch (service) {
        case "public": // red => Public Sector Services
            return "#CC2936";
        case "professional": // purple => Administrative and Professional Services
            return "#54457F";
        case "health": // green => Health Services
            return "#059c75";
        case "hospitality": // yellow => Tourism and Hospitality Services
            return "#E9D758";
        case "engineering": // blue => Technology, Technological and Creative Services
            return "#2589bd";
        case "orange": // blue => Technology, Technological and Creative Services
            return "#F26419";
        default: // black fox => Default
            return "#262626";
    }
};

export const getColorForService = service => {
    switch (service) {
        case "public": // red => Public Sector Services
            return "#FFFFFF";
        case "professional": // purple => Administrative and Professional Services
            return "#FFFFFF";
        case "health": // green => Health Services
            return "#FFFFFF";
        case "hospitality": // yellow => Tourism and Hospitality Services
            return "#FFFFFF";
        case "engineering": // blue => Technology, Technological and Creative Services
            return "#FFFFFF";
        default: // black fox => Default
            return "#FFFFFF";
    }
};

export const renderOGImageCanvas = ({
    service,
    // title,
    titleLine1 = '',
    titleLine2 = '',
    companyNameLine1 = '',
    companyNameLine2 = '',
    context = null,
    image = null,
    logo,
    width = 0,
    height = 0,
    titleSize = 0,
    companyTitleSize = 0,
    font = '',
}) => {
    context.clearRect(0, 0, context.canvas.width, context.canvas.height)
    context.fillStyle = getBGColorForService(service);
    context.fillRect(0, 0, width, height);
    context.font = `Bold ${titleSize}px ${font}`;
    context.fillStyle = "#FFF";
    context.textAlign = "center";
    const linePos = height / 2.5;
    let companylinePos = linePos + titleSize + 33;

    if (titleLine2) {
        context.fillText(titleLine1.toUpperCase(), width / 2, linePos - (titleSize / 2) + 33);
        context.fillText(titleLine2.toUpperCase(), width / 2, linePos + (titleSize / 2) + 33);
        companylinePos = linePos + (titleSize + (titleSize / 2) + 33);
    } else {
        context.fillText(titleLine1.toUpperCase(), width / 2, (height / 2.5) + 33);
    }
    context.font = `${companyTitleSize}px ${font}`;
    context.fillText(companyNameLine1, width / 2, companylinePos);
    if(companyNameLine2){
        context.fillText(companyNameLine2, width / 2, companylinePos + companyTitleSize + 3);
    }

    context.drawImage(image, (width / 2) - (logo.width / 2), logo.yPos, logo.width, logo.height);
}

export const urlify = a => a.toLowerCase().replace(/[^a-z0-9]+/g, "-").replace(/^-+|-+$/g, "-").replace(/^-+|-+$/g, '')

export const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
