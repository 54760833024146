import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    linkItem: {
        marginLeft: '15px !important'
    }
}));

export default function Links({ title, onClick }) {
    const classes = useStyles();

    return (
        <Link 
            href="#" 
            onClick={onClick}
            className={classes.linkItem}
        >
            {title}
        </Link>
    );
}