import { useState, useRef, useContext, useEffect } from "react";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  makeStyles,
  Grid,
  Avatar,
  Badge,
  IconButton,
  Hidden,
  useMediaQuery,
  useTheme,
  Tooltip,
  withStyles,
} from "@material-ui/core/";
import { Menu, HighlightOff } from "@material-ui/icons";
import AccountMenu from "./account-menu";
import { Context } from "../../../utils/context-provider";
import Logo from "../../../assets/svg/logo_dark.svg";
import { useNavigate } from "react-router-dom";
import { Notifications } from "@material-ui/icons";
import Filter from "../../../icons/filter";
import { connectSearchBox } from "react-instantsearch-dom";
import TypesenseSearchBox from "../../../utils/search/typesense-search-box-overview";
import ProfileIcon from "../../../assets/svg/profile.svg";
import FavoriteIcon from "../../../assets/svg/favorite.svg";
import FilterIcon from "../../../assets/svg/filter.png";

const SearchBox = connectSearchBox(TypesenseSearchBox);

const useStyles = makeStyles((theme) => ({
    header: {
        boxShadow: "0px 3px 6px #0000000D",
        zIndex: theme.zIndex.drawer + 1,
        height: "60px",
    },
    menuButton: {
        cursor: "pointer",
    },
    logo: {
        display: "block",
        height: "30px",
        maxWidth: "auto",
        padding: 0,
        margin: "0 auto",
    },
    avatar: {
        marginRight: "0",
        cursor: "pointer",
        height: "35px",
        width: "35px",
    },
    favorite: {
        cursor: "pointer",
        height: "28px",
        width: "28px",
    },
    filteredIcon: {
        height: "auto",
        width: "23px",
        marginTop: (props) => (props.isFiltered ? 0 : "10px"),
    },
    notifications: {
        marginRight: 20,
        width: 30,
        height: 30,
        [theme.breakpoints.down("sm")]: {
            marginRight: 15,
        },
    },
    filterButton: {
        marginLeft: 30,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        color: theme.palette.primary.main,
        textDecoration: "none",
        fontSize: 14,
        lineHeight: "28px",
        fontWeight: "bold",
        transition: "all 0.2s linear",
        cursor: "pointer",
        width: "80%",
        [theme.breakpoints.down("sm")]: {
            width: "unset",
            marginLeft: 15,
        },
    },
    searchButton: {
        width: 250,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "end",
            width:'100%',
            marginTop:'60px'
        },
    },
    customBadge: {
        backgroundColor: "#48ff00",
        height: 12,
        width: 12,
        borderRadius: "50%",
    },
    tooltip: {
        position: "relaitve",
    },
}));
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginTop: 0,
    zIndex: 1000000,
    position: "absolute",
  },
}))(Tooltip);

const Header = ({
    className,
    onClick,
    pageCategory,
    onCloseFilterNav,
    onShowFilterNav,
    onSearchClick,
    isOpen,
    isSearchOpen,
    isFiltered,
    singlePage,
    navOpen,
}) => {
    const { user } = useContext(Context);
    const classes = useStyles({ isFiltered });
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const navigate = useNavigate();
    const [numberFavorites, setNumberFavorites] = useState(0);
    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const favoritedJobsLength = (user?.favoritedJobs ?? []).length;
    const selectedJobsLength = (user?.selectedJobs ?? []).length;

    useEffect(() => {
        const totalJobsLength = favoritedJobsLength + selectedJobsLength;
        setNumberFavorites(totalJobsLength);
    }, [user?.favoritedJobs]);

    const handleNotificationClick = () => {
        navigate("/job-cart");
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleNavigation = (link) => {
        setOpen(false);
        navigate(`/${link}`);
    };

    const isJobs = pageCategory === "overview";
    return (
        <>
            <AppBar
                position="fixed"
                role="header"
                color="secondary.light"
                className={className}
            >
                <Toolbar>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid
                            item
                            xs={isJobs ? 3 : 3}
                            sm={isJobs ? 3 : 3}
                            md={4}
                            lg={4}
                            container
                            direction="row"
                            alignItems="center"
                        >
                            {!navOpen ? (
                                <Menu
                                    onClick={onClick}
                                    color="main.primary"
                                    aria-label="open drawer"
                                    className={clsx(classes.menuButton, {
                                        [classes.hide]: navOpen,
                                    })}
                                    fontSize="medium"
                                />
                            ) : (
                                <HighlightOff
                                    onClick={onClick}
                                    color="main.primary"
                                    aria-label="close drawer"
                                    className={clsx(classes.menuButton, {
                                        [classes.hide]: navOpen,
                                    })}
                                    fontSize="medium"
                                />
                            )}
                            {isJobs && (
                                <div className={classes.filterButton}>
                                    {isOpen ? (
                                        <div onClick={() => onCloseFilterNav(true)}>
                                            {isFiltered ? (
                                                <Badge
                                                    variant="dot"
                                                    classes={{ badge: classes.customBadge }}
                                                >
                                                    <Filter />
                                                </Badge>
                                            ) : (
                                                <Filter />
                                            )}
                                        </div>
                                    ) : (
                                        <div onClick={() => onShowFilterNav(false)}>
                                            {isFiltered ? (
                                                <Badge
                                                    variant="dot"
                                                    classes={{ badge: classes.customBadge }}
                                                >
                                                    <img
                                                        src={FilterIcon}
                                                        alt="filter"
                                                        className={classes.filteredIcon}
                                                    />
                                                </Badge>
                                            ) : (
                                                <img
                                                    src={FilterIcon}
                                                    alt="filter"
                                                    className={classes.filteredIcon}
                                                />
                                            )}
                                        </div>
                                    )}
                                    <Hidden mdDown>
                                        {!matches && !singlePage && (
                                            <SearchBox placeholderText="Search jobs" />
                                        )}
                                    </Hidden>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={isJobs ? 6 : 6} sm={isJobs ? 6 : 6} md={4} lg={4}>
                        <a href="/jobs">
                            <img className={classes.logo} src={Logo} alt="Mi Kier Traha" />
                        </a>
                        </Grid>
                        <Grid
                            item
                            xs={isJobs ? 3 : 3}
                            sm={3}
                            md={4}
                            lg={4}
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <IconButton
                                onClick={handleNotificationClick}
                                color="inherit"
                                className={classes.notifications}
                            >
                                <Badge
                                    badgeContent={numberFavorites >= 1 ? numberFavorites : 0}
                                    color="primary"
                                    ripple={false}
                                >
                                    <img
                                        src={FavoriteIcon}
                                        alt="favorite"
                                        className={classes.favorite}
                                    />
                                </Badge>
                            </IconButton>
                            <Avatar
                                className={classes.avatar}
                                alt={user.firstName || ""}
                                src={user.photoURL || ProfileIcon}
                                ref={anchorRef}
                                aria-controls={open ? "menu-list-grow" : undefined}
                                aria-haspopup={true}
                                onClick={handleToggle}
                            />
                            <AccountMenu
                                open={open}
                                setOpen={setOpen}
                                anchorEl={anchorRef.current}
                                onClick={handleClose}
                                fullName={`Howdy, ${user.firstName || ""}`}
                                handleNavigation={handleNavigation}
                            />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Hidden mdUp>
                {matches && !singlePage && isJobs ? (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <div className={classes.searchButton}>
                            <SearchBox
                                placeholderText="Search jobs"
                                onSearchClick={onSearchClick}
                                isSearchOpen={isSearchOpen}
                            />
                        </div>
                    </Grid>
                ) : null}
            </Hidden>
        </>
    );
};
export default Header;
