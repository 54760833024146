import { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core/";
import Header from "./header";
import MiniNavigation from "../dashboard-layout/navigation/drawer";
import clsx from "clsx";
import FilterNav from "./jobs-layout/header/filter-nav";
// import firebase from '../../utils/firebase';

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
    },
    main: {
        flexGrow: 1,
        padding: "0",
    },
    appBar: {
        zIndex: "9999990000",
    },
    overlay: {
        position: "fixed",
        zIndex: "90",
        top: 70,
        width: "100%",
        height: "100%",
        display: "none",
        visibility: "hidden",
        opacity: 0,
        transition: "all 0.085s linear",

        "&.overlay-open": {
            display: "block",
            visibility: "visible",
            opacity: 1,
            backgroundColor: "rgba(255,255,255,0.5)",
            backdropFilter: "blur(2px)",
        },
    },
    containerFilter: {
        backgroundColor: "#FFF",
        position: "fixed",
        zIndex: 99,
        opacity: 0,
        transition: "all 0.085s linear",
        height: "auto",
        padding: "4px 0",
        "&.open": {
            top: 70,
            opacity: 1,
            backgroundColor: "#FFFFFF",
        },
        "&.close": {
            top: (p) => p.height,
        },

        [theme.breakpoints.between("xs", "sm")]: {
            paddingTop: 50,
        },
    },
    filterContainer: {
        flexWrap: "nowrap",
        overflowX: "auto",
        justifyContent: "space-evenly",
        flexDirection: "column",
    },
    iconWrapper: {
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        top: 15,
        right: 35,

        "& :last-child": {
            marginLeft: "5px",
        },
    },
    textClear: {
        "&:hover": {
            fontWeight: "bold",
        }
    }
}));

export default function DashboardLayout({
    mainNavigation,
    aside,
    footer,
    pageCategory,
    onSetSelectedService,
    selectedServices,
    onSetSelectedCountry,
    selectedCountries,
    setSelectedCountries,
    setSelectedServices,
    singlePage,
    children,
}) {
    const classes = useStyles({
        height: `-400px`,
    });
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [isFilterOpen, setFilterOpen] = useState(false);
    const [isSearchOpen, setSearchOpen] = useState(false);

    const onShowFilterNav = () => {
        setFilterOpen(true);
        setSearchOpen(false);

        const navEl = document.getElementById("filter-nav");
        const jobContainer = document.getElementById("job-container");
        navEl.classList.remove("close");
        navEl.classList.add("open");
        jobContainer.classList.add("overlay-open");
    };

    const onCloseFilterNav = () => {
        setFilterOpen(false);

        const navEl = document.getElementById("filter-nav");
        const jobContainer = document.getElementById("job-container");
        navEl.classList.remove("open");
        navEl.classList.add("close");
        jobContainer.classList.remove("overlay-open");
    };

    const services = [
        "health",
        "professional",
        "engineering",
        "hospitality",
        "public",
    ];

    const countries = ["AUA", "BON", "CUR"];

    const clearAll = () => {
        setSelectedServices([]);
        setSelectedCountries([]);
    };

    const selectAll = () => {
        setSelectedServices(services);
        setSelectedCountries(["aua", "bon", "cur"]);
    };

    const onSearchClick = () => {
        setSearchOpen((wasOpened) => !wasOpened);
        setFilterOpen(false);
        onCloseFilterNav();
    };

    return (
        <>
            <Header
                onClick={open === false ? handleDrawerOpen : handleDrawerClose}
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
                pageCategory={pageCategory}
                mainNavigation={mainNavigation}
                onShowFilterNav={onShowFilterNav}
                onCloseFilterNav={onCloseFilterNav}
                onSearchClick={onSearchClick}
                navOpen={open}
                isOpen={isFilterOpen}
                isSearchOpen={isSearchOpen}
                isFiltered={
                    selectedServices?.length > 0 || selectedCountries?.length > 0
                }
                singlePage={singlePage}
            />
            <div className={classes.container}>
                <MiniNavigation open={open} pageCategory={pageCategory} handleDrawerClose={handleDrawerClose} />

                {pageCategory === "overview" && (
                    <Grid
                        id="filter-nav"
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        className={`${classes.containerFilter} close`}
                    >
                        <div className={classes.iconWrapper}>
                            {selectedServices?.length || selectedCountries?.length ? (
                                <div
                                    className={classes.textClear}
                                    onClick={clearAll}
                                    style={{ cursor: "pointer" }}
                                >
                                    Clear all
                                </div>
                            ) : (
                                <div
                                    className={classes.textClear}
                                    onClick={selectAll}
                                    style={{ cursor: "pointer" }}
                                >
                                    Select all
                                </div>
                            )}
                            <div
                                className={classes.textClear}
                                onClick={onCloseFilterNav}
                                style={{ cursor: "pointer", paddingLeft: 10, paddingRight: 5 }}
                            >
                                Close
                            </div>
                        </div>

                        <Grid container item xs={12} className={classes.filterContainer}>
                            <FilterNav
                                onSelected={onSetSelectedCountry}
                                selected={selectedCountries}
                                filters={countries}
                                title={"Country"}
                                isCountry={true}
                            />

                            <FilterNav
                                onSelected={onSetSelectedService}
                                selected={selectedServices}
                                filters={services}
                                title={"Industry"}
                            />
                            {pageCategory === "overview" && (
                                <Grid
                                    id="filter-nav"
                                    container
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    className={`${classes.containerFilter} close`}
                                >
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        className={classes.filterContainer}
                                    >
                                        <FilterNav onSetSelectedService={onSetSelectedService} />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
                <div
                    id="job-container"
                    className={classes.overlay}
                    onClick={onCloseFilterNav}
                />
                <main id="main" role="main" className={classes.main}>
                    {/* <button style={{ margin: '150px' }} onClick={updateDB}>Test</button> */}
                    {children}
                </main>
            </div>
        </>
    );
}
