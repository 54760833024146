import {
    useEffect,
    useState
} from "react";
import {
    LinearProgress,
    makeStyles,
    Typography,
    withStyles,
} from "@material-ui/core";

const pwStrengthColor = {
    "Too Weak": "#ff5722",
    "Weak": "#ffac40",
    "Moderate": "#fdf900",
    "Strong": "#70ff98",
    "Very Strong": "#4caf50",
};

const StyledLinearProgress = withStyles({
    colorPrimary: {
        backgroundColor: "white",
    },
    barColorPrimary: {
        backgroundColor: p => pwStrengthColor[p.strength]
    },
})(LinearProgress);

const useStyles = makeStyles((theme) => ({

    containerProgress: {
     width:"100%",
     marginBottom: theme.spacing(2),

    },
  }));

export default function PasswordStrength({ password }) {
    const classes = useStyles();

    const [passwordStrength, setPasswordStrength] = useState(null);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const pwString = [
            "Too Weak",
            "Too Weak",
            "Weak",
            "Moderate",
            "Strong",
            "Very Strong",
        ];
        let strength = 0;

        if (password.length > 3) {
            strength += 1;
            if (password.length > 7) {
                if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
                    strength += 1;
                }
                if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
                    strength += 1;
                }
                if (password.match(/[\W_]+/)) {
                    strength += 1;
                }
            }
            if (password.length > 10 && strength > 3) {
                strength += 1;
            }
        }

        setPasswordStrength(pwString[strength]);
        setProgress(strength * 20);

    }, [password]);

    return (
        <div className={classes.containerProgress}>
            {password?.length > 0 && <Typography>{passwordStrength}</Typography>}
            <StyledLinearProgress
                variant="determinate"
                value={progress}
                strength={passwordStrength}
            />
        </div>
    );
}