import { makeStyles, Grid } from "@material-ui/core";
import SocialMedia from "./social-media";
import Logo from "../../../../assets/svg/logo_dark.svg"

const useStyles = makeStyles((theme) => ({
  aside: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTop: "1px solid rgba(0,0,0,0.05)",
    borderBottom: "1px solid rgba(0,0,0,0.05)",
    paddingTop: 30,
    paddingBottom: 30,
  },
  logo: {
    display: "block",
    height: "39px",
    width: "200px",
    padding: 0,
    marginBottom: 0,
  },
}));

export default function Aside() {
  const classes = useStyles();
  return (
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <aside role="complementary" className={classes.aside}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <a href="/">
            <img
              className={classes.logo}
              src={Logo}
              alt="Mi Kier Traha"
            />
          </a>
        </Grid>
        <Grid
          container
          xs={12}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <SocialMedia />
          </Grid>
        </Grid>
      </Grid>
    </aside>
  );
}
