import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    line: {
        width: " 100%",
        height: "1px",
        backgroundColor: "rgba(0,0,0,0.1)",
        position: "relative",
        margin: "30px 0 50px 0",
    },
    text: {
        position: "absolute",
        top: "-17px",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "white",
        padding: "0 10px",
        fontSize: 13
    },
}));

export default function CenterText({ text = "text" }) {
    const classes = useStyles();
    return (
        <div className={classes.line}>
            <Typography variant="h6" className={classes.text}>{text}</Typography>
        </div>
    );
}
