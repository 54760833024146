import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(({ theme, props }) => ({
    button: {
        fontFamily:'Roboto',
        fontSize:'14px',
        fontWeight:'bold',
        minWidth: '125px',
        maxWidth: '185px',
        width:'100%',
        boxShadow:'none',
        borderRadius:'22px',
        border:'4px solid #F26419',
        backgroundColor:'#F26419',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#c95a1e',
            color: '#ffffff',
            border: '4px solid #c95a1e !important',
            boxShadow:'none !important',
        },
        '&:disabled': {
            borderColor: '#999999',
            backgroundColor:'#ffffff',
            color: '#999999',
            cursor: 'not-allowed !important',
        }
    },
}));

export default function NewButton({label, service, onClick, disabled, props}) {
    const classes = useStyles({
        service: service?.toLowerCase() || '',
        buttonColor:  '#F26419',
    });

    return (
        <Button
            variant="contained"
            className={classes.button}
            service={service}
            onClick={onClick}
            disabled={disabled === '' ? 'disabled' : ''}
        >
            {label}
        </Button>
    );
}
