import React from 'react';
import {
    makeStyles,
} from '@material-ui/core/';
import {
    EditorState,
    convertFromRaw,
} from 'draft-js';
import {
    EditorContainer,
    Editor,
} from 'draft-js-wysiwyg';

const useStyles = makeStyles((theme) => ({

    dynamicEditor: {
        fontFamily:"Roboto",
        fontSize:"14px",
        '& h2, h3, h4, h5, h6': {
            fontSize: 'clamp( 16px, 18px, 21px )',
            marginTop: '15px',
            marginBottom: '15px',
            fontWeight: 700,
            fontFamily: "ITC Avant Garde Gothic Std Bold",
        },
        '& ul, ol': {
            marginTop: 0,
            marginBottom: '15px',
            // paddingLeft: '15px'
        },

    }
}));

export default function DynamicEditor({
    id,
    readOnly,
    description
}) {
    const classes = useStyles();
    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty()
    );

    const onEditorChange = (editorState) => {
        setEditorState(editorState);

        const data = editorState.getCurrentContent();
        // const content = JSON.stringify(convertToRaw(data));

        // onChange({ target: { id: id, value: content } });
    }

    const editor = React.useRef(null);

    React.useEffect(() => {
        const contentState = convertFromRaw(JSON.parse(description || '{"blocks":[{"text":""}],"entityMap":{}}'));
        let newContentState = EditorState.push(
            editorState,
            contentState,
            "remove-range"
        );
        setEditorState(newContentState);
    }, [description]);

    return (
        <EditorContainer
            editorState={editorState}
            onChange={onEditorChange}
            className={classes.dynamicEditor}
        >
            <Editor
                id={id}
                editorState={editorState}
                ref={editor}
                placeholder={readOnly ? "" : "Enter some text.."}
                readOnly={readOnly}
            />
        </EditorContainer>
    )
}