import firebase from "./firebase";

export async function login(email, password) {
    try {
        const userCredential = await firebase
            .auth()
            .signInWithEmailAndPassword(email, password);
        const token = await userCredential.user.getIdToken(/* forceRefresh */ true);

        return { userCredential, token };
    } catch (error) {
        console.log("Error logging in:", error);
        throw error;
    }
}

export function logout() {
    firebase.auth().signOut();
}
