import {
    useState,
    useEffect,
    useContext
} from "react";
import {
    Box,
    Grid
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import firebase from "../../utils/firebase";
import { logout } from "../../utils/authentication";
import PasswordField from "../base-components/form-fields/password-field";
import PasswordValidationField from "../base-components/form-fields/password-validation-field";
import { EmailAuthProvider } from "firebase/auth";
import { Context } from "../../utils/context-provider";
import PasswordStrength from "../../utils/password-strength";
import NewButton from "../base-components/base-button/new-button";

export default function Security() {
    const [newPassword, setNewPassword] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [hasErrors, setHasErrors] = useState(false);
    const [fieldsWithError, setFieldsWithError] = useState({});
    const [success, setSuccess] = useState("");
    const [validationError, setValidationError] = useState(false);

    const { user } = useContext(Context)

    const [validation, setValidation] = useState({
        uppercase: false,
        lowercase: false,
        digit: false,
        specialCharacter: false,
        minimumEight: false,
    });

    const newUser = firebase.auth().currentUser;
    const submitPassword = (e) => {
        e.preventDefault();
        let check = { ...fieldsWithError };
        const credential = EmailAuthProvider.credential(
            user.email,
            currentPassword
        );

        const requiredFields = ["confirmNewPassword"];
        requiredFields.forEach((requiredField) => {
            if (newPassword !== confirmNewPassword || check[requiredField]) {
                check[requiredField] = true;
            } else {
                delete check[requiredField];
            }
        });
        if (Object.keys(check).length > 0 || validationError) {
            setFieldsWithError(check);
        } else {
            newUser
                .reauthenticateWithCredential(credential)
                .then(() => {
                    newUser
                        .updatePassword(confirmNewPassword)
                        .then(() => {
                            setSuccess("Password has been updated!");
                            setNewPassword("");
                            setConfirmNewPassword("");
                            logout();
                        })
                        .catch((error) => {
                            setHasErrors(error);
                            console.log("error" + error);
                        });
                })
                .catch((error) => {
                    setHasErrors(error);
                    console.error(error);
                });
        }
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSuccess(false);
            setHasErrors(false);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, [success, hasErrors]);

    return (

        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={6}>
                    <form onSubmit={submitPassword}>
                        <PasswordField
                            id="currentPassword"
                            name="currentPassword"
                            type="password"
                            label="Current Password"
                            value={currentPassword}
                            // errorMessage='Password field must match'
                            setHasErrors={setHasErrors}
                            setSuccess={setSuccess}
                            password={currentPassword}
                            setPassword={setCurrentPassword}
                            fieldsWithError={fieldsWithError}
                            setFieldsWithError={setFieldsWithError}
                            showValidation={false}
                            setValidationError={setValidationError}
                            validation={validation}
                            setValidation={setValidation}
                        />
                        <PasswordField
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            label="New password"
                            value={newPassword}
                            errorMessage="Password field must match"
                            setHasErrors={setHasErrors}
                            setSuccess={setSuccess}
                            password={newPassword}
                            setPassword={setNewPassword}
                            fieldsWithError={fieldsWithError}
                            setFieldsWithError={setFieldsWithError}
                            showValidation={true}
                            setValidationError={setValidationError}
                            validation={validation}
                            setValidation={setValidation}
                        />
                        <PasswordField
                            id="confirmNewPassword"
                            name="confirmNewPassword"
                            label="Confirm new password"
                            value={confirmNewPassword}
                            errorMessage="Password field must match"
                            setHasErrors={setHasErrors}
                            setSuccess={setSuccess}
                            success={success}
                            password={confirmNewPassword}
                            setPassword={setConfirmNewPassword}
                            fieldsWithError={fieldsWithError}
                            setFieldsWithError={setFieldsWithError}
                            showValidation={false}
                            setValidationError={setValidationError}
                            validation={validation}
                            setValidation={setValidation}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <NewButton type="submit" disabled={validationError} label="Change password">

                                </NewButton>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                {hasErrors && <Alert severity="error">Wrong Password</Alert>}
                                {success && <Alert severity="success">{success}</Alert>}
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={6}>
                    <PasswordStrength password={newPassword} />
                    <PasswordValidationField validation={validation} />
                </Grid>
                <Box width="100%" />
            </Grid>
        </Grid >
    );
}
