import {
    TextField,
    makeStyles,
    createStyles,
    Popper,
} from '@material-ui/core/';
import Heading from '../typography/heading';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            zIndex: 60,
            "& .MuiPaper-root": {
                margin: 0,
            },
            "& .MuiAutocomplete-listbox": {
                padding: 0,
                "& li": {
                    backgroundColor: theme.palette.secondary.light,
                    borderBottom: '1px solid #ECECEC',
                    "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        "& h6": {
                            color: theme.palette.secondary.light,
                        }
                    },
                },
                "& .MuiAutocomplete-option[data-focus='true'], & .MuiAutocomplete-option[aria-selected='true']": {
                    backgroundColor: theme.palette.primary.main,
                    borderBottom: '1px solid rgba(255,255,255,0.2)',
                    "& h6": {
                        color: theme.palette.secondary.light,
                    }
                },
                "& h6": {
                    fontSize: 12,
                    textTransform: 'none',
                    margin: 0
                }
            }
        }
    })
);

export default function AutoCompleteFieldWithRenderOption({
    id = "auto-complete-field",
    name = "auto-complete-field",
    label = "Auto complete field",
    value,
    onChange,
    options = [],
    multiple = false,
    required,
    hasError,
    onError,
    errorMessage,
    labelRenderer = () => '',
}) {
    const classes = useStyles();

    let selectedItem;
    if (multiple) {
        selectedItem = options?.filter(i => value?.some(v => v?.id === i?.id));
    }
    else {
        selectedItem = options?.find(v => v?.id === value);
    }

    const handleChange = (_, option, reason) => {
        onChange && onChange({ target: { id, value: option?.id || undefined }, reason })
    };

    const handleBlur = () => {
        const ids = options.map(({ id }) => id);
        const isError = !value || !ids.includes(value);
        onError && onError(id, isError);
    }

    const handleOptionSelected = (option, value) => {
        return option?.id === value?.id
    };

    const filterOptions = createFilterOptions({
        stringify: option => labelRenderer(option),
    });

    return (
        <Autocomplete
            id={id}
            name={name}
            value={selectedItem || null}
            options={options || []}
            getOptionLabel={option => labelRenderer(option)}
            autoComplete={true}
            multiple={multiple}
            fullWidth
            onChange={handleChange}
            getOptionSelected={handleOptionSelected}
            renderInput={params =>
                <TextField
                    {...params}
                    label={required ? label : label + " *"} //handle required mark(*) on label 
                    variant="outlined" fullWidth
                    required={required}
                    onFocus={() => onError && onError(id, false)}
                    onBlur={handleBlur}
                    error={hasError}
                    helperText={hasError ? errorMessage : ''}
                    InputLabelProps={{
                        shrink: value ? true : undefined,
                    }}
                    className={classes.input}
                />
            }
            renderOption={prop => < Heading variant="h6" title={labelRenderer(prop)} color="secondary" />}
            PopperComponent={props => < Popper {...props} className={classes.root} ></Popper >}
            filterOptions={filterOptions}
        />
    )
}