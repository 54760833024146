import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      padding: theme.spacing(2),
      height: 300,
      borderRadius: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
  },

  icon: {
    width: 40,
    height: 40,
    position: "absolute",
    top: 25,
    right: 25,
    cursor: "pointer",
  },
}));

export default function ModalSuccess({ onClose, open, title, children }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleClose = () => {
    onClose(false);
    navigate("/login");
  };

  return (
    <Dialog onClose={handleClose} open={open} className={classes.root}>
      {children}
      <CloseIcon
        color="primary"
        className={classes.icon}
        onClick={handleClose}
      />
    </Dialog>
  );
}

ModalSuccess.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string,
};
