import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "../../../components/base-components/form-fields/text-field";
import { IconButton, InputAdornment } from "@material-ui/core";
import { getBGColorForService } from "../../../utils";
import { useNavigate } from "react-router-dom";

import PasswordStrength from "../../../utils/password-strength";
import PasswordValidation from "./validation-password";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },

  textField: {
    fontSize: "30px",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#ffffff",
      color: "white",
      borderRadius: "30px",
    },
  },
  submitButton: {
    margin: theme.spacing(2),
    border: `1px solid ${getBGColorForService("orange")}`,
    background: getBGColorForService("orange"),
    color: "white",
    width: "150px",
    borderRadius: "30px",
    "&:hover": {
      background: "#773512",
    },
  },
  text: {
    fontSize: 26,
    marginBottom: theme.spacing(2),
  },
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "flex-start",
    flexDirection: "column",
    marginTop:20,
  },
}));

const passwordUpperCaseRE = /(?=.*?[A-Z])/; // At least one upper case English letter, (?=.*?[A-Z])
const passwordLowerCaseRE = /(?=.*?[a-z])/; // At least one lower case English letter, (?=.*?[a-z])
const passwordDigitRE = /(?=.*?\d)/; // At least one digit, (?=.*?[0-9])
const passwordSpecialCharRE = /[\W_]+/; // At least one special character, (?=.*?[#?!@$%^&*-])
const passwordMinimumEightRE = /^.{8,}$/; // Minimum eight in length .{8,} (with the anchors

const PasswordSecurity = ({
  userExist,
  formData,
  setFormData,
  setValidationError,
  setFieldsWithError,
  fieldsWithError,
  showValidation,
}) => {
  const classes = useStyles({ userExist });

  const [validation, setValidation] = useState({
    uppercase: false,
    lowercase: false,
    digit: false,
    specialCharacter: false,
    minimumEight: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const setFieldError = (id, hasError) => {
    let check = { ...fieldsWithError };
    if (hasError) {
      check[id] = true;
    } else {
      delete check[id];
    }
    setFieldsWithError(check);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (showValidation) {
      setValidation({
        uppercase: passwordUpperCaseRE.test(formData.password),
        lowercase: passwordLowerCaseRE.test(formData.password),
        digit: passwordDigitRE.test(formData.password),
        specialCharacter: passwordSpecialCharRE.test(formData.password),
        minimumEight: passwordMinimumEightRE.test(formData.password),
      });
    }
  }, [formData.password, showValidation]);

  useEffect(() => {
    if (showValidation) {
      const validationError = () => {
        if (
          validation.digit &&
          validation.lowercase &&
          validation.uppercase &&
          validation.specialCharacter &&
          validation.minimumEight
        ) {
          setValidationError(false);
        } else {
          setValidationError(true);
        }
      };
      validationError();
    }
  }, [
    showValidation,
    setValidationError,
    validation.digit,
    validation.lowercase,
    validation.uppercase,
    validation.specialCharacter,
    validation.minimumEight,
  ]);
  return (
    <>
      <TextField
        id="password"
        name="password"
        label="Password"
        type={showPassword ? "text" : "password"}
        value={formData.password}
        onChange={handleChange}
        className={classes.textField}
        required={true}
        hasError={fieldsWithError["password"]}
        onError={setFieldError}
        errorMessage=""
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleClickShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {!userExist ? (
        <div className={classes.wrapper}>
          <PasswordStrength password={formData.password} />
          <PasswordValidation validation={validation} />
        </div>
      ) : null}
    </>
  );
};

export default PasswordSecurity;
