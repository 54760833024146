import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Context } from "../utils/context-provider";
import Preloader from "../components/preloader/";

export default function PrivateRoute() {
  const { authenticated, loadingAuthState } = useContext(Context);
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const pageState = searchParams.get("lastPage");

  if (loadingAuthState) {
    return (
      <div>
        <Preloader height="100vh" />
      </div>
    );
  }
  return authenticated && pathname !== "/" ? (
    <Outlet />
  ) : (
    <Navigate replace={true}
      to={`/login/${pageState !== null ? `lastPage=${pageState}` : ""}`}
    />
  );
}
