import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

import InfoIcon from "@material-ui/icons/InfoRounded";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
const useStyles = makeStyles((theme) => ({
  alert: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 5,
    alignItems: "center",
    "& p": {
      marginLeft: 15,
      marginBottom: 0,
    },
  },
  formWrapper: {
    marginBottom: theme.spacing(2),

  },
}));
export default function PasswordValidation({validation}) {
  const classes = useStyles();

  return (
    <div className={classes.formWrapper}>
      <div className={classes.alert}>
        <InfoIcon color="primary" />
        <Typography>Use a password you don't use elsewhere</Typography>
      </div>
      <div className={classes.alert}>
        {validation.uppercase ? (
          <CheckIcon style={{ fill: "green" }} />
        ) : (
          <CloseIcon color="error" />
        )}
        <Typography color={validation.uppercase ? "primary" : "error"}>
          Contains at least upper case
        </Typography>
      </div>
      <div className={classes.alert}>
        {validation.lowercase ? (
          <CheckIcon style={{ fill: "green" }} />
        ) : (
          <CloseIcon color="error" />
        )}
        <Typography color={validation.lowercase ? "primary" : "error"}>
          Contains at least lower case
        </Typography>
      </div>

      <div className={classes.alert}>
        {validation.digit ? (
          <CheckIcon style={{ fill: "green" }} />
        ) : (
          <CloseIcon color="error" />
        )}
        <Typography color={validation.digit ? "primary" : "error"}>
          Contains at least one number
        </Typography>
      </div>

      <div className={classes.alert}>
        {validation.specialCharacter ? (
          <CheckIcon style={{ fill: "green" }} />
        ) : (
          <CloseIcon color="error" />
        )}
        <Typography color={validation.specialCharacter ? "primary" : "error"}>
          Contains at least one symbol
        </Typography>
      </div>
      <div className={classes.alert}>
        {validation.minimumEight ? (
          <CheckIcon style={{ fill: "green" }} />
        ) : (
          <CloseIcon color="error" />
        )}
        <Typography color={validation.minimumEight ? "primary" : "error"}>
          More than 8 characters
        </Typography>
      </div>
    </div>
  );
}
