import {
    InstantSearch,
    Stats,
    Configure
} from "react-instantsearch-dom";

export default function TypesenseSearchInterface({
    client,
    indexName,
    hitsPerPage,
    showStats = false,
    searchState,
    setSearchState,
    children
}) {

    return (
        <InstantSearch
            searchClient={client || {}}
            indexName={indexName || 'jobs'}
            searchState={searchState}
            onSearchStateChange={setSearchState}
        >
            {showStats && <Stats />}
            <Configure hitsPerPage={hitsPerPage || 50} />
            {children}
        </InstantSearch>
    )
}