import {
    StylesProvider,
} from '@material-ui/core';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';
import Theme from './theme';
import PrivateRoute from './components/private-route';
import DashboardScreen from './screens/dashboard-screen';
import ContextProvider from './utils/context-provider';
import { Helmet } from "react-helmet";
import LoginPage from './screens/login-password-reset';
import JobsOverview from './components/jobs-overview/overview';
import SingleJob from './components/jobs-overview/single-job';

export default function App() {
    return (
        <StylesProvider injectFirst>
            <ContextProvider>
                <Theme>
                    <Helmet>
                        <title>{process?.env?.REACT_APP_TITLE || ''}</title>
                    </Helmet>
                    <Router>
                        <Routes>
                            <Route path="/" element={<PrivateRoute />}>
                                <Route path="/*" element={<DashboardScreen />} />
                                <Route path="/jobs/*" element={<JobsOverview />} />
                                <Route path="/job/:jobId" element={<SingleJob/>}/>
                            </Route>
                            <Route path="/login" element={<LoginPage />} />
                        </Routes>
                    </Router>
                </Theme>
            </ContextProvider>
        </StylesProvider>
    );
}