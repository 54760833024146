import { useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { Context } from "../utils/context-provider";
import { useDocumentData } from "../utils/firebase";
import Preloader from "./preloader";

const RequireAuth = ({ allowedRoles }) => {
    const location = useLocation();
    const {
        user,
        loadingUserData,
        loadingAuthState,
    } = useContext(Context);

    if (loadingUserData || loadingAuthState) {
        return (<Preloader />)
    }

    const authenticated = !!user && user?.claims && allowedRoles.some(role => user.claims[role]);

    return (
        authenticated
            ? <Outlet />
            : <Navigate to="/404" state={{ from: location }} replace />
    );
}


export default RequireAuth;