import {
    useEffect,
    useState,
    createContext,
} from "react";
import Cookies from "universal-cookie";
import {
    SKILLS_COLLECTION,
    SYSTEM_COLLECTION,
    USERS_COLLECTION,
} from "./constants";
import firebase, {
    useCollectionData,
    useDocumentData
} from "./firebase";
import {
    userAccountDataConverter
} from "./data-converters";

export const Context = createContext({});

export default function ContextProvider({ children }) {
    const [loadingAuthState, setLoadingAuthState] = useState(true);
    const [authUserData, setAuthUserData] = useState(null);
    const [configurationData, loadingConfigurationData, configurationDataError] =
        useDocumentData(SYSTEM_COLLECTION, "configuration");
    const [skillsData] = useCollectionData(SKILLS_COLLECTION);
    const [userData, loadingUserData] = useDocumentData(USERS_COLLECTION, authUserData?.uid || "", userAccountDataConverter);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
            setLoadingAuthState(true);
            if (user) {
                const tokenResult = await user.getIdTokenResult();
                setAuthUserData({
                    uid: user.uid,
                    displayName: user.displayName,
                    email: user.email,
                    emailVerified: user.emailVerified,
                    isAnonymous: user.isAnonymous,
                    photoURL: user.photoURL,
                    refreshToken: user.refreshToken,
                    claims: tokenResult.claims
                });

                const cookies = new Cookies();
                cookies.set("authToken", user.refreshToken, {
                    maxAge: 2592000,
                    // domain: "localhost",
                });
            }
            else {
                setAuthUserData(null)
            }
            setLoadingAuthState(false);
        });

        return () => unsubscribe();
    }, []);

    const updateUserData = async () => {
        await firebase.auth().currentUser.reload();
        const currentUser = firebase.auth().currentUser;
        setAuthUserData({
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            email: currentUser.email,
            emailVerified: currentUser.emailVerified,
            isAnonymous: currentUser.isAnonymous,
            photoURL: currentUser.photoURL,
            refreshToken: currentUser.refreshToken,
        });
    };

    const user = authUserData
        ? {
            ...authUserData,
            ...userData,
        }
        : null;

    const context = {
        authenticated: user !== null,
        user,
        updateUserData,
        loadingUserData,
        loadingAuthState,
        configurationData,
        loadingConfigurationData,
        configurationDataError,
        skillsData,
    };

    return <Context.Provider value={context}>{children}</Context.Provider>;
}
