import { jsPDF } from "jspdf";
import { getFormattedDate } from "./date-format";
import ImageURL from "../assets/png/logo_dark_crop.png";
import { countries } from "./constants";

const getDateYear = (timestamp) => {
    if (!timestamp) {
        return '';
    }
    const date = new Date(timestamp);
    const year = date.getFullYear();
    return year;
}

const doc = new jsPDF();

export default function generateCV(cvData, userData, skillsData) {

    console.log('generating cv...')
    let y = 20;
    const x = 14;

    // Full Name
    doc.setFontSize(32);
    doc.text(`${userData?.firstName?.toUpperCase() || ''} ${userData?.lastName?.toUpperCase() || ''}`, x, y);

    doc.setFontSize(12);
    y += 6;
    doc.text(userData?.email, x, y);

    // Contact Info
    if (userData?.phone) {
        y += 6;
        const country = countries.find((item) => item.code === userData.phoneCode);
        const phoneCode = country ? `(+${country.phone}) ` : '';
        doc.text(`${phoneCode}${userData.phone}`, x, y);
    }
    if (userData?.dateOfBirth) {
        y += 6;
        doc.text(getFormattedDate(userData.dateOfBirth, true), x, y);
    }


    // Title
    // doc.setFontSize(18);
    // y += 8;
    // doc.text(`${cvData?.title?.toUpperCase() || ''}`, x, y);

    // y += 16;

    // Description
    // doc.setFontSize(12);
    // // var splitText = doc.splitTextToSize(`${cvData?.description || ''}`, 180, { 'center': true });
    // doc.text(`${cvData?.description || ''}`, x, y, { maxWidth: 180, align: 'justify' });

    // const dim = doc.getTextDimensions(cvData?.description || '', { maxWidth: 180 });
    // const height = Math.ceil(dim.h);
    // y += height;

    // horizontal line
    y += 8;
    doc.line(14, y, 196, y);

    // Work experiences
    y += 16;
    doc.setFontSize(18);
    doc.text('WORK EXPERIENCE', x, y);

    doc.setFontSize(12);

    (cvData?.experiences || []).forEach((experience) => {
        y += 8;

        const startDt = getDateYear(experience?.started);
        const endDt = getDateYear(experience?.ended);

        doc.text(`${startDt}${experience.current ? ' - present' : endDt ? ` - ${endDt}` : ''}`, x, y);

        const maxWidth = 194 - x - 44;
        doc.setFontSize(14);
        doc.text(`${experience?.jobTitle || ''}${experience?.company ? ` - ${experience?.company}` : ''}`, 44, y);

        // Company name
        // y += 5;
        // doc.setFontSize(10);
        // doc.text(experience?.company || '', 44, y);

        // Job description
        y += 5;
        doc.setFontSize(12);
        doc.text(`${experience?.description || ''}`, 44, y, { maxWidth });

        const dim = doc.getTextDimensions(experience?.description || '', { maxWidth });
        const height = Math.ceil(dim.h);

        if (y + 24 > 287) {
            doc.addPage();
            y = 13;
        } else {
            y += height;
        }
    });

    // horizontal line
    doc.line(14, y, 196, y);

    // Education
    y += 16;
    doc.setFontSize(18);
    doc.text('EDUCATION', x, y);
    doc.setFontSize(12);
    (cvData?.educations || []).forEach((education) => {
        y += 8;

        const startDt = getDateYear(education?.started);
        const endDt = getDateYear(education?.ended);

        doc.text(`${startDt}${education.current ? ' - present' : endDt ? ` - ${endDt}` : ''}`, x, y);

        const maxWidth = 194 - x - 44;

        // Description
        const title = `${education?.title || ''}${education?.institution ? `, ${education?.institution}` : ''}`;
        doc.text(title, 44, y, { maxWidth });

        if (y + 24 > 287) {
            doc.addPage();
            y = 10;
        } else {
            y += 5;
        }
    });

    // horizontal line
    doc.line(14, y, 196, y);

    // Expertises
    y += 16;
    doc.setFontSize(18);
    doc.text('EXPERTISE', x, y);


    y += 8;
    doc.setFontSize(12);

    const expertises = (cvData?.expertises || []);
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    let maxWidth = 0;
    expertises.forEach(skillID => {
        // Get the width of the current string
        const label = skillsData.find(({ id }) => id === skillID)?.label || '';
        const width = doc.getTextWidth(label);

        // Update maxWidth if necessary
        if (width > maxWidth) {
            maxWidth = width;
        }
    });

    let xExperitse = 14;
    expertises.forEach(skillID => {
        const label = skillsData.find(({ id }) => id === skillID)?.label || '';

        doc.text(label, xExperitse, y);
        const width = doc.getTextWidth(label);
        if (xExperitse + width + maxWidth + 5 < pageWidth) {
            xExperitse += (maxWidth + 5);
        } else {
            y += 8;
            xExperitse = 14;
        }
        if (y + 24 > 287) {
            doc.addPage();
            y = 13;
        }
    });

    // CV Generated by:
    doc.text('CV generator by:', pageWidth / 2, 278, { align: 'center' });

    doc.addImage(ImageURL, 'PNG', (pageWidth / 2) - 22, 280, 44, 6);

    doc.save("a4.pdf");
    console.log('done!')
}