import { useEffect, useState } from "react";
import {
    IconButton,
    InputAdornment,
    makeStyles,
} from "@material-ui/core";
import {
    VisibilityOffOutlined,
    VisibilityOutlined,
} from '@material-ui/icons';
import TextField from "../form-fields/text-field";

const useStyles = makeStyles(() => ({
    alert: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 5,
        alignItems: "center",
        "& p": {
            marginLeft: 15,
            marginBottom: 0,
        },
    },
    formWrapper: {
        display: "flex",
        flexDirection: "row",
    },
}
));

const passwordUpperCaseRE = /(?=.*?[A-Z])/; // At least one upper case English letter, (?=.*?[A-Z])
const passwordLowerCaseRE = /(?=.*?[a-z])/; // At least one lower case English letter, (?=.*?[a-z])
const passwordDigitRE = /(?=.*?\d)/; // At least one digit, (?=.*?[0-9])
const passwordSpecialCharRE = /[\W_]+/; // At least one special character, (?=.*?[#?!@$%^&*-])
const passwordMinimumEightRE = /^.{8,}$/; // Minimum eight in length .{8,} (with the anchors

export default function PasswordForm({
    id,
    name,
    label,
    value,
    errorMessage,
    setHasErrors = () => { },
    setSuccess = () => { },
    success,
    password,
    setPassword,
    fieldsWithError,
    setFieldsWithError = () => { },
    showValidation,
    setValidationError = () => { },
    setValidation,
    validation = {},
}) {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const onChangePassword = (e) => {

        const noSpace = e.target.value.split(" ").join("")
        setPassword(noSpace);
        setHasErrors(false);
        setSuccess(false);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSuccess(false);
            setHasErrors(false);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, [success, setSuccess, setHasErrors]);

    const setFieldError = (id, hasError) => {
        let check = { ...fieldsWithError };
        if (hasError) {
            check[id] = true;
        } else {
            delete check[id];
        }
        setFieldsWithError(check);
    };

    useEffect(() => {
        if (showValidation) {
            setValidation({
                uppercase: passwordUpperCaseRE.test(password),
                lowercase: passwordLowerCaseRE.test(password),
                digit: passwordDigitRE.test(password),
                specialCharacter: passwordSpecialCharRE.test(password),
                minimumEight: passwordMinimumEightRE.test(password),
            });
        }
    }, [password, showValidation]);

    useEffect(() => {
        if (showValidation) {
            const validationError = () => {
                if (
                    validation.digit &&
                    validation.lowercase &&
                    validation.uppercase &&
                    validation.specialCharacter &&
                    validation.minimumEight
                ) {
                    setValidationError(false);
                } else {
                    setValidationError(true);
                }
            };
            validationError();
        }
    }, [
        showValidation,
        setValidationError,
        validation.digit,
        validation.lowercase,
        validation.uppercase,
        validation.specialCharacter,
        validation.minimumEight,
    ]);

    return (
        <div className={classes.alert}>
            <TextField
                id={id}
                name={name}
                label={label}
                type={showPassword ? "text" : "password"}
                value={value}
                onChange={onChangePassword}
                required={true}
                hasError={fieldsWithError[`${name}`]}
                onError={setFieldError}
                errorMessage={errorMessage}
                InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
}
