import { useState } from "react";
import { makeStyles, Grid } from "@material-ui/core/";
import Header from "./header";
import FilterNav from "./header/filter-nav";
import Aside from "./aside";
import Footer from "./footer";

const useStyles = makeStyles((theme) => ({
    overlay: {
        position: "fixed",
        zIndex: "90",
        top: 70,
        width: "100%",
        height: "100%",
        display: "none",
        visibility: "hidden",
        opacity: 0,
        transition: "all 0.085s linear",

        "&.overlay-open": {
            display: "block",
            visibility: "visible",
            opacity: 1,
            backgroundColor: "rgba(255,255,255,0.5)",
            backdropFilter: "blur(2px)",
        },
    },
    containerFilter: {
        backgroundColor: "#FFF",
        position: "fixed",
        zIndex: 99,
        opacity: 0,
        transition: "all 0.085s linear",
        height: "auto",
        padding: "4px 0",
        "&.open": {
            top: 70,
            opacity: 1,
            backgroundColor: "#FFFFFF",
        },
        "&.close": {
            top: (p) => p.height,
        },

        [theme.breakpoints.between("xs", "sm")]: {
            paddingTop: 50,
        },
    },
    filterContainer: {
        flexWrap: "nowrap",
        overflowX: "auto",
        justifyContent: "space-evenly",
        flexDirection: "column",
    },
    iconWrapper: {
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        top: 15,
        right: 35,

        "& :last-child": {
            marginLeft: "5px",
        },
    },
    textClear: {
        "&:hover": {
            fontWeight: "bold",
        }
    }
}));

export default function SiteLayout(props) {
    const {
        seo,
        mainNavigation,
        aside,
        footer,
        children,
        pageCategory,
        onSetSelectedService,
        selectedServices,
        onSetSelectedCountry,
        selectedCountries,
        setSelectedCountries,
        setSelectedServices,

        singlePage
    } = props;

    const [isOpen, setOpen] = useState(false);
    const [isSearchOpen, setSearchOpen] = useState(false);

    const classes = useStyles({
        height: `-400px`,
    });

    const onShowFilterNav = () => {
        setOpen(true);
        setSearchOpen(false);

        const navEl = document.getElementById("filter-nav");
        const jobContainer = document.getElementById("job-container");
        navEl.classList.remove("close");
        navEl.classList.add("open");
        jobContainer.classList.add("overlay-open");
    };

    const onCloseFilterNav = () => {
        setOpen(false);

        const navEl = document.getElementById("filter-nav");
        const jobContainer = document.getElementById("job-container");
        navEl.classList.remove("open");
        navEl.classList.add("close");
        jobContainer.classList.remove("overlay-open");
    };

    const services = [
        "health",
        "professional",
        "engineering",
        "hospitality",
        "public",
    ];

    const countries = ["AUA", "BON", "CUR"];

    const clearAll = () => {
        setSelectedServices([]);
        setSelectedCountries([]);
    };

    const selectAll = () => {
        setSelectedServices(services);
        setSelectedCountries(["aua", 'bon', 'cur']);
    };

    const onSearchClick = () => {
        setSearchOpen((wasOpened) => !wasOpened);
        setOpen(false);
        onCloseFilterNav();
    };

    return (
        <>
            <Header
                mainNavigation={mainNavigation}
                pageCategory={pageCategory}
                onShowFilterNav={onShowFilterNav}
                onCloseFilterNav={onCloseFilterNav}
                onSearchClick={onSearchClick}
                isOpen={isOpen}
                isSearchOpen={isSearchOpen}
                isFiltered={
                    selectedServices?.length > 0 || selectedCountries?.length > 0
                }
                singlePage={singlePage}


            />

            {pageCategory === "overview" && (
                <Grid
                    id="filter-nav"
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    className={`${classes.containerFilter} close`}
                >
                    <div className={classes.iconWrapper}>
                        {selectedServices?.length || selectedCountries?.length ? (
                            <div className={classes.textClear} onClick={clearAll} style={{ cursor: "pointer" }}>
                                Clear all
                            </div>
                        ) : (
                            <div className={classes.textClear} onClick={selectAll} style={{ cursor: "pointer" }}>
                                Select all
                            </div>
                        )}
                        <div className={classes.textClear} onClick={onCloseFilterNav} style={{ cursor: "pointer", paddingLeft: 10, paddingRight: 5 }}>
                            Close
                        </div>

                    </div>

                    <Grid container item xs={12} className={classes.filterContainer}>
                        <FilterNav
                            onSelected={onSetSelectedCountry}
                            selected={selectedCountries}
                            filters={countries}
                            title={"Country"}
                            isCountry={true}
                        />

                        <FilterNav
                            onSelected={onSetSelectedService}
                            selected={selectedServices}
                            filters={services}
                            title={"Industry"}
                        />
                        {pageCategory === "overview" && (
                            <Grid
                                id="filter-nav"
                                container
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                className={`${classes.containerFilter} close`}
                            >
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    className={classes.filterContainer}
                                >
                                    <FilterNav onSetSelectedService={onSetSelectedService} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
            <div
                id="job-container"
                className={classes.overlay}
                onClick={onCloseFilterNav}
            />
            {children}
            <Aside {...aside}/>
            <Footer {...footer}/>
        </>

    );
}
