import { Grid, makeStyles, Button, Tooltip } from "@material-ui/core";
import { getBGColorForService } from "../../utils/";
import Heading from "../../components/base-components/typography/heading";
// import { motion } from 'framer-motion';
import { getFormattedDateShort } from "../../utils/date-format";
// import moment from 'moment';
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";

const useStyles = makeStyles((theme) => ({
  jobLink: {
    backgroundColor: (p) => getBGColorForService(p.service),
    cursor: "drag",
    position: "relative",
    textDecoration: "none",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "200px",
    height: "200px",
  },
  jobInfo: {
    color: theme.palette.secondary.light,
    paddingLeft: 15,
    paddingRight: 15,
    position: "absolute",
    height: "50%",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
  },
  title: {
    top: "0",
    fontWeight: 900,
    alignItems: "flex-end",
    marginBottom: "0 !important",
    fontSize: 14,
  },
  companyName: {
    fontSize: 12,
    lineHeight: "21px",
    fontWeight: 400,
    margin: 0,
    fontStyle: "none",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
    bottom: 0,
    fontFamily: "Roboto",
  },
  country: {
    fontSize: 12,
    lineHeight: "18px !important",
    fontWeight: 400,
    bottom: -22,
    alignItems: "center",
    margin: 0,
    textTransform: "uppercase",
    fontStyle: "normal",
  },
  extraInformation: {
    opacity: 0,
    position: "absolute",
    top: 8,
    left: 8,
    backgroundColor: "#262626",
    width: "calc(100% - 16px)",
    height: "calc(100% - 16px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.15s linear",
  },
  actionInfo: {
    color: "white",
    paddingLeft: 8,
    paddingRight: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  indicatorAction: {
    cursor: "pointer !important",
    marginTop: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    marginLeft: 8,

    "&:hover": {
      color: "#F26419",
    },
  },
  metaInfo: {
    fontFamily: "Roboto",
    color: theme.palette.secondary.light,
    fontSize: 12,
    lineHeight: "24px",
    paddingLeft: 8,
    paddingRight: 8,
    textTransform: "uppercase",
    fontWeight: 600,

    "&.centerInfo": {
      marginTop: 8,
      marginBottom: 8,
      fontWeight: 600,
    },
  },
  topSection: {
    position: "absolute",
    zIndex: 5,
    top: 0,
    left: 0,
    height: "auto",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  favorite: {
    "&:hover": {
      color: "#F26419",
    },
  },
}));

export default function Job({
  service,
  title,
  companyName,
  publishDate,
  country,
  columnId,
  applied,
  id,
  handleFavouriteClick,
  appliedForDate,
}) {
  const classes = useStyles({ service: service?.toLowerCase() || "" });

  return (
    <Grid className={classes.jobLink} layout>
      <div className={classes.topSection}>
        {publishDate && !applied ? (
          <div className={`${classes.metaInfo}`} color="secondary.light">
            {getFormattedDateShort(publishDate)}
          </div>
        ) : (
          <div className={`${classes.metaInfo}`} color="secondary.light">
            Applied on {getFormattedDateShort(appliedForDate)}
          </div>
        )}
        {applied !== true && (
          <div className={`${classes.actionInfo}`} color="secondary.light">
            <Tooltip title="Remove from Favorites" placement="right">
              <div
                className={classes.indicatorAction}
                onClick={(e) => handleFavouriteClick(e, id)}
              >
                <FavoriteIcon fontSize="small" className={classes.favorite} />
              </div>
            </Tooltip>
            <div className={classes.indicatorAction}>
              {columnId === "favorited-jobs" ? (
                <Tooltip title="Add to Selected" placement="right">
                  <AddCircleOutlineIcon fontSize="small" />
                </Tooltip>
              ) : (
                <Tooltip title="Remove from Selected" placement="right">
                  <RemoveCircleOutlineIcon fontSize="small" />
                </Tooltip>
              )}
            </div>
          </div>
        )}
      </div>
      <Heading
        variant="h2"
        className={`${classes.jobInfo} ${classes.title}`}
        color="secondary.light"
      >
        {title}
      </Heading>
      <span
        className={`${classes.jobInfo} ${classes.companyName}`}
        color="secondary.light"
      >
        {companyName}
        <em className={`${classes.country}`}>{country || "AUA"}</em>
      </span>
    </Grid>
  );
}
