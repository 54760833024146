import { Box, makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { connectStateResults } from "react-instantsearch-dom";
import { getBGColorForService } from "..";
import JobsOverview from "../../components/jobs-overview/jobs-grid";
import Loading from "../../components/base-components/loader";

const useStyles = makeStyles((theme) => ({
    button: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "125px",
        height: "30px",
        backgroundColor: 'white',
        border: "none",
        borderRadius: "50px",
        fontWeight: "bold",
        color: `${getBGColorForService("orange")}`,
        cursor: "pointer",
        padding: "20px 20px",
        transition: "all 0.3s ease-in-out",
        border: `solid 4px ${getBGColorForService("orange")}`,

        "&:hover": {
            color: "white",

            backgroundColor: `${getBGColorForService("orange")}`,
        },
    },
    buttonWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        paddingBottom: 60,
    },
}));

const Loader = connectStateResults(({ isSearchStalled, searching }) => (
    <Box sx={{ width: "100%" }}>{<Loading searching={searching} />}</Box>
));

export default function TypesenseResultsTable({
    hits = [],
    filterSortMapData,
    setHitsPerPage,
    hitsPerPage,
    ...props
}) {
    const classes = useStyles();

    let data = filterSortMapData ? filterSortMapData(hits) : hits;

    //check if hitsperpage surpassed the data.length
    const hideButton = hitsPerPage <= data.length;

    return data?.length > 0 ? (
        <>
            <JobsOverview filtered={data} {...props} />
            {hideButton && (
                <div className={classes.buttonWrapper}>
                    <button
                        className={classes.button}
                        onClick={() => setHitsPerPage(hitsPerPage + 50)}
                    >
                        More Jobs
                    </button>
                </div>
            )}
        </>
    ) : (
        <Loader />
    );
}
