import {
    makeStyles,
    Chip,
    Grid
} from '@material-ui/core';
import {
    Cancel
} from '@material-ui/icons';
import AutoSugguestInputField from './auto-suggest-input-field';
import Preloader from '../../preloader';
import { urlify } from '../../../utils';
import firebase from '../../../utils/firebase';

const useStyles = makeStyles((theme) => ({
    chip: {
        marginRight: '8px',
        marginBottom: '8px',
    }
}));

export default function ChipSelectField({
    id,
    value,
    loading,
    onChange,
    options,
    optionsId,
    placeholder = 'Search'
}) {
    const classes = useStyles();

    const onSuggestionSelected = ({ isNew = false, label, ...props }) => {
        if (isNew) {
            props.id = urlify(props.value);
            label = props.value[0].toUpperCase() + props.value.substring(1);
            firebase.firestore().collection(optionsId).doc(props.id).set({ label });
        }
        value.push(props.id);
        onChange({ target: { id, value } });
    }

    const onDeletePill = index => {
        value.splice(index, 1);
        onChange({ target: { id, value } });
    }

    return (
        <Grid container spacing={2} direction="row">
            <Grid item xs={12} container justifyContent="space-between">
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {loading ?
                            <Preloader />
                            :
                            <div className={classes.autoSuggestFieldContainer}>
                                <AutoSugguestInputField
                                    options={options}
                                    onChange={onSuggestionSelected}
                                    placeholder={placeholder}
                                />
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {value?.length > 0 && value.map((id, i) => {
                            const option = options.find(option => option.id === id);
                            return (
                                <Chip
                                    key={`chip-select-field-chip--${id}-${i}`}
                                    label={option?.label || ''}
                                    clickable
                                    color="primary"
                                    onDelete={() => onDeletePill(i)}
                                    deleteIcon={<Cancel />}
                                    className={classes.chip}
                                />
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}