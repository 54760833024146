import {
    Routes,
    Route,
} from 'react-router-dom';
import AppliedJobsOverview from './applied-jobs-overview';

export default function AppliedJobsRoute() {
    return (
        <Routes>
            <Route exact path="/" element={<AppliedJobsOverview />} />
        </Routes>
    )
}