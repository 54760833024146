import {
    makeStyles,
} from '@material-ui/core/';

import StepItem from './step-item';

const useStyles = makeStyles((theme) => ({
    modal: {
        position:'fixed',
        top:0,
        left:0,
        width:'100%',
        height:'100vh',
        backgroundColor:'rgba(0,0,0,0.85)',
        zIndex:999,
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',

        '&.hidden': {
            display:'none',
            visibility: 'hidden',
        },
    },
    modalContainer: {
        backgroundColor:'#fff',
        width:'calc(100% - 120px)',
        height:'calc(100vh - 120px)',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        position:'relative',
        [theme.breakpoints.down("sm")]: {
            width:'calc(100% - 30px)',
            height:'calc(100vh - 30px)',
        },

        '& .active': {
            visibility:'visible',
            opacity:'1',
            display:'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems:'center',
        },
        '& .disabled': {
            visibility:'hidden',
            opacity:'0',
            height:'0',
        },
    }, 
    modalContent: {
        position: 'relative',
        overflow: 'auto',
        height: '100%',
        padding: 30,
        
        zIndex:9999999999,
        [theme.breakpoints.down('xl')]: {
            display:'flex',
        },
        [theme.breakpoints.down('sm')]: {
            padding: 15,
        }
    }
}));

export default function Steps({ onClick }) {
    const classes = useStyles();

    const countries = {
        type: 'country',
        mainTitle: 'Choose Country',
        items: [
            {
                title: 'aruba', 
                service: 'health',
                id: 'aua', 
            },
            {
                title: 'bonaire', 
                service: 'professional',
                id: 'bon', 
            },
            {
                title: 'curaçao', 
                service: 'engineering',
                id: 'cur', 
            },
            {
                title: 'all', 
                service: 'all',
                id: 'all', 
            },
        ]
    };

    const industries = {
        type: 'service',
        mainTitle: 'Choose Industries',
        items: [
            {
                title: 'health', 
                service: 'health',
                id: 'health',
            },
            {
                title: 'professional', 
                service: 'professional',
                id: 'professional',
            },
            {
                title: 'engineering', 
                service: 'engineering',
                id: 'engineering',
            },
            {
                title: 'hospitality', 
                service: 'hospitality',
                id: 'hospitality',
            },
            {
                title: 'public', 
                service: 'public',
                id: 'public',
            },
            {
                title: 'all', 
                service: 'all',
                id: 'all',
            },
        ]
    };
    
    return (
        <div id="modal" className={classes.modal}>
            <div className={classes.modalContainer}>
                <div className={classes.modalContent}>
                    <StepItem id="step-1" list={countries} active={true} onClick={onClick} />
                    <StepItem id="step-2" list={industries} active={false} onClick={onClick} />
                </div>
            </div>
        </div>
    )
}