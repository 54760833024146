import { 
    makeStyles,
    withStyles,
    Grid,
    LinearProgress
} from '@material-ui/core/';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 0,
    },
    colorPrimary: {
        backgroundColor:'#c1c6de',
    },
    bar: {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
    },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export default function ProgressBar({ progressText }) {
    const classes = useStyles();
    return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.root}>
                <BorderLinearProgress variant="determinate" value={progressText} />
            </div>
        </Grid>
    )
}