import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",

    "&.hidden": {
      display: "none",
      visibility: "hidden",
    },
  },
  modalContainer: {
    backgroundColor: "#fff",
    width: "750px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      height: "calc(100vh - 30px)",
    },

    "& .active": {
      visibility: "visible",
      opacity: "1",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .disabled": {
      visibility: "hidden",
      opacity: "0",
      height: "0",
    },
  },
  modalContent: {
    position: "relative",
    overflow: "auto",
    height: "100%",
    padding: 30,

    zIndex: 100,
    [theme.breakpoints.down("xl")]: {
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
  close: {
    position: "absolute",
    right: 15,
    top: 15,
    zIndex: 101,
    cursor: "pointer",
  },
}));

export default function Modal({ onClose, modalId, children }) {
  const classes = useStyles();

  return (
    <div id="modal" className={classes.modal}>
      <div className={classes.modalContainer}>
        <div className={classes.close} onClick={onClose}>
          {modalId === 3 ? "Close" : "Cancel"}
        </div>
        <div className={classes.modalContent}>{children}</div>
      </div>
    </div>
  );
}
