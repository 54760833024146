import { useState } from 'react'
import firebase from '../../../utils/firebase'
import { makeStyles, Tooltip, Grid, Fab } from '@material-ui/core/'
import Heading from '../typography/heading';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import ProgressBarComponent from '../progress-bar/'

const useStyles = makeStyles((theme) => ({
    fileContainer: {
        backgroundColor: 'transparent',
        border: '0',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    input: {
        width: '100%',
        height: '42px',
        padding: '8px 15px',
        cursor: 'pointer',
    },
    iconUpload: {
        marginRight: 8,
    },
    headingProfile: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center', 
        '& svg': {
            marginLeft: '8px',
            marginTop: '-3px',
        }
    }, 
    uploadContainer: {
        marginBottom:30,
    }
}))

export default function FileField({
    id,
    name,
    accept,
    storagePath,
    setRefreshList = false,
    // labelButton = 'Upload P.O.D.',
    profile = false,
    inventoryItem = false,
}) {
    const [progressFile, setProgressFile] = useState('')

    const classes = useStyles()

    const onChange = async (event) => {
        let fileList = event.target.files
        const file = fileList[0]
        if (file) {
            const storage = firebase.storage()
            let storageRef
            if (profile) {
                storageRef = storage.ref(`${storagePath}/profile-picture.jpg`)
            }
            if (inventoryItem) {
                storageRef = storage.ref(`${storagePath}/itemImage.jpg`)
            }
            if (!profile && !inventoryItem) {
                storageRef = storage.ref(`${storagePath}/${file.name}`)
            }

            const uploadTask = storageRef.put(file)

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setProgressFile(progress)
                    // console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            // console.log('Upload is paused');
                            break
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                        default:
                            // console.log('Upload is running');
                            break
                    }
                },
                (error) => {
                    console.log(error)
                    // Handle unsuccessful uploads
                },
                () => {
                    console.log('Upload complete')

                    setTimeout(function () {
                        setProgressFile('');
                        setRefreshList && setRefreshList(true);
                    }, 600)
                }
            )
        } else {
            alert('Please choose a file to upload...')
        }
    }

    return (
        <Grid
            container
            spacing={2}
            direction='row'
            className={classes.uploadContainer}
        >
            <Grid item xs={12} sm={12} md={9} lg={9}>
                <Heading color="secondary.dark" variant="h6" className={classes.headingProfile}>
                    Profile Image 
                    <Tooltip
                        title={`Supported file types are: ${accept}`}
                        aria-label='upload'
                        placement='top'
                        arrow
                        className={classes.iconUpload}
                    >
                        <InfoIcon fontSize="small" color="primary" />
                    </Tooltip>
                </Heading>
                <div className={classes.fileContainer}>
                    <label htmlFor={name}>
                        <input
                            type='file'
                            id={id}
                            name={name}
                            style={{ display: 'none' }}
                            className={classes.input}
                            accept={accept}
                            onChange={onChange}
                            label='.jpeg, .jpg, .png, .pdf'
                        />
                        <Tooltip
                            title={`Supported file types are: ${accept}`}
                            aria-label='upload'
                            placement='top'
                            arrow
                            className={classes.iconUpload}
                        >
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                            >
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </label>
                    {progressFile > 0 && (
                        <ProgressBarComponent progressText={progressFile} />
                    )}
                </div>
            </Grid>
        </Grid>
    )
}
