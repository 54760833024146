import firebase from "./firebase";

export const getDataFromResponse = (response, defaultValue) => {
  let result = response.data;

  if (result.result === "error") {
    throw new Error(`${result.errorCode} - ${result.errorMessage}`);
  }
  return result || defaultValue;
};

export const updateUser = async (user) => {
  const updateUser = firebase.functions().httpsCallable("updateUser");
  const response = await updateUser(user);
  return getDataFromResponse(response, []);
};

export const setCustomUserClaim = async (claim) => {
  const setCustomUserClaim = firebase
    .functions()
    .httpsCallable("setCustomUserClaim");
  const response = await setCustomUserClaim(claim);
  return getDataFromResponse(response, []);
};

export const setCustomClaims = async (claim) => {
  const setCustomClaims = firebase
    .functions()
    .httpsCallable("setCustomClaims");
  const response = await setCustomClaims(claim);
  return getDataFromResponse(response, []);
};

export const passwordResetMail = async (email) => {
  const passwordResetMail = firebase
    .functions()
    .httpsCallable("passwordResetMail");
  const response = await passwordResetMail(email);
  return getDataFromResponse(response, []);
};

export const createClientLogin = async (client) => {
  const createClientLogin = firebase
    .functions()
    .httpsCallable("createClientLogin");
  const response = await createClientLogin(client);
  return getDataFromResponse(response, []);
};

export const createUserWithEmailAndPassword = async (user) => {
  const createUserWithEmailAndPassword = firebase
    .functions()
    .httpsCallable("createUserWithEmailAndPassword");
  const response = await createUserWithEmailAndPassword(user);
  return getDataFromResponse(response);
};

export const updateUserClients = async (user) => {
  const updateUserClients = firebase
    .functions()
    .httpsCallable("updateUserClients");
  const response = await updateUserClients(user);
  return getDataFromResponse(response, []);
};

export const sendTestMail = async () => {
  const sendTestMail = firebase.functions().httpsCallable("sendTestMail");
  const response = await sendTestMail();
  return getDataFromResponse(response, []);
};
export const sendVerificationEmail = async (data) => {
  const sendVerificationEmail = firebase.functions().httpsCallable("sendVerificationEmail");
  const response = await sendVerificationEmail(data);
  return getDataFromResponse(response, []);
};


export const applyForSelectedJobs = async () => {
    const applyForSelectedJobs = firebase
        .functions()
        .httpsCallable("applyForSelectedJobs");
    const response = await applyForSelectedJobs();
    return getDataFromResponse(response, []);
};

export const applyForJobDirectly = async (singleJobID) => {
    const applyForJobDirectly = firebase
        .functions()
        .httpsCallable("applyForJobDirectly");
    const response = await applyForJobDirectly(singleJobID);
    return getDataFromResponse(response, []);
};


export const listUsers = async () => {
    const listUsers = firebase
        .functions()
        .httpsCallable("listUsers");
    const response = await listUsers();
    return getDataFromResponse(response, []);
};


// this will be on hold
// export const confirmPasswordReset = async (
//   auth,
//   oobCode,
//   newPassword
// ) => {
//   const confirmPasswordReset = firebase
//     .functions()
//     .httpsCallable("confirmPasswordReset");
//   const response = await confirmPasswordReset({ auth, oobCode, newPassword });

//   console.log("response", response);

//   return getDataFromResponse(response, []);
// };
